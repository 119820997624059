import Send from '../utils/Send.js'

export default {
    async insert(name, params, result) {
        return await Send({
            url: '/log',
            method: 'post',
            params : {
                name: name,
                params: params,
                result: result
            },
        })
    },
}