<template>
    <div id="app">
        <div class="wrap">
            <!-- header -->
            <!-- <div class="header_wrap" v-if="isSubPage">
                <div class="header" :class="{'main':isMain}">
                    <h1 v-if="isMain" class="logo">
                        <a @click="$root.goTo('/main')">Philip Morris</a>
                    </h1>
                    <h1 v-else-if="isSubPage">
                        {{$route.meta.title}}
                    </h1>
                    <div class="hd_left" v-if="this.$store.state.specialHeader == 'refund'">
                        <a class="aside_back" @click="goBackToHistory()"><span>뒤로가기</span></a>
                    </div>
                    <div class="hd_left" v-else-if="this.$store.state.specialHeader == 'showPolicy'">
                        <a class="aside_back" @click="goBackToRefund()"><span>뒤로가기</span></a>
                    </div>
                    <div v-else class="hd_left">
                        <a class="aside_menu" @click="openMenu()"><span>메뉴 열기</span></a>
                    </div>
                    <div class="hd_right" v-if="this.$store.state.specialHeader != 'refund' && this.$store.state.specialHeader != 'showPolicy'">
                        <a @click="$root.goTo('/member')" class="ico_mypage"><span>내 정보</span></a>
                        <a @click="$root.goTo('/cart')" class="ico_cartbox">
                            <span class="cart">메일함</span>
                            <span class="m_alarm" v-if="$store.state.cart && $store.state.cart.count > 0">
                                {{$store.state.cart.count}}
                            </span>
                        </a>
                    </div>
                </div>
            </div> -->
            <!-- //header -->
            
            <!-- 20220923 ~ header -->
            <div class="header_wrap" v-if="isSubPage">
                <div class="header" :class="{'main':isMain}">
                    <h1 v-if="isMain" class="logo">
                        <a @click="$root.goTo('/main')">Philip Morris</a>
                    </h1>
                    <h1 v-else-if="isSubPage">
                        {{$route.meta.title}}
                    </h1>
                    <div class="hd_left">
                        <a class="aside_menu" @click="openMenu()"><span :class="{'black':isMain}">메뉴 열기</span></a>
                    </div>
                    <!-- <div class="hd_right" v-if="this.$store.state.specialHeader != 'refund' && this.$store.state.specialHeader != 'showPolicy'">
                        <a @click="$root.goTo('/member')" class="ico_mypage"><span>내 정보</span></a>
                        <a @click="$root.goTo('/cart')" class="ico_cartbox">
                            <span class="cart">메일함</span>
                            <span class="m_alarm" v-if="$store.state.cart && $store.state.cart.count > 0">
                                {{$store.state.cart.count}}
                            </span>
                        </a>
                    </div> -->
                </div>
            </div>
            <!-- 20220923 ~ header -->

            <router-view />

            <div class="footer_wrap" v-if="isSubPage">
                <div class="nav_footer">
                    <ul>
                        <li @click="$root.goTo('/main')"><a></a></li>
                        <li @click="$root.goTo('/cart')">
                             <a>
                                <span class="m_alarm" v-if="$store.state.cart && $store.state.cart.count > 0">
                                    {{$store.state.cart.count}}
                                </span>
                            </a>
                        </li>
                        <li @click="$root.goTo('/orderHistory')"><a></a></li>
                        <li @click="$root.goTo('/notice')"><a></a></li>
                    </ul>
                </div>
            </div>

            <!-- hamburger menu -->
            <div v-if="isMenuOpen" class="slide_wrap">
                <div class="side_menu">
                        <button type="button" class="btn_close_wht" @click="closeMenu">
                            <span>닫기</span>
                        </button>
                    <ul class="gnb_munu">
                        <li>
                            <a @click="$root.goTo('/main')">홈으로</a>
                        </li>
                        <!-- <li>
                            <a @click="$root.goTo('/member')">소매점 정보</a>
                        </li> -->
                        <li>
                            <a @click="$root.goTo('/product')">상품주문</a>
                        </li>
                        <li>
                            <a @click="$root.goTo('/orderHistory')">주문현황</a>
                        </li>
                        <li>
                            <a @click="$root.goTo('/notice')">공지사항</a>
                        </li>
                        <li>
                            <a @click="$root.goTo('/survey')">설문조사</a>
                        </li>
                        <li>
                            <a @click="$root.goTo('/promotion')">교육자료</a>
                        </li>
                        <li>
                            <a @click="$root.goTo('/deliveryplan')">배송일정</a>
                        </li>    
                        <li>
                            <a @click="$root.goTo('/member')">소매점정보</a>
                        </li>  
                    </ul>
                    <div class="lg_service_num">
                        <dl>
                            <dt>지점번호</dt>
                            <dd>{{this.$store.state.customer.csTelNo}}</dd>
                        </dl>
                        <div class="button_area">
                            <button type="button" class="btn_call" @click="callCenter">전화걸기</button>
                        </div>
                    </div>
                    <ul class='gnb_munu'>
                        <br>
                        <li>
                            <a class='app' @click="$root.goToFooter('Footer','popPolicyPmi')">개인정보 처리방침 (한국필립모리스(주))</a>
                        </li>
                        <li>
                            <a class='app' @click="$root.goToFooter('Footer','showPolicy3')">개인정보 처리방침 ((주)영유통, (주)삼양인터내셔날, (주)한미상사)</a>
                        </li>
                        <!-- <li>
                            <a class='app' @click="popAgreement()">이용약관</a>
                        </li>
                        <li>
                            <a class='app' @click="popCompany()">회사정보</a>
                        </li> -->
                    </ul>
                </div>
            </div> 
        </div>  
        <vue-confirm-dialog class="confirm_layer"></vue-confirm-dialog>
        <vue-dialog />
    </div>
</template>
<script>
import Code from '@/api/Code'
import Login from '@/api/Login'
import $ from 'jquery';
import moment from 'moment'

export default {
    name: 'Member',
    data() {
        return {
            title: "No Title",
            isMenuOpen: false,
            isSubPage: true,
            isMain: true
        }
    },
    beforeCreate(){
        if(this.$route.name != 'Login'){
            var nowTime = moment()
            var expireTime = moment(this.$store.state.session.expire)
            // console.log(expireTime.format('YYYY-MM-DD HH:mm'))
            // console.log('변경전 토큰 : ',this.$store.state.session.token)
            var diff = expireTime.diff(nowTime, 'minute') // 토큰만료시간 - 현재시간
            // console.log('diff : ',diff)

            if(0 <= diff && diff <= 5){
                Login.refresh({
                    token: this.$store.state.session.token,
                    customerCode :  this.$store.state.customer.customerCode
                })
                .then(res => {
                    this.$store.commit('session', res.data.token);
                    // console.log('변경후 토큰 : ',this.$store.state.session.token)
                })
            }
        }

        // if(this.$route.name != 'Login'){
        //     var nowTime = moment()
        //     var expireTime = moment(this.$store.state.session.expire)
        //     // console.log('만료시간 : ',expireTime.format('YYYY-MM-DD HH:mm'))
        //     var diff = expireTime.diff(nowTime, 'minute') // 토큰만료시간 - 현재시간
        //     // console.log('로그아웃 남은 시간 : ',diff)

        //     if(diff <= 0 ){
        //         this.$store.commit('logout');
        //     }else {
        //         this.$store.commit('session', this.$store.state.session.token);
        //     }
        // }

        // if(0 <= diff && diff <= 5){
           
        // }

    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                //console.log('watch:', this.$route.name)
                var excludeSubHead = 'Login, LoginSimple, System, Access, DanalDemo';
                this.isSubPage = excludeSubHead.indexOf(this.$route.name) == -1;
                this.isMain = this.$route.name == 'Main';
                this.isMenuOpen = false;
            }
        }
    },
    created() {
        document.title = 'DigitalPMK';

        // PRD에서 herokuapp.com으로 접속 시 www.digitalpmk.com으로 리다이렉트
        let checkDomain = 'https://pmk-b2b-mobile-prd.herokuapp.com';
        if (window.location.href.indexOf(checkDomain) > -1) {
            window.location.href = window.location.href.replace(checkDomain, 'https://www.digitalpmk.com');
        }
    },
    mounted() {
        //상단 SubPage헤더 적용 여부 확인
        var excludeSubHead = 'Login, LoginSimple, System, Access, DanalDemo';
        this.isSubPage = excludeSubHead.indexOf(this.$route.name) == -1;

        if(this.$route.name == 'Login' && this.$store.state.session == null){
            this.$store.commit('orderUpdate', null);
            this.$store.commit('cartPopUpCheck', null);
            this.$store.commit('isOrderPage', false);
        }

        var includeMain = 'Main';
        
    },
    methods: {
        goBackToHistory(){
            this.$root.goTo('/orderHistory');
            this.$store.commit('specialHeader', null);
        },
        goBackToRefund(){ 
            this.$store.commit('specialHeader', 'refund');
            this.$route.meta.title = '환불요청';
        },
        openMenu: function() {
            this.isMenuOpen = true;
        },
        closeMenu: function() {
            this.isMenuOpen = false;
        },
        callCenter: function() {
            window.location.href = "tel:" + this.$store.state.customer.csTelNo;
        },
        // popPolicyPmi() {
        //     this.$router.push({name:'Footer',params: { url: 'popPolicyPmi' }})
        // },
        // popAgreement(){
        //     this.$router.push({name:'Footer',params: { url: 'popAgreement' }})
        // },
        // popCompany(){
        //     this.$router.push({name:'Footer',params: { url: 'popCompany' }})
        // }
    },
    
}
</script>