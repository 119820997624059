/*
import Vue from 'vue'

export const events = new Vue({
  name: 'vue-confirm-dialog'
})
*/

/*
import { createApp } from 'vue'

export const events = createApp({
  name: 'vue-confirm-dialog'
})
*/

import mitt from 'mitt'

export const events = mitt()