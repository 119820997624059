<template>
    <div :class="{testBg:isTest}">
        <!-- lg_flexBox -->
        <div class="lg_flexBox">
            <div class="lg_header">
                <h1>
                    <img src="../../assets/styles/images/20221020-DigitalPMK_logo_W.png" alt="한국 필립모리스(주)">
                </h1>
                <h2 class="sub_tit mt-15">
                    <p class="t_1"></p>
                    <p class="t_2">소매점주용 온라인 주문 시스템</p>
                </h2>
                    <!-- <center> 
                        <font style="color:#00d1d2; font-size: 50px;"> PMI & U </font> <br>
                        <font size = '1' style="color:#34303d"> kr.pmiandu.com </font> 
                    </center> -->

            </div>
            <div class="lg_content">
                <div class="lg_body">
                    <!-- <h2 class="sub_tit">
                        <p class="t_1">kr.pmiandu.com</p>
                        <p class="t_1"></p>
                        <p class="t_2">소매점주용 온라인 주문 시스템</p>
                    </h2> -->
                    <div v-if="$store.state.loginKey" class="logoin_sec_simple">
                        <div class="btn_box">
                            <button type="button" class="btn_simple_login" @click="$router.push({name:'LoginSimple', params:{mode:'login'}})">
                                간편 접속
                                <i></i>
                            </button>
                        </div>
                    </div>
                    <div v-else class="logoin_sec">
                        <div class="notice">간편 비밀번호 등록을 위해<br>OTP 인증이 최초 1회 필요합니다.</div>
                        <div class="input_box active">
                            <input v-model="phoneNumber" type="number" pattern="\d*" placeholder="전화번호" :disabled="isSendOtp">
                            <!-- 21.03.15 퍼블수정 : 클래스명 disabled 추가 -->
                            <span v-if="isSendOtp" class="btn_request disabled">인증번호 요청</span>
                            <span v-else class="btn_request " @click="sendOTP()">인증번호 요청</span>
                            <div class="t_red" v-if="message" v-html="message"></div>
                        </div>
                        <div class="input_box active">
                            <input v-model="otp" type="number" pattern="\d*" placeholder="인증번호">
                            <div v-if="isEnableLogin" class="noti_timer">제한시간 <span>{{elapsedTime}}</span></div>
                        </div>
                        <!--div class="checkbox mt-1 mb-2">
                            <input id="isRememberId" type="checkbox" class="hidden" v-model="isRememberId"/>
                            <label for="isRememberId">전화번호 저장</label>
                        </div-->
                        <div class="lg_button">
                            <!-- 21.03.15 퍼블수정 : disabled로 만들경우 btn_blk-> btn_gray 변경 -->
                            <button v-if="isEnableLogin" type="button" class="btn_blk bigger" @click="login()">로그인</button>
                            <button v-else type="button" class="btn_gray bigger">로그인</button>
                        </div>
                        <!-- 21.02.26 퍼블수정 : 알링글 추가 -->
                        <div class="lg_notice">㈜영유통, ㈜삼양인터내셔날 또는 ㈜한미상사의 거래처 권한으로 로그인 후 이용하실 수 있습니다.</div>
                        <!-- // 21.02.26 퍼블수정 : 알링글 추가 -->
                    </div>
                </div>
            </div>
            <!-- 21.02.26 퍼블수정 : 푸터 추가 -->
            <div class="lg_footer">
                <Footer/>
            </div>
            <!-- //21.02.26 퍼블수정 : 푸터 추가 -->
        </div>
        <!-- //lg_flexBox -->

        <!-- <div class="lg_service_num">
            <dl>
                <dt>고객센터 대표번호</dt>
                <dd>080-781-7000</dd>
            </dl>
            <div class="button_area">
                <button type="button" class="btn_call">전화걸기</button>
            </div>
        </div> -->
  
    </div>
</template>

<script>
import Footer from './Footer'
import Login from '@/api/Login'
import Cart from '@/api/Cart'
import System from '@/api/System'

export default {
    name: 'Login',
    components: {
        Footer
    },
    data() {
        return {
            phoneNumber: "",
            otp: "",
            stores: null,
            selectedStore: null,
            message: "",
            storeMessage: '',
            isSendOtp: false,
            isEnableLogin: false,
            isRememberId: false,
            interval: null,
            expireTime: null,
            elapsedTime: "30:00",
            otpRequestErrorCount: 10,
            otpRequestErrorMessage: '전송한도 초과로 10분간 재인증 처리가 불가합니다. <br/>고객센터로 문의바랍니다.'
        }
    },
    created() {
        System.getCheck();

        // 1분간 10회 인증번호 발급 요청 시 10분간 발급요청 금지
        this.otpRequestCheck();

        if (localStorage.getItem('rememberId')) {
            //this.phoneNumber = localStorage.getItem('rememberId');
            //this.isRememberId = true;
        }
    },
    computed: {
        isTest() {
            return process.env.NODE_ENV == 'local' || process.env.NODE_ENV == 'dev' || process.env.NODE_ENV == 'stg';
        }
    },
    watch: {
        phoneNumber: function(newVal, oldVal) {
            //this.$dialog('결제 서비스 장애 안내<div class="paragraph" style="text-align:left"><br>현재 결제 시 승인 오류가 간헐적으로 발생하고 있습니다.<br>빠른 시간 내 조치하도록하겠습니다.<br>서비스 이용에 불편을 드려 죄송합니다.</div>');
            /*
            this.$dialog(
                {
                    title: '확인',
                    message: '전화번호로 인증 후 간편비밀번호를 다시 등록해주세요',
                    button: {
                        yes: '확인', no: '취소'
                    }
                }
            );
            */  
            this.message = '';
        },
        isRememberId: function(newVal) {
            // 전화번호 저장 해제 시 저장된 전화번호 삭제
            if (!newVal) {
                localStorage.removeItem('rememberId');
                
                // 로그인 활성화상태가 아니라면 전화번호 삭제
                if (!this.isEnableLogin) {
                    this.phoneNumber = '';
                }
            }
        }

    },
    methods: {
        sendOTP: function() {
            this.message = '';

            if (this.phoneNumber.length == 0) {
                this.message = '전화번호를 입력해주세요';
                return;
            }

            /*
            // otp 발급 카운트 증가
            this.$store.commit('otpRequest');

            // 1분간 10회 인증번호 발급 요청 시 10분간 발급요청 금지
            if (!this.otpRequestCheck()) {
                return false;
            }
            */
           
            // 인증요청버튼 비활성화
            this.isSendOtp = true;


            Login.sendOTP({
                    phoneNumber: this.phoneNumber
                })
                .then(res => {
                    this.message = '인증번호가 전송되었습니다.';
                    this.isEnableLogin = true;


                    // 카운트다운
                    const self = this;
                    this.expireTime = new Date();
                    this.expireTime.setMinutes(this.expireTime.getMinutes()+30);
                    this.expireTime = this.expireTime.getTime();

                    this.interval = setInterval(function() {
                        var currentTime = new Date().getTime();
                        var min = Math.floor((self.expireTime - currentTime)/1000/60);
                        var sec = Math.floor((self.expireTime - currentTime - (min*60*1000))/1000);
                        
                        if (sec < 10) {
                            sec = '0' + sec;
                        }
                        
                        self.elapsedTime = min + ':' + sec;

                        // 제한시간 만료
                        if (self.expireTime <= currentTime) {
                            clearInterval(self.interval);
                            self.message = "인증번호의 유효시간이 지났습니다.";

                            // 인증요청버튼 활성화
                            self.isSendOtp = false;
                            self.isEnableLogin = false;
                        }

                    }, 100);
                })
                .catch(error => {
                    // 인증요청버튼 활성화
                    this.isSendOtp = false;

                    if ('No Customer' == error.response.data.message) {
                        this.message = '고객정보가 없습니다.';
                    } else if (error.response.data.message.indexOf('RetryTimeRemaining') == 0) {
                        let msg = error.response.data.message.split('=');
                        this.$dialog('전송한도 초과로 ' + msg[1] + '분간 인증번호 요청 처리가 불가합니다. <br/>고객센터로 문의바랍니다.')
                        clearInterval(this.interval);
                        this.isEnableLogin = false;
                    } else {
                        this.message = '서버 에러 발생';
                    }

                })
        },
        otpRequestCheck: function() {
            // 1분간 10회 인증번호 발급 요청 시 10분간 발급요청 금지
            if (this.$store.state.otpRequest != null && this.$store.state.otpRequest.count >= 10) {
                let current = (new Date()).getTime();

                // 최초 발급요청 시점으로부터 10분간 인증번호 요청 금지
                if ((current - this.$store.state.otpRequest.time) < 10*60*1000) {
                    this.message = '전송한도 초과로 10분간 인증번호 요청 처리가 불가합니다. <br/>고객센터로 문의바랍니다.';
                    this.isSendOtp = true;

                    return false;
                }
            }
            return true;
        },
        login: function(customerCode) {
            console.log(this.selectedStore);
            
            if (this.stores) {
                if (!this.selectedStore) {
                    this.storeMessage = '소매점을 선택해주세요.';
                    return false;
                } else {
                    customerCode = this.selectedStore;
                }
            }

            Login.checkOTP({
                    id: this.phoneNumber,
                    otp: this.otp
                })
                .then(res => {
                    localStorage.setItem('rememberId', this.phoneNumber);
                    this.$router.push({name:'LoginSimple', params:{id:this.phoneNumber, otp:this.otp}})
                })
                .catch(error => {
                    if ('No Otp' == error.response.data.message) {
                        this.$dialog('발급된 인증번호가 없습니다.');
                    } else if ('Invalid Otp' == error.response.data.message) {
                        this.$dialog('유효한 인증번호가 아닙니다.');
                    } else if (error.response.data.message.indexOf('RetryTimeRemaining') == 0) {
                        let msg = error.response.data.message.split('=');
                        this.$dialog('인증 실패로 ' + msg[1] + '분간 로그인 요청 처리가 불가합니다. <br/>고객센터로 문의바랍니다.')
                    } else {
                        this.message = '서버 에러 발생';
                    }
                })
        },
        clickStore() {
            this.storeMessage = '';
        },
        resetSimple() {
            //this.$dialog('asd');
            this.$dialog(
                {
                    title: '확인',
                    message: '전화번호로 인증 후 간편비밀번호를 다시 등록해주세요',
                    button: {
                        yes: '확인'
                    },
                    callback: confirm => {
                        this.$store.commit('loginKey', null);
                    }
                }
            );  
        }
    }
}

var vm = window.app;

</script>

<style lang="scss">
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';

.testBg {
    background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='80px' style='padding: 10px'><text x='0' y='15' fill='red' font-size='20'>테스트</text></svg>");
}

</style>