<template>
    <div>
        <div class="access_wrap">
            <div class="inner_area">
                <h1>접근 불가</h1>
                <h1>Access denied</h1>
                <p class="txt">
                    해당 지역/국가의 접속이 허용되어있지 않습니다.<br/>
                    Access from the region/country is not allowed.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import System from '@/api/System'
    
    export default {
        created() {
            System.getCheck()
                .then(res => {
                    // 접근불가 해제
                    this.$store.commit('accessdenied', false);
                });
            
        } 
    };
    
</script>

<style >
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
</style>
    