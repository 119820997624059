<template>
    <div class="layer_view_area">
        <div class="layer_box">
            <div class="layer_head">
                <h2 class="ly_title">파일 업로드</h2>
            </div>
            <div class="layer_body">
                <div class="pl-2 pr-2">
                    <div class="mb-1">업로드 경로 : /{{path}}</div>
                    <input type="file" ref="file" class="pl-1" v-on:change="handleFileUpload()"/>
                    <div class="t_red" v-if="message">{{message}}</div>
                    <div>* 등록 가능 파일 : BMP, JPG, JPEG, GIF, PNG, TIF, TIFF, MP4</div>
                    <div>* 용량 제한 : 500MB</div>
                </div>
                <div class="btn_center">
                    <button type="button" class="btn_blk mr-1" v-on:click="submitFile()">업로드</button>
                    <button type="button" class="btn_wht" v-on:click="close()">닫기</button>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import axios from 'axios'
import File from '@/api/File'

export default {
        name: "File",
        data() {
            return {
                path: "",
                targetId: "",
                sfid: "",
                file: [],
                message: ""
            }
        },
        mounted() {
            this.path = this.$route.query.path;
            this.targetId = this.$route.query.targetId;
            this.sfid = this.$route.query.sfid;
            console.log('this.$route.query.key', this.$route.query.key);
        },
        methods: {

            submitFile() {
                const limitSize = 500;
                const file = this.file[0];

                let formData = new FormData();
                formData.append('path', this.path);
                formData.append('file', file);
                formData.append('key', this.$route.query.key);
                //console.log('path : ' + this.path);
                //console.log(file);

                if (file == null) {
                    this.message='파일을 선택해주세요.';
                    return;
                }

                let originalFilename = file.name;
                let pos = originalFilename.lastIndexOf('.');
                let ext = originalFilename.substring(pos + 1);
                let allowFiles = 'BMP, JPG, JPEG, GIF, PNG, TIF, TIFF, MP4';

                if (allowFiles.indexOf(ext.toUpperCase()) == -1) {
                    this.message = '등록 불가능한 파일입니다.';
                    return;
                }

                if (file.size > 1000*1000*limitSize) {
                    this.message = '파일 크기가 ' + limitSize + 'MB보다 작아야합니다.';
                    return;
                }
                /*
                if (this.file[0] == null) {
                    this.message='파일을 선택해주세요.';
                    return;
                }
                */

                File.post(formData)
                    .then((response) => {
                        console.log(response.data);
                        console.log(this.sfid);
                        if (opener) {
                            try {
                                let sfid = this.sfid;
                                opener.postMessage(eval('({"resultCode":"S", "fileUrl":response.data, "sfid":sfid})'), process.env.VUE_APP_LIGHTNING_URL);
                                //opener.document.getElementById(this.targetId).value = response.data;
                                alert("파일 업로드 완료");
                                close();
                            } catch(e) {
                                this.message = '오류 : ' + e;
                                this.message += '<br> 파일경로 : ' + response.data;
                            }
                        } else {
                            this.message = '오류 : 부모창을 찾지 못했습니다.';
                            this.message += '<br> 파일경로 : ' + response.data;
                        }
                    })
                    .catch((e) => {
                        //console.log(e.response.data.message);

                        this.message = '파일 업로드에 실패했습니다.';

                        if (e && e.response && e.response.data) {
                            if (e.response.data.message == 'Unauthorized') {
                                this.message = 'KEY 값이 일치하지 않습니다.';
                            } else if (e.response.data.message == 'Not an allowed file') {
                                this.message = '등록 불가능한 파일입니다.';
                            } else if (e.response.data.message.indexOf('Maximum upload size exceeded') > -1) {
                                this.message = '파일 크기가 ' + limitSize + 'MB보다 작아야합니다.';
                            }
                        }
                    });                  
            },
            handleFileUpload() {
                this.file = this.$refs.file.files;
                this.message = '';
                console.log(this.file);
            },
            close() {
                window.close();
            }      
        }
}
</script>