<template>
    <date-pick 
        v-model="pickDate" 
        @input="updateDate()" 
        :months="months" 
        :weekdays="weekdays"
        :startWeekOnSunday="true">
        <template v-slot:default="{toggle, inputValue}">
            <input type="text" :value="inputValue" @click="toggle"/>
            <button type="button" @click="toggle" class="ico_calendar"/>
        </template>
    </date-pick>
</template>

<script>
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
    components: {DatePick},
    props: ['date'],
    watch: {
        date: function(newVal, oldVal) {
            console.log(newVal);
            this.pickDate = newVal;
        }
    },
    data: () => ({
        pickDate: null,
        months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        weekdays: ['월','화','수','목','금','토','일']
    }),
    created() {
        this.pickDate = this.date;
    },
    methods: {
        updateDate() {
            this.$emit('update:date', this.pickDate)
        }
    }
};
</script>