import Send from '../utils/Send.js'

export default {
    insertOrder(json) {
        return Send({
            url: '/order',
            method: 'post',
            data : json,
            headers : {'Content-Type': 'application/json'},
        })
    },
    getOrderDetail(data) {
        return Send({
            url: '/order/'+data,
            method: 'get',
        })        
    },
    getOrderHistory(data) {
        return Send({
            url: '/order',
            method: 'get',
            params : data,
        })        
    },
    updateOrder(params) {
        return Send({
            url: '/order',
            method: 'put',
            data : params,
            headers : {'Content-Type': 'application/json'},
        })        
    },
    insertTempOrder(json) {
        return Send({
            url: '/order/temp',
            method: 'post',
            data : json,
            headers : {'Content-Type': 'application/json'},
        })
    },

    validateDuplicate() {
        return Send({
            url: '/order/validate/duplicate',
            method: 'get'
        })
    }
}