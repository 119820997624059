import Send from '../utils/Send.js'

export default {
    getCheck() {
        return Send({
            url: '/system/check',
            method: 'get',
            params: {
                timestamp: (new Date()).getTime()
            }
        })
    },
    getMaintenanceInfo() {
        return Send({
            url: '/system/info/maintenance',
            method: 'get'
        })
    }
}