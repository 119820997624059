<template>
    <div class="container" :key="refreshKey">
        <!-- popupRefund 환불팝업 -->
        <div class="sub_container" v-show="this.$store.state.specialHeader == 'refund'">
        <!-- <div class="layer_view_area" v-show="true"> -->
            <div class="info_chk">
                <span>가상 계좌 결제하신 금액을 환불 처리하오니<br> 환불 처리를 위한 입금할 은행과 계좌 번호를<br> 입력하여 주십시오.</span>
                <p class="txt_noti"><br>* 환불 처리는 당일 13시 이전 등록 시 익일 14시 이전에 입금되며, 금일 13시 이후에 입금 시 모레 입금될 예정입니다.
                <br>(환불 계좌를 잘못 입력하실 경우 입금이 늦어질 수 있습니다)</p>
            </div>
            <div class= "checkboxWrap">
                <div class="checkbox1">
                    <div class="allchk" style="display:none">
                    <!-- <input id="check01" class="allCheck" type="checkbox"  @click="allCheck()"/>
                    <label for="check01">개인정보 수집 및 처리 동의에 모두 동의</label> -->
                    <!-- <input id="check02" class="check" type="checkbox" @click="selectBox('check02')"/>
                    <label for="check02"><u class="mt-1" @click="evtPolicy(0)">개인(신용)정보 수집 및 이용 관해 동의합니다.</u></label> -->
                    </div>
                    <div class="sub">
                        <input id="check02" class="check" type="checkbox" @click="selectBox('check02')"/>
                        <label for="check02"><u class="mt-1" @click="evtPolicy(0)">개인(신용)정보 수집 및 이용 관해 동의합니다.</u></label>
                        <!-- <input id="check03" class="check" type="checkbox" @click="selectBox('check03')"/>
                        <label for="check03" class="mt-2"><span class="mt-2">본인 명의 아닌 타인 명의 계좌 입력 시, 회사는 이체 지연 및 이체 오류 등에 대하여 일체 책임지지 않습니다.</span></label> -->
                        <!-- <input id="check04" class="check" type="checkbox" @click="selectBox(check04)"/>
                        <label for="check04"><u @click="evtPolicy(2)">제 3자 제공(위탁)동의서(필수)</u></label> -->
                    </div>
                </div>
            </div>
            <!-- 환불요청 -->
            <div class="info">
                <!-- <h2 class="sub_tit">환불요청</h2> -->
                <table class="table_info">
                    <tbody>
                        <tr>
                            <th class="fs-17">은행명</th>
                            <td>
                                <select v-model="pickBank" >
                                    <option value="">은행선택</option>
                                    <option v-for="(bank,index) in bankList" :key="index" :value="bank.vcBankCode+bank.bankName">{{bank.bankName}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th class="fs-17">계좌번호</th>
                            <td>
                                <input type="text" v-model="accountNo" maxlength="30" />
                            </td>
                        </tr>
                        <tr>
                            <th class="fs-17">예금주명</th>
                            <td>
                                <input type="text" v-model="accountName" maxlength="7" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <!-- //환불요청 -->

        </div>
        <!-- //popupRefund 환불팝업 -->
        <!--  버튼 -->
        <div class="total_button">
            <div class="combo_form">
                <a class="btn_blk bigger" @click="$root.goTo('/orderHistory')">취소</a>
                <!-- <a class="link_button2" v-show="!refundActive">환불하기</a> -->
                <a class="link_buttonRed" @click="refundValid()" v-if="refundActive">환불하기</a>
                <a class="link_buttonGray" v-else>환불하기</a>
            </div>
        </div>
        <!-- //버튼 -->

        <!-- 계좌confirm 팝업 -->
        <div class="layerdim_wrap" v-show="popupAccountConfirm">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <h2 class="ly_title">환불요청</h2>
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>입력하신 예금주명/계좌번호가 올바른지 <br> 다시한번 확인부탁드립니다</p>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht"  @click="popupClose()">다시 확인</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="refundPopup()">다음 단계</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //계좌confirm 팝업 -->

      <!-- 환불confirm 팝업 -->
      <div class="layerdim_wrap" v-show="popupRefundConfirm">
        <div class="dim_inner">
          <div class="layer_wrap">
            <div class="layer_box">
              <div class="layer_head">
                <h2 class="ly_title">환불요청</h2>
                <a class="btn_close" @click="popupClose()">닫기</a>
              </div>
              <div class="layer_body" style="text-align: center;">
                <p>{{orderDate}} 주문을 <br> {{accountName}} {{accountNo}}({{pickBank.slice(3)}})로 <br>환불 요청 하시겠습니까?</p>
                <div class="btn_center combo_form">
                  <div>
                    <a class="btn_wht"  @click="popupClose()">아니요</a>
                  </div>
                  <div>
                    <a class="btn_wht" @click="evtRefund()">예</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //환불confirm 팝업 -->

        <!-- 환불 성공 후, 팝업 -->
        <div class="layerdim_wrap" v-show="popupAfterRefund">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <h2 class="ly_title">{{popupMergeTxt}}</h2>
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>{{popupMergeTxt2}} 정상적으로<br>처리되었습니다. <br>
                                장바구니에 추가하시겠습니까?
                            <br>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht"  @click="goOrderHistory()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="newCart(false)">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //환불 성공 후, 팝업 -->
        <!-- refund popPolicy1 -->
        <div class="layer_view_area" v-show="this.$store.state.specialHeader == 'showPolicy'">
            <!-- header --> 
            <div class="header_wrap">
                <div class="header">
                    <h1>{{policyTitle}}</h1>
                    <div class="hd_left">
                        <a class="aside_back" @click="closePolicy()"><span>뒤로가기</span></a>
                    </div>
                </div>
            </div>
            <!-- //header -->

            <div class="container" v-show="this.$store.state.specialHeader == 'showPolicy'">
                <div class="cont_in">
                    <!-- <div class="select_box">
                        <select v-model="policy">
                            <option v-for="option in policyOptions" v-bind:key="option.value" v-bind:value="{id: option.id, text: option.text}">{{option.text}}</option>                        
                        </select>
                        <span>{{policy.text}}</span>
                    </div> -->
                    <img id="scrollInit" :src="getPolicyImage()"/>
                </div>
            </div>
        </div>
        <!-- //refund popPolicy1 -->
        <div class="layerdim_wrap" v-show="isLoading">
            <div class="dim_inner">
                <div class="loading_api">
                    <div></div>
                </div>
            </div>
        </div>  
    </div>
</template>

<script>
// import axios from "axios";
import $ from 'jquery'
import Cart from '@/api/Cart'
import Payment from '@/api/Payment'
import moment from 'moment'

export default {
    components: {
    },
    data: () => {
        return {
            orderDate:'',
            bankList: [],
            pickBank: '',
            accountNo:'',
            accountName:'',

            popupMergeTxt:'',
            popupMergeTxt2:'',

            showPolicy: false,
            showPolicy1: false,
            showPolicy2: false,
            policyTitle: '개인정보 이용 동의서',
            policyNumber: '',
            policy:'',
            policyOptions: [
                {text: '개인정보 이용 동의서', id: 'refund4.png'}
            ],
            refundActive:false,

            popupRefund: false, // 환불 화면
            popupRefundConfirm: false, // 환불 화면 이전 confirm창
            popupAccountConfirm : false, //계좌 확인 confirm창
            popupAfterRefund: false, // 환불 완료 후, 주문 병합confirm창
            isLoading: false, 
            orderList: [],// 현재 주문 상품목록

            cart:null,
            cartList:[],
            isCartList:false, // 장바구니 존재여부
            refreshKey: 0,
            
        }
    },
    watch: {
        $route (){
            // 라우터 변화 감지 시 창 닫기
            // this.close();
            this.refreshKey++;
            // console.log('route변화 감시(watch)')
            // if(this.$route.query.policy){
            // console.log('this.$route.query.policy:  '+this.$route.query.policy)

            //     let number = this.$route.query.policy;
            //     this.policyNumber = this.$route.query.policy;
            //     this.$store.commit('specialHeader', 'showPolicy');
            //     // this.$router.push({name: 'Refund' ,params: { policy: this.policyNumber }})
            //     this.$route.meta.title = this.policyOptions[number].text;
            //     this.policy = this.policyOptions[number];
            //     this.policyTitle = this.policyOptions[number].text;

            // } else {
            //     this.$store.commit('specialHeader', 'refund');
            //     this.$route.meta.title = '환불요청';
            // }
        }
    },
    created(){
        // 새로고침 했을 때, 헤더 다시 띄우기...
        // this.$store.commit('specialHeader', 'refund');
        // this.$route.meta.title = '환불요청';
            console.log('------------created()----------')
            this.$store.commit('specialHeader', 'refund');
            this.$route.meta.title = '환불요청';
        // if(this.$route.query.policy){
        //     console.log('새로고침 했을 때, 헤더 다시 띄우기...')
        //     let number = this.$route.query.policy;
        //     this.policyNumber = this.$route.query.policy;
        //     // this.$router.push({name: 'Refund' ,params: { policy: this.policyNumber }})
        //     this.$store.commit('specialHeader', 'showPolicy');
        //     this.$route.meta.title = this.policyOptions[number].text;
        //     this.policy = this.policyOptions[number];
        //     this.policyTitle = this.policyOptions[number].text;
        // } else {
        //     this.$store.commit('specialHeader', 'refund');
        //     this.$route.meta.title = '환불요청';
        // }


        this.policy = this.policyOptions[0];

        // 은행목록(환불)
        Payment.getBankListRefund({isVc:''}).then((res) => {
            this.bankList = res.data;
        })
        this.orderList = this.$store.state.refundData.list;             // 현재 주문 상품목록
        this.orderNumber = this.$store.state.refundData.eorderNumber;   // 주문번호
        this.orderDate = this.$store.state.refundData.orderDate;        // 주문일자

        // 장바구니 조회
        Cart.getCart()
        .then((response) => {
            this.cartList = response.data;
            console.log(this.cartList)
            if(this.cartList.length > 0){
                this.isCartList = true;
            }
        })
        .catch((e) => {
        console.log("error : ", e);
        });
        
    },
    methods:{
        goBack(){ //뒤로가기
            this.$router.go(-1);
        },

        allCheck(){
            if($('input[id=check01]:checkbox').is(":checked")){
               $("input[id=check02]:checkbox").prop("checked", true);
            //    $("input[id=check03]:checkbox").prop("checked", true);
            //    $("input[id=check04]:checkbox").prop("checked", true);
                //전체 ischecked true
                this.refundActive = true;
                // this.refundActiveStyle.backgroundColor = '#e31a33';


            }else{

               $("input[id=check02]:checkbox").prop("checked", false);
            //    $("input[id=check03]:checkbox").prop("checked", false);
            //    $("input[id=check04]:checkbox").prop("checked", false);

                //전체 ischecked false
                this.refundActive = false;
            }
            
        },

         selectBox(checkBoxList){
            //약관선택 비교 전체선택 on | off
            // if($("input:checkbox[class=check]:checked").length == 2){
            if($('input[id=check02]:checkbox').is(":checked")) {
                $('input[id=check01]:checkbox').prop("checked", true);
                this.refundActive = true;
            }else {
                $('input[id=check01]:checkbox').prop("checked", false);
            }
            // else{
            //     $('input[id=check01]:checkbox').prop("checked", false);
            //     this.refundActive = false;
            // }

            if($("input[id="+checkBoxList+"]:checkbox").is(":checked")){
                //선택된 상품 ischecked true

            }else{
                //선택된 상품 ischecked false

            }


        },
        evtPolicy(number){ // 약관페이지 팝업 오픈
        
            // this.$router.push({name: 'Refund' ,query: { policy: number }})
            this.$store.commit('specialHeader', 'showPolicy');
            this.$route.meta.title = this.policyOptions[number].text;
            // if(number == 0)this.showPolicy= true;

            // if(number == 1)this.showPolicy1= true;
            // if(number == 2)this.showPolicy2= true;
            this.policy = this.policyOptions[number];
            this.policyTitle = this.policyOptions[number].text;
        },
        closePolicy(){
            this.$store.commit('specialHeader', 'refund');
            this.$route.meta.title = '환불요청';
            // this.showPolicy = false
        },
        refundValid(){ // 유효성검사
            let regex = /^[0-9]+(?:-[0-9]+)*$/i; // 숫자나 대쉬(-)만 허용, 첫번째 자리나 마지막 자리에 대쉬(-) 올 수 없음
            
            if(!this.pickBank || this.pickBand ==""){
                // alert("은행을 선택해주세요.");
                this.$dialog('은행을 선택해주세요.');
                // this.$dialog('<div class="paragraph" style="text-align:left">은행을 선택해주세요.</div>');

            } else if(!this.accountNo || this.accountNo.toString().trim() ==""){
                this.$dialog('계좌번호를 입력해주세요.');
                // alert("계좌번호를 입력해주세요.");
            } else if(!regex.test(this.accountNo)){
                this.$dialog('계좌번호를 정확히 입력해주세요.');
                // alert("계좌번호를 정확히 입력해주세요.");
            } else if(!this.accountName || this.accountName.trim() ==""){
                this.$dialog('예금주명을 입력해주세요.');
                // alert("예금주명을 입력해주세요.");
            } else {
                // this.popupRefundConfirm = true;
                this.popupAccountConfirm = true;
            }
        },
        popupClose() {
            // this.popupDetail.display="none";
            this.popupRefund = false;
            this.popupRefundConfirm = false;
            this.popupAccountConfirm = false;
            this.popupAfterRefund = false;
        },
        refundPopup() {
          this.popupAccountConfirm=false;
          this.popupRefundConfirm=true;
        },
        evtRefund() { // 환불 api호출
            this.isLoading = true;
            this.popupRefundConfirm = false;
            let order = {
                  orderNumber: this.orderNumber,
                  userBankCode: this.pickBank.substring(0,3),
                  userAccount: this.accountNo, 
                  userName: this.accountName
            }
            Payment.refund(order)
            .then((res) => {
                this.isLoading = false;
                if(res == 1 || res.data == 1) { // 환불성공
                    this.popupRefundConfirm = false;
                    this.popupAfterRefund = true;
                    // alert("환불 요청이 정상적으로<br>처리되었습니다.");
                    // this.$dialog('환불 요청이 정상적으로<br>처리되었습니다.');
                    // this.$dialog(
                    //     {
                    //         title: '알림',
                    //         message: '환불 요청이 정상적으로 처리되었습니다.',
                    //         button: {
                    //             yes: '확인',
                    //         },callback: confirm => {
                    //             console.log(confirm)
                    //             this.$router.push({name:'OrderHistory'})
                    //         }
                    //     }
                    // );

                    // 주문병합하기 넣어야함.
                    // this.popupAfterRefund = true;

                } else { // 환불실패
                    this.popupAfterRefund= false;
                    // alert("환불 요청에 실패했습니다.");
                     this.$store.commit('specialHeader', null);
                    // this.$router.push({name:'OrderHistory'})
                    this.$dialog(
                        {
                            title: '알림',
                            message: '환불 요청에 실패했습니다.',
                            button: {
                                yes: '확인',
                            },callback: confirm => {
                                console.log(confirm)
                                this.$router.push({name:'OrderHistory'})
                            }
                        }
                    );
                }

                console.log(res);
                this.popupMergeTxt = '환불요청'
                this.popupMergeTxt2 = '환불요청이'
                
            }).catch(error => {
                this.isLoading = false;
                console.log(error)

                this.$dialog(
                    {
                        title: '알림',
                        message: error.response.data.message,
                        callback: confirm => {
                            this.$router.push({ name: 'OrderHistory' })
                        }
                    }
                );

                //location.href='/orderHistory';
            })
        },
        getPolicyImage() { // 서류 이미지
            let image = require.context('@/assets/html/policy', false);
            return image('./' + this.policy.id);
        },
        goOrderHistory(){
            this.popupAfterRefund = false;
            this.$store.commit('specialHeader', null);
            this.$router.push({name:'OrderHistory'})
        },
        //추가하기
        mergeCart(checked){ //checked말고 숫자로 상태주는걸로 수정해야함. 1:주문수정, 2:주문취소, 3:환불 , 4:재주문
            this.$store.commit('orderUpdate', null);
            if(this.isCartList){// 장바구니 있으면
                for(var idx in this.orderList){
                    this.cart = {'productCode' : this.orderList[idx].productCode, 'quantity' : this.orderList[idx].quantity,'clickValue': 'cartMerge'};     
                    // this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].deliveryQty,'clickValue': 'cartMerge'};     
                    this.cartList.push(this.cart)
                }
                const json = JSON.stringify(this.cartList);

                Cart.updateCart(json)
                .then((response) => {
                    if(1 == response.data){
                        this.$store.commit('specialHeader', null);
                        this.goRouteName = 'updateCart';
                        this.$router.push({name:'Cart'})
                    }else{
                        console.log("재주문하기 장바구니 상품 있을시 insert error!")
                    }
                })
                .catch((e) => {
                console.log("error : ", e);
                });
            } else { //장바구니 없음
                this.newCart(0); // 0: 장바구니 없음, 1: 있음
            }
        },

        async newCart(num=2) { //  0: 장바구니 없음, 1: 있음, 2:default
            this.$store.commit('orderUpdate', null);
            if(this.isCartList || num == 1) { // 장바구니 있음
                var params = [{'customerCode':this.$store.state.customer.CustomerCode__c}];

                // 장바구니 삭제
                await Cart.deleteCart(params)
                .then((response) => {
                    var result = response.data;

                    if(result > 0){ //cart delete 성공
                        this.cart={};
                        this.cartList = [];
                        for(var idx in this.orderList){
                            if(this.orderList[idx].quantity != 0){
                                 this.cart = {'productCode' : this.orderList[idx].productCode, 'quantity' : this.orderList[idx].quantity,'isOrdered':true};     
                                // this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].deliveryQty,'isOrdered':true};     
                                this.cartList.push(this.cart)
                            }
                        }
                    }else{
                        console.log('장바구니 삭제 에러!')
                    }

                })
                .catch((e) => {
                console.log("error : ", e);
                });
            } else if(!this.isCartList && num == 0) { // 장바구니 없음
                for(var idx in this.orderList){
                    if(this.orderList[idx].quantity != 0){
                        this.cart = {'productCode' : this.orderList[idx].productCode, 'quantity' : this.orderList[idx].quantity};     
                        // this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].deliveryQty};     
                        this.cartList.push(this.cart)
                    }
                }
            }
            
            const json = JSON.stringify(this.cartList);

            //cart insert
            await Cart.insertCart(json)
            .then((response) => {
                if(1 == response.data){
                    this.$store.commit('specialHeader', null);
                    this.goRouteName = 'updateCart';
                    this.$router.push({name:'Cart'})
                }else{
                    console.log("재주문하기 장바구니 상품 있을시 insert error!")
                }
            })
            .catch((e) => {
            console.log("error : ", e);
            });
        },
    }
}
</script>
<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
</style>