import Send from '../utils/Send.js'

export default {
    getCart(json) {
        return Send({
            url: '/cart',
            method: 'get',
            params : json,
        })        
    },
    
    insertCart(json) {
        return Send({
            url: '/cart',
            method: 'post',
            data : json,
            headers : {'Content-Type': 'application/json'},
        })
    },

    deleteCart(json){
        return Send({
            url: '/cart',
            method: 'delete',
            data : json,
            headers : {'Content-Type': 'application/json'},
        })        
    },

    updateCart(params){
        return Send({
            url: '/cart',
            method: 'put',
            data : params,
            headers : {'Content-Type': 'application/json'},
            
        }) 
    },

    getTempCart(json) {
        return Send({
            url: '/cart/temp',
            method: 'get',
            params : json,
        })        
    },
    
    insertTempCart(json) {
        return Send({
            url: '/cart/temp',
            method: 'post',
            data : json,
        })
    },

}