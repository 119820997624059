import Send from '@/utils/Send.js'

export default {
    getDetail() {
        return Send({
            url: '/customer',
            method: 'get'
        })
    },

    getPlan() {
        return Send({
            url: '/customer/plan',
            method: 'get'
        })
    },

    getPlans() {
        return Send({
            url: '/customer/plans',
            method: 'get'
        })
    }
}