import axios from 'axios';
import store from '@/store';
import router from '@/router';

/*
    axios 인스턴스를 생성합니다.
    생성할때 사용하는 옵션들 (baseURL, timeout, headers 등)은 다음 URL에서 확인할 수 있습니다.
    https://github.com/axios/axios 의 Request Config 챕터 확인
*/
const instance = axios.create({
    baseURL: process.env.VUE_APP_HEROKU_API_URL,
    timeout: 60000
    //,headers: {'Content-Type': 'multipart/form-data'}
  });

instance.interceptors.request.use(
    function(config) {
        // 미리보기인 경우 url 변경
        if (router.history.current.name == 'Preview') {
            config.url = '/preview' + config.url;
        }

        // Do something before request is sent
        if (store.state.session != null && store.state.session.token != null) {
            config.headers.Authorization = 'Bearer ' + store.state.session.token;
        }

        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    },
);



/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
instance.interceptors.response.use(
    function (response) {
    /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다. 
        .then() 으로 이어집니다.
    */
        let config = response.config;

        // 장바구니 수량 변경 감지하여 수량 업데이트
        if ((config.url.indexOf('/cart') == 0 || config.url.indexOf('/order') == 0)
                && config.method != 'get') {
            instance
                .get('/cart')
                .then(res => {
                    console.log(res.data.length);
                    store.commit('cart', res.data);
                })
        }
        // 장바구니 수량 체크
        //console.log('match:' + config.url.match('/cart', 'order'));
        //if (config.url.match('/cart', 'order')) {

        //}

        return response;
    },

    function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 처리를 작성합니다.
        .catch() 으로 이어집니다.    
    */

        if (error.response) {
            console.log(error.response);
            if (error.response.status == 401) {
                // 권한없음 에러 시 로그인 페이지로 이동
                store.commit('logout');
            } else if (error.response.status == 500) {
                if (error.response.data.status == 900 && store.state.maintenance == null) {
                    // 시스템점검중
                    instance
                        .get('/system/info/maintenance')
                        .then(res => {
                            store.commit('maintenance', res.data);
                        });
                }

                if (error.response.data.status == 901 && store.state.accessdenied == false) {
                    store.commit('accessdenied', true);
                }

                // 메세지 내용이 배열 형태인 경우 분해해서 담아주기
                let message = error.response.data.message;
                if (message.indexOf('|') > -1) {
                    let messageList = message.split('|');
                    error.response.data.message = messageList[0]
                    error.response.data.info = messageList.slice(1);
                }
            }
        }

        return Promise.reject(error);
    }
);

// 생성한 인스턴스를 익스포트 합니다.
export default instance;