import { events } from './events'
//import VueConfirmDialog from './vue-confirm-dialog.vue'
import VueDialog from './VueDialog.vue'

export default {
  /* vue3
  install: (app) => {
    //if (this.installed) return

    //this.installed = true
    //this.params = options

    //console.log(app);
    //console.log(options);

    //app.component(options.componentName || 'vue-confirm-dialog', VueDialog)
    //console.log('install vue-dialog')
    app.component('vue-dialog', VueDialog)

    const confirm = params => {
      if (typeof params != 'object' || Array.isArray(params)) {
        if (typeof params == 'string') {
          // 문자열로 들어온 경우 alert으로 처리
          params = {
            type: 'alert',
            message: params
          }
        } else {
          let caughtType = typeof params
          if (Array.isArray(params)) caughtType = 'array'
  
          throw new Error(
            `Options type must be an object. Caught: ${caughtType}. Expected: object`
          )
        }
      }

      if (typeof params === 'object') {
        if (
          //params.hasOwnProperty('callback') &&
          Object.prototype.hasOwnProperty.call(params, "callback") &&
          typeof params.callback != 'function'
        ) {
          let callbackType = typeof params.callback
          throw new Error(
            `Callback type must be an function. Caught: ${callbackType}. Expected: function`
          )
        }
        events.emit('open', params)

      }
      
      return confirm
    }
    confirm.close = () => {
      events.emit('close')
    }

    //vue3
    //app.config.globalProperties.$dialog = confirm
    
    //vue2
    app.provide("$dialog", {confirm});
    Vue['$dialog'] = confirm
  }    
  */
  //vue2
  install(Vue, args = {}) {
    //if (this.installed) return

    //this.installed = true
    //this.params = options

    //console.log(app);
    //console.log(options);

    //app.component(options.componentName || 'vue-confirm-dialog', VueDialog)
    //console.log('install vue-dialog')
    Vue.component('vue-dialog', VueDialog)

    const confirm = params => {
      if (typeof params != 'object' || Array.isArray(params)) {
        if (typeof params == 'string') {
          // 문자열로 들어온 경우 alert으로 처리
          params = {
            type: 'alert',
            message: params
          }
        } else {
          let caughtType = typeof params
          if (Array.isArray(params)) caughtType = 'array'
  
          throw new Error(
            `Options type must be an object. Caught: ${caughtType}. Expected: object`
          )
        }
      }

      if (typeof params === 'object') {
        if (
          //params.hasOwnProperty('callback') &&
          Object.prototype.hasOwnProperty.call(params, "callback") &&
          typeof params.callback != 'function'
        ) {
          let callbackType = typeof params.callback
          throw new Error(
            `Callback type must be an function. Caught: ${callbackType}. Expected: function`
          )
        }
        events.emit('open', params)

      }
      
      return confirm
    }
    confirm.close = () => {
      console.log('dialog close')
      events.emit('close')
    }

    //vue3
    //app.config.globalProperties.$dialog = confirm
    
    //vue2
    Vue.prototype.$dialog = confirm
    Vue['$dialog'] = confirm
  }
}
