<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">
            <!-- 상단 알림 -->
            <div class="sec">
                <div class="Notification_wrap" v-bind:style="deadLineOrder">
                    <span>{{orderDeadLineDisplay}} 까지 수정 가능</span>
                    <span>{{ countDown }}분 남음</span>
                </div>
            </div>
            <!-- //상단 알림 -->
            <!-- 20220914~  주문완료 - 상단 경고 알림 -->
            <div class="warning_box">
                <i></i>
                <p class="warning_caption">
                    아직 주문이 완료되지 않았습니다.<br>
                    하단의 주문완료 버튼을 이용하여 주문을 마무리해주세요.
                </p>
            </div>
            <!-- 20220914~ // 주문완료 - 상단 경고 알림 -->

            <!-- 결제(e-payment) -->
            <div class="info" v-show="epayment == 'E'">
                <h2 class="sub_tit">결제</h2>
                <div class="pl-2">
                    <table class="table_info">
                        <tbody>
                            <!-- 가상계좌 -->
                            <tr v-show="vcActive == 't'">
                                <td @click="payType = 'VC'">
                                    <div class="radio">
                                        <input type="radio" id="payType_VC" v-model="payType" value="VC" />
                                        <label for="payType_VC">가상계좌</label>
                                    </div>
                                </td>
                            </tr>
                            <!-- 가상계좌(은행선택) -->
                            <tr v-show="payType == 'VC'">
                                <td>
                                    <div class="pay_lst_chk" role="tablist">
                                        <div v-for="bank in bankList" :key="bank.vcBankCode" role="none presentation"
                                            :class="bankCode == bank.vcBankCode ?'selected':''">
                                            <a role="tab" @click="fnPickBank(bank.vcBankCode)">
                                                <span class="splite__payment" :class="'bank_'+bank.vcBankCode"></span>
                                                <strong>{{bank.bankName}}</strong></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- 간편결제 -->
                            <tr v-show="ezActive == 't'">
                                <td @click="isEzPayOk? payType = 'EZ':''">
                                    <div class="radio">
                                        <input type="radio" id="payType_EZ" v-model="payType" value="EZ" :disabled="!isEzPayOk" />
                                        <label for="payType_EZ">간편결제</label>
                                    </div>
                                    <!-- <input style="width:10px;height:10px;border:1px;vertical-align:top;" class="left" type="radio" v-model="payType" value="EZ" :disabled="!isEzPayOk">  간편결제 -->
                                    <span class="ml-3 t_red" v-if="!isEzPayOk" style="display:inline-block;">* 간편결제 최대 한도는 200만원입니다.
                                        <br>가상계좌를 이용부탁드립니다.</span>
                                    <div class="checkboxWrap2" v-if="payType == 'EZ'">
                                        <div class="checkbox1">
                                            <div class="sub">
                                                <input id="check02" class="check" type="checkbox" v-model="checkEZPolicy" />
                                                <label for="check02"><u @click="showEZPolicy">다날 간편결제 약관에 동의합니다.</u></label>
                                                <!-- <input id="check03" class="check" type="checkbox" @click="selectBox('check03')"/>
                                                            <label for="check03" class="mt-2"><span class="mt-2">본인 명의 아닌 타인 명의 계좌 입력 시, 회사는 이체 지연 및 이체 오류 등에 대하여 일체 책임지지 않습니다.</span></label> -->
                                                <!-- <input id="check04" class="check" type="checkbox" @click="selectBox(check04)"/>
                                                            <label for="check04"><u @click="evtPolicy(2)">제 3자 제공(위탁)동의서(필수)</u></label> -->
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- 현금수금 -->
                            <tr v-show="ccActive == 't'">
                                <td @click="payType = 'CC'">
                                    <div class="radio">
                                        <input type="radio" id="payType_CC" v-model="payType" value="CC" />
                                        <label for="payType_CC">현금수금</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div ref="divPaymentMethod"></div>
            </div>
            <!-- //결제(e-payment) -->
            
            <!-- 20220914~ 주문하기 리스트 -->
            <!-- 총 주문 금액 -->
            <div>
                <dl class="amount_area">
                    <dt>총 주문 금액</dt>
                    <dd class='amount'><span>원</span></dd>
                </dl>
            </div>
            <div class="sec paragraph">
                <dl class="amount_area">
                    <dt>총 수량</dt>
                    <dd class='quantity'>{{total.quantityString}}</dd>
                </dl>
                <ul class="purchase_list orderwrap">
                    <li v-for="orderList of orderLists" :key="orderList.productCode">
                        <div class="product_info">
                            <div class="thumb_img">
                                <vue-image :src="orderList.imageUrl" size="75" />
                            </div>
                            <div class="des_area">
                                <div class="name">{{orderList.productNm}}</div>
                                <div class="composition">
                                    <span>{{orderList.addInfo1}}
                                        <em></em>
                                    </span>
                                    <span>{{orderList.addInfo2}}
                                        <em></em>
                                    </span>
                                </div>
                                <div class="composition" v-if="orderList.addInfo1 == null">
                                    <span><pre> </pre></span>
                                </div>
                                <div class="option">
                                    <div class="count_area">
                                        <input type="text" :value="orderList.quantity+' (' + unitDefine[orderList.productType].name + ')'" readonly>
                                    </div>
                                    <div class="sum_area">
                                        <strong>{{orderList.amount | makeComma}}</strong>
                                        <span>원</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 20220914~ // 주문하기 리스트 -->

            <!-- 할인(Trading Program) -->
            <template v-if="!hasPackage">
                <div class="info" v-if="dcList">
                    <h2 class="sub_tit">할인</h2>
                    <div class="pl-2">
                        <table class="table_info">
                            <tbody>
                                <tr v-for="(item,index) in dcList" :key="index">
                                    <td @click="fnPickDc(item)">
                                        <div class="radio">
                                            <input type="radio" :id="'dc_'+item.codeDetail"  v-model="pickDc" :value="item.codeDetail"/>
                                            <label :for="'dc_'+item.codeDetail">{{item.name}}</label>
                                            <span class="t_red bold"> ({{comma(item.amount)}})</span> <span style="color:gray;"> (사용기한: {{item.expireDt.substring(0,10)}}까지)</span>
                                        </div> 
                                    </td>
                                </tr>
                                <!-- 사용안함은 데모에서만 사용. for 쿠폰적용 테스트 -->
                                <!-- <tr>
                                    <td @click="dcAmt=0">
                                        <div class="radio">
                                            <input type="radio" name="rbtn_store" :id="'dc_no'"  v-model="pickDc" value=""/>
                                            <label :for="'dc_no'">할인적용안함</label>
                                        </div> 
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div ref="divDiscount"></div>
                </div>
                <!-- //할인(Trading Program) -->

                <!-- 할인 금액 -->
                <div>
                    <dl class="amount_area"  v-if="dcList">
                        <dt>할인 금액</dt>
                        <dd><span class="dc">{{dcAmt>0?'-':''}}{{comma(dcAmt)}}원</span></dd>
                    </dl>
                </div>
            </template>
            <!-- 최종 결제 금액 -->
            <div>
                <dl class="amount_area">
                    <dt>최종 결제 금액</dt>
                    <dd>{{comma(finalAmt)}}원</dd>
                </dl>
            </div>



            <!-- 주소 -->
            <div class="sec">
                <div class="address_wrap">
                    <h2 class="sub_tit">{{name}}</h2>
                    <p class="txt_add">
                        <span>{{addressZipCode}}</span>
                        {{address}}
                    </p>
                    <p class="txt_noti">
                        <!--영업사원 정기 방문 요일은 {{customerVO.DeliveryDayKor}} 입니다.<br>-->
                        배송예정일은 <span class="bold">{{EdeliveryDate}} ({{$root.dayKor(EdeliveryDate)}})</span> 입니다.<br>
                        (주문마감 {{cutOffDateDash}} ({{$root.dayKor(cutOffDateDash)}}) {{orderDeadLineDisplay}} 까지)<br><br>
                        주문이 확정되면 배송일정은 문자로 안내 됩니다.
                    </p>
                    <div class="text_box">
                        <textarea name="request" v-if="orderedRequests" id="request" v-model="orderedList.requests" placeholder="요청사항을 작성해 주세요.(50자)" maxlength="50" ></textarea>
                        <textarea name="request" v-else-if="!orderedRequests" id="request" v-model="requestValue" placeholder="요청사항을 작성해 주세요.(50자)" maxlength="50" ></textarea>
                        <!-- <textarea name="request" v-if="orderedRequests" id="request" :value="orderedList.requests" placeholder="요청사항을 작성해 주세요.(50자)" maxlength="50" ></textarea>
                        <textarea name="request" v-else-if="!orderedRequests" id="request" placeholder="요청사항을 작성해 주세요.(50자)" maxlength="50" ></textarea> -->
                    </div>
                </div>
            </div>
            <!-- //주소 -->


            <!-- <div class="total_button">
                <div class="combo_form"> @click="goCart()"
                    <a class="btn_blk bigger" @click="$root.goTo('/cart')">장바구니 돌아가기</a>
                    <a class="link_button" @click.once="checkValid()" :key="orderButtonKey">{{payType=='VC'|| payType=='EZ'?'결제완료 하기':'주문완료 하기'}}</a>
                </div>
            </div> -->
            <!-- //버튼 -->
        </div>
        <!-- //container -->
        <!-- 20220914~ 버튼 -->
        <div class="product_fixed">
            <div class="total_button">
                <div class="combo_form">
                    <a class="btn_blk bigger" @click="$root.goTo('/cart')">장바구니 돌아가기</a>
                    <a class="link_button" @click.once="checkValid()" :key="orderButtonKey">{{payType=='VC'||
                        payType=='EZ'?'결제완료 하기':'주문완료 하기'}}</a>
                </div>
            </div>
        </div>
        <!-- 20220914~ //버튼 -->
        <!-- 버튼 -->
        <!-- 주문 마감 팝업 -->
        <div class="layerdim_wrap" v-bind:style="popUpDeadLine">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">주문마감</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                        <p>{{message}}</p>
                            <div class="btn_center">
                                <a class="btn_wht" @click="goProductList()">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //주문 마감 팝업 -->
        
        <!-- 결제 장애 팝업 -->
        <div class="layerdim_wrap" v-show="popEzPayError">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <h2 class="ly_title">알림</h2>
                        </div>
                        <div class="layer_body">
                            <p>결제 서비스 장애 안내</p>
                            <div class="paragraph">
                                <br>
                                현재 결제 시 승인 오류가 간헐적으로 발생하고 있습니다.<br>
                                빠른 시간 내 조치하도록하겠습니다.<br>
                                서비스 이용에 불편을 드려 죄송합니다.
                            </div>
                            <div class="btn_center">
                                <a class="btn_wht" @click="close()">닫기</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //결제 장애 팝업 -->

        <!-- 간편결제 약관 팝업 -->
        <div class="layer_view_area" style="z-index:1000" v-if="isShowEZPolicy">
            <!-- header -->
            <div class="header_wrap">
                <div class="header">
                    <h1>간편결제 약관</h1>
                    <div class="hd_left">
                        <a class="aside_back" @click="isShowEZPolicy = false"><span>뒤로가기</span></a>
                    </div>
                </div>
            </div>
            <!-- //header -->
        
            <div class="container">
                <div class="cont_in">
                    <img id="scrollInit" src="../../assets/html/policy/DANAL_20220501.png" />
                </div>
            </div>
        </div>
        <!-- //간편결제 약관 팝업 -->

        <!-- 로딩 이미지 -->
        <div class="layerdim_wrap" v-show="isLoading">
            <div class="dim_inner">
                <div class="loading_api">
                    <div></div>
                </div>
            </div>
        </div>    
        <!-- //로딩 이미지 -->

        <!-- 다날 페이지로 이동 -->
        <div id="refundRoot">
            <form :action="danalRes.STARTURL" method="POST" id="refundForm" ref="refundForm">
                <input type="hidden" id="STARTPARAMS" name="STARTPARAMS" :value="danalRes.STARTPARAMS"/>
            </form> 
        </div>
        <!-- //다날 페이지로 이동 -->
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery'
import Code from '@/api/Code'
import Order from '@/api/Order'
import Cart from '@/api/Cart'
import Discount from '@/api/Discount'
import Customer from '@/api/Customer'
import Payment from '@/api/Payment'
import moment from 'moment'
import VueImage from '@/components/common/vue-image';
import { computed } from '@vue/composition-api';

export default {
    components: {
        VueImage
    },    
    data: () => {
        return  {
            isQuickOrder : false,        // 즉시주문 여부
            orderLists : [],            //주문하기 리스트
            amount : 0,                 //총주문금액
            totalQuantity : 0,          //총주문수량
            result : '',                //주문 insert 후 결과값
            customerVO : [],            //로그인한 고객 정보
            name : '',                  //점포명
            address : '',               //주소
            addressZipCode: '',         //우편번호?
            deliveryDate2 : '',          //배송전날 요일
            deliveryNum : '',           //배송요일 getDay값   
            EdeliveryDate : '',         //예상 배송일
            orderedList : null,           //주문완료된 리스트
            message : '',               //팝업 메시지
            countDown : 30,             //남은 주문마감시간
            deadLineOrder : {display : 'none'},     //배송전날 마감시간 -30분전 표시
            popUpDeadLine : {display : 'none'},     //배송전날 마감시간 이후 주문완료 클릭 시 주문 안되게 팝업
            // popUpGoOut : {display : 'none'},
            orderDeadLine : '',         //주문마감시간
            orderDeadLineDisplay : '',  //주문마감시간 표시
            orderDeadLineCheck : false, //주문마감시간 체크
            deliveryString : '',        //배송일 문자열(년월일)
            getDate : '',               //배송일(일)
            sessionOrderUpdate : '',    //주문수정으로 들어 온건 true
            deliveryDateCheck : false,  //배송날짜 체크
            // answer : '',
            orderedRequests : false,    //수정으로 들어온경우 요청사항 표시
            countMinutes : 0,           //상단 마감시간 카운트다운
            // lastDate : '',              //요번달 마지막 날짜
            goToProduct : false,        //배송전날 마감시간전 수정 > 주문완료 클릭 시 마감시간 후 상품목록으로 가기 
            // cutOffDays : 2,             //주문마감일수(n일전마감)
            cutOffDate : null,          //주문마감일 (YYYYMMDD)
            cutOffDateTime: null,       // 주문마감일(시,분 포함) (YYYYMMDDHHmm)
            dlvDate : null,             //배송일
            cutOffDateDash:'',          // 주문마감일 (YYYY-MM-DD)

            epayment: null,             // epayment대상 소매점 코드:E
            ccActive: null,             // 현금수금 활성화 - t: acitve f: inactive
            vcActive: null,             // 가상계좌결제 활성화 - t: acitve f: inactive 
            ezActive: null,             // 간편결제 활성화 - t: acitve f: inactive  

            payType: '',                // 결제방식 -  가상계좌:'VC', 간편결제:'EZ', 현금수금:'CC' 
            bankCode:'',                // 가상결제 - 선택한 은행
            bankList:[],                // 은행목록
            dcAmt:0,                    // 할인금액
            pickDc:'',                  // 선택한 할인쿠폰
            dcList: null,               // 할인쿠폰 목록

            orderButtonKey:1,           // 주문하기(결제하기) 버튼 Key: click.once걸어놓은 상태에서 버튼 다시 활성화 시킬때 사용 ex) orderButtonKey++
            
            orderKey:'',                // 간편결제 주문번호
            danalRes:'',                // 간편결제 - 다날 auth결과(결제페이지로 이동하는 startUrl,...)
            paymentItemName:'',         // order테이블에 저장되는 주문상품명 (말보로 레드 외 4종)

            popEzPayError: false,       // 결제 장애 팝업
            isLoading: false,           // 로딩바
            isAllDc: false,             // 주문 수정인 경우, 결제했던 할인쿠폰 전부 띄우기 여부
            orderedPayType: null,       // 주문 수정 시, 기존 주문정보
            accountHolder: '',          //(삭제예정)다날결제 예금주명 ex)'영유통' - 백엔드에서 작업하고 있으니 삭제해도 됨.

            requestValue:'',			// 요청사항

            orderFail: null,           // 주문 실패 원인.
            
            minQty: 50,                 // 최소 주문 수량

            unitDefine: {},
            total: {},

            hasPackage: false, //패키지 포함여부 (패키지 포함일 경우 할인 노출 안함)

            checkEZPolicy: false,
            isShowEZPolicy: false,
        }   

    },
    computed: {
        finalAmt: function () {  // 최종결제금액(총주문금액-할인금액)
            return this.amount - this.dcAmt;
        },
        isEzPayOk: function () { // 간편결제가능여부
            const limitAmt = 2000000; // 200만원
            if(this.finalAmt > limitAmt) {
                return false;
            } else {
                return true;
            }
        },
    },

    watch: {
        // 간편결제 불가능한 경우, 결제수단 초기화.
        isEzPayOk: function(newVal, oldVal){
            if(newVal == false && this.payType == 'EZ') {
                this.payType = '';
            }
        },
        // 결제버튼 다시 활성화 시, 로딩바off 
        orderButtonKey: function() {
            this.isLoading = false;
        },
        // 간편결제 - 다날 결제 오류 알림
        orderFail(){
            if(this.orderFail == 'AMOUNT') {
                this.$dialog('[ERROR] 금액이 일치하지 않습니다.');
            } else {
                this.$dialog('[ERROR] 결제 실패했습니다.');
            }

        }
    },

    /*
    beforeRouteLeave(to, from, next) {
        if(to.name == 'OrderHistory' || (this.goToProduct == true && to.name == 'Product')){
            // 주문 미완료 시 알림 종료
            this.$store.commit('isOrderPage', false);
            next()
        }else if(to.name == 'Footer'){
            next()
        }else{
            next(false)
        }
    },
    */
   
    async created() {
        this.isLoading = true;

        // 제품타입 코드 조회 (단위명, 단위수량)
        this.unitDefine = await this.$root.getUnitDefine();

        //console.log('order.vue created');
        // 즉시주문여부 세팅
        this.isQuickOrder = (this.$route.query.isQuickOrder == 'true' || this.$route.query.isQuickOrder == true);

        // alert(document.referrer)
        // 간편결제 - 다날 결제 오류 발생 catch
        if(this.$route.query.RETURNFAIL){
            this.orderFail = this.$route.query.RETURNFAIL;
        } else if(this.$route.params.RETURNFAIL) {
            this.orderFail = this.$route.params.RETURNFAIL;
        }
        
        // 주문마감시간 가져오기
        await Code.getCode('C00002', 'C01')
            .then(res => {
                var deadline = res.data.refValue1.split(':')
                this.orderDeadLineDisplay = res.data.refValue1;
                this.orderDeadLine = parseInt(deadline[0]+deadline[1])
                // console.log('주문 마감시간 : ',this.orderDeadLine)
                this.sessionOrderUpdate = this.$store.state.orderUpdate
                // console.log('order 주문마감시간 ',res.data, 'this.sessionOrderUpdate :',this.sessionOrderUpdate);
            });

        var limit = {'limit': 'true'}

        //주문현황에 주문완료 체크
        await Order.getOrderHistory(limit)
        .then((response) => {
            console.log('order 주문현황 최신1건 ',response.data[0])
            console.log('order statusCode ',response.data[0].statusCode) 
            console.log('order statusCode 비교',response.data[0].statusCode == 'Waiting') 
            console.log('if문',response.data != null && (response.data[0].statusCode == 'Ordered' || response.data[0].statusCode == 'Waiting')) 
//             const s1 = 'café';
            // const s2 = 'café';
            // console.log(s1.normalize() === s2.normalize()); // true
            // if(response.data != null && (response.data[0].statusCode == 'Ordered' || response.data[0].statusCode == 'Waiting')){
            if(response.data != null && response.data[0].statusCode == 'Ordered'){
                console.log('getOrderHistory의 if문 안에 들어왔다.')
                this.orderedList = response.data[0]
                this.deliveryString = this.orderedList.deliveryDt

                // 주문 수정인경우(현금수금)
                if(this.sessionOrderUpdate){
                    this.isAllDc = true;
                    this.orderedPayType =  response.data[0].payType;
                    console.log(' this.orderedPayType :   ', this.orderedPayType )
                }
            }
            
        })
        .catch((e) => {
            console.log("error : ", e);
        });

        // 주문 미완료 시 알림 시작
        this.$store.commit('isOrderPage', true);
        //window.addEventListener('beforeunload', this.unLoadEvent);

        //로그인한 고객정보
        await Customer.getDetail()
        .then((response) => {
            this.customerVO = response.data
            // console.log(this.customerVO)
            this.name = this.customerVO.Name
            this.address = this.customerVO.Address__c
            this.addressZipCode = this.customerVO.AddressZipcode__c
            this.epayment = this.$store.state.customer.epayment; //E: epayment가능
            // this.customerLevel2 = this.$store.state.customer.level2;
            
            console.log('epayment:'+this.$store.state.customer.epayment)
            this.payType = this.epayment == 'E'?'':'CC';
            console.log('this.orderedPayType: '+this.orderedPayType)
            console.log("this.orderedPayType == 'CC' || this.orderedPayType == '':  "+(this.orderedPayType == 'CC' || this.orderedPayType == ''))
            if(this.orderedPayType == 'CC' || this.orderedPayType == '') {
                this.vcActive = 'f';
                this.ezActive = 'f';
                this.ccActive = 't';
                this.payType = 'CC';
            } else {
                this.ccActive = this.customerVO.PAY_METHOD_CC;
                this.vcActive = this.customerVO.PAY_METHOD_VC;
                this.ezActive = this.customerVO.PAY_METHOD_EZ;
            }
            // 결제 수정인 경우, 결제수단 변경 제어.(가상->가상,간편 / 현금->현금)
            // if(this.sessionOrderUpdate == true && (this.orderedPayType == 'VC' || this.orderedPayType == 'EZ')) {
            //     this.ccActive = 'f';
            // } else if(this.sessionOrderUpdate == true && (this.orderedPayType == 'CC' || this.orderedPayType == '')){
            //     this.epayment == ''
            //     this.vcActive = 'f';
            //     this.ezActive = 'f';
            // }
        })
        .catch((e) => {
        console.log("error : ", e);
        });

        // 배송계획 조회
        await Customer.getPlan()
        .then((response) => {
            let plan = response.data;
          
            //배송요일 
            var DeliveryDay = '';
            this.deliveryNum = '';

            this.deliveryDate2 = plan.cutOffDayKor.substring(0,1);
            this.EdeliveryDate = plan.dlvDate;

            if(this.orderedList != null && this.sessionOrderUpdate == true){
                this.orderedRequests = true
                if(this.orderedList.requests) {
                    this.requestValue = (this.orderedList.requests+" ").slice(0, -1) ;
                    // this.request = this.orderedList.requests;
                    // this.request = $('#request').val(this.orderedList.requests)
                }
                // console.log('주문완료건 있고, 수정일때 수정요청사항',this.orderedRequests)
            }
            // this.orderDeadLine = 1200

            // console.log(this.orderDeadLine)
            // console.log('마감시간 : ',this.orderDeadLine)

            var cutOffTimeSplit = plan.cutOffTime.split(':');
            var deadLineMinute = cutOffTimeSplit[1];
            var deadLineHour = cutOffTimeSplit[0];
            var orderDeadLineTime;
            
            if(deadLineMinute == '00'){
                orderDeadLineTime = parseInt(deadLineHour-1 +"30")
                this.countMinutes = 60
                // console.log('00분일때 orderDeadLineTime',orderDeadLineTime)
            }else if(deadLineMinute == '30'){
                orderDeadLineTime = parseInt(deadLineHour+"00")
                this.countMinutes = 30
                // console.log('00분 아닐때 orderDeadLineTime',orderDeadLineTime)
            }
            this.cutOffDateDash = plan.cutOffDate;
            this.cutOffDate = parseInt(plan.cutOffDate.replace(/-/g, ''));
            this.cutOffDateTime = parseInt((plan.cutOffDate+plan.cutOffTime).replace(/[-:]/gi, ''));
            // console.log('주문마감일(시간)', plan.dlvDate+plan.cutOffTime);
            // console.log('주문마감일(시간)', this.cutOffDateTime);
            this.dlvDate = parseInt(plan.dlvDate.replace(/-/g, ''));
            //console.log('주문마감일', this.cutOffDate);
            //console.log('배송예정일', this.dlvDate);

            var nowDateNum = parseInt(moment(new Date()).format('YYYYMMDD'));
            var nowTime = parseInt(moment(new Date()).format('HHmm'));
            //console.log('현재시간',  nowTime);

            // 주문마감시간 표시
            //console.log('nowDateNum : ',nowDateNum, '배송cutoff 띄우는 날짜 : ',parseInt(moment(this.getDate).subtract(1, 'd').format('DD')), ', this.orderDeadLine : ',this.orderDeadLine, ',nowTime : ',nowTime);
            if (nowDateNum == this.cutOffDate && this.orderDeadLine != '' && nowTime >= orderDeadLineTime && nowTime <= this.orderDeadLine){
                this.deadLineOrder.display='';
                this.countDownTimer()
            }

            
        })
        .catch((e) => {
        console.log("error : ", e);
        });

        //

        if (this.isQuickOrder) {
            await Cart.getTempCart()
                .then(({data}) => {
                    this.callbackGetCart(data);
                })
                .catch((e) => {
                    console.log("error : ", e);
                });
        } else {
            var params = {ischecked:'true'}
            await Cart.getCart(params)
            .then(({data}) => {
                    this.callbackGetCart(data);
                })
                .catch((e) => {
                    console.log("error : ", e);
                });
        }

        // 고객 최소최대수량 가져오기
        await Code.getCode('C00001', this.$store.state.customer.grade)
            .then(res => {
                //codeDetail 최소값 없는경우 50으로 세팅
                /*if('46'==this.$store.state.customer.level3) {
                  this.minQty = 70
                }else */if(res.data.refValue1==null){
                    this.minQty = 50
                }else{
                    this.minQty = parseInt(res.data.refValue1)
                }
                // this.max = parseInt(res.data.refValue2)
            });

        // 은행목록 로드
        Payment.getBankList({isVc:'Y'}).then((res) => {
            this.bankList = res.data;
        })

        // 할인쿠폰 목록 조회
        var dcParam = {}
        console.log('this.sessionOrderUpdate'+this.sessionOrderUpdate)
        console.log('this.isAllDc'+this.isAllDc)
        console.log('this.sessionOrderUpdate == true && this.isAllDc == true'+this.sessionOrderUpdate == true && this.isAllDc == true)
                
        if(this.sessionOrderUpdate == true && this.isAllDc == true){ // 수정하는 경우 기존 할인코드만 로드
            console.log('수정하는 경우 기존 할인코드만 로드'+this.isAllDc)
            dcParam = {
                codeDetail: this.orderedList.dcCode
            }
        } else {
            dcParam = {}
        }
        console.log('dcParam'+JSON.stringify(dcParam));
        
        await Discount.getDcList(dcParam)
        .then((response) => {
            this.dcList = response.data;
            if(this.dcList.length == 1 && this.dcList[0].amount > 0 && this.hasPackage == false) {
                this.pickDc = this.dcList[0].code;
                this.dcAmt = this.dcList[0].amount;
            } else if(this.dcList.length == 0) {
                this.dcList = null;
            }
        })
        .catch((e) => {
        console.log("error : ", e);
        });
    

        this.isLoading = false;
        
        



    },
    mounted() {
        //console.log('order.vue mounted');
    },

    methods : {
        async callbackGetCart(data) {
            this.orderLists = data;
            // console.log(this.orderLists)

            for(var i in this.orderLists){
                var totalAmount = this.orderLists[i].quantity * this.orderLists[i].price;
                this.orderLists[i].amount = totalAmount;
                this.amount += totalAmount;
                this.totalQuantity += this.orderLists[i].quantity
                
                if (this.orderLists[i].isPackage == true) {
                    this.hasPackage = true;
                }
                
                $('.amount').text((this.amount).toLocaleString('ko-KR')+'원');
                //$('.quantity').text((this.totalQuantity).toLocaleString('ko-KR')+'갑');
            }

            this.total = await this.$root.totalQuantityAmount(this.orderLists);
        },
        fnPickBank(code){
            this.bankCode = code;
        },

        fnPickDc(item){
            this.pickDc = item.codeDetail;
            this.dcAmt = item.amount;
        },

        comma(number){
            return (parseInt(number)).toLocaleString('ko-KR');
        },

        // 실시간 남은 주문 마감시간 
        countDownTimer() {

            var now = new Date();	// 현재 날짜 및 시간
            var minutes = now.getMinutes();	// 분
            
            this.countDown = this.countMinutes - minutes
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    // console.log('카운트 : ',this.countDown)
                    if(this.countDown == 0 || this.countDown == 60){
                        this.deadLineOrder.display='none'
                    }
                    this.countDownTimer()
                }, 60000)
            }
        },
        deliveryCheck(){ // 배송날짜
            var nowTime = parseInt(moment(new Date()).format('HHmm'));
            // this.orderDeadLine = 1200
            // nowTime = 1430
            // nowDate = 9
            var nowDateNum = parseInt(moment(new Date()).format('YYYYMMDD'));
            //console.log('nowDateNum = ', nowDateNum);
            //console.log('dlvDateNum- = ', parseInt(moment(this.getDate).subtract(3, 'd').format('YYYYMMDD')));

            if((nowDateNum == this.cutOffDate && nowTime >= this.orderDeadLine) || (nowDateNum > this.cutOffDate && nowDateNum <= this.dlvDate)){
                  
                this.orderDeadLineCheck = true
                //this.EdeliveryDate = moment(this.getDate).add(7,'d').format('YYYY-MM-DD')
                // console.log('월요일 배송일+7일 : ',this.EdeliveryDate,', this.deliveryString : ',this.deliveryString)

                //다음주 배송날짜 , 주문완료 배송날짜 비교
                if(this.EdeliveryDate == this.deliveryString){
                    this.deliveryDateCheck = true
                    // console.log(this.EdeliveryDate,this.deliveryString,this.deliveryDateCheck)
                }

            }

            /* 위 코드로 요약
            if(this.deliveryNum == 1){
                // console.log('nowDate : ',nowDate, '배송전날 날짜 : ',parseInt(moment(this.getDate).subtract(3, 'd').format('DD')), ', this.orderDeadLine : ',this.orderDeadLine, ',nowTime : ',nowTime)
                // console.log(nowDate == parseInt(moment(this.getDate).subtract(3, 'd').format('DD')) , nowTime >= this.orderDeadLine , nowDate == parseInt(moment(this.getDate).format('DD')))
                // console.log('nowDate : ',nowDate,', -3day : ',parseInt(moment(this.getDate).subtract(3, 'd').format('DD')))
                // if(nowDate >= parseInt(moment(this.getDate).subtract(3, 'd').format('DD')) && nowDate <= parseInt(moment(this.getDate).format('DD')) && nowTime >= this.orderDeadLine){
                //if((nowDate == parseInt(moment(this.getDate).subtract(3, 'd').format('DD')) && nowTime >= this.orderDeadLine) || (nowDate >= parseInt(moment(this.getDate).subtract(2, 'd').format('DD')) && nowDate <= parseInt(moment(this.getDate).format('DD')))){
                if((nowDateNum == parseInt(moment(this.getDate).subtract(3, 'd').format('YYYYMMDD')) && nowTime >= this.orderDeadLine) || (nowDateNum >= parseInt(moment(this.getDate).subtract(2, 'd').format('YYYYMMDD')) && nowDateNum <= parseInt(moment(this.getDate).format('YYYYMMDD')))){
                  
                    this.orderDeadLineCheck = true
                    this.EdeliveryDate = moment(this.getDate).add(7,'d').format('YYYY-MM-DD')
                    // console.log('월요일 배송일+7일 : ',this.EdeliveryDate,', this.deliveryString : ',this.deliveryString)

                    //다음주 배송날짜 , 주문완료 배송날짜 비교
                    if(this.EdeliveryDate == this.deliveryString){
                        this.deliveryDateCheck = true
                        // console.log(this.EdeliveryDate,this.deliveryString,this.deliveryDateCheck)
                    }

                }
            }else{
                if(nowDate == parseInt(moment(this.getDate).subtract(1, 'd').format('DD')) && nowTime >= this.orderDeadLine || nowDate == parseInt(moment(this.getDate).format('DD'))){
                    // console.log('배송 전날 마감시간 지났음')
                    this.orderDeadLineCheck = true
                    this.EdeliveryDate = moment(this.getDate).add(7,'d').format('YYYY-MM-DD')
                    // console.log('배송일+7일 : ',this.EdeliveryDate,', this.deliveryString : ',this.deliveryString)

                    //다음주 배송날짜 , 주문완료 배송날짜 비교
                    if(this.EdeliveryDate == this.deliveryString){
                        this.deliveryDateCheck = true
                        // console.log(this.orderedList,this.orderedList.statusCode,this.orderDeadLineCheck,this.deliveryDateCheck)
                    }

                }
            }
            */
        },
        setItemName() {
            let orderLists = this.orderLists;
            if (orderLists) {
                if (orderLists.length > 1) {
                    this.paymentItemName = orderLists[0].productNm + ' 외 ' + (orderLists.length - 1) + '종';
                } else if (orderLists.length == 1) {
                    this.paymentItemName = orderLists[0].productNm;
                }
            }
        },
        async getOrderKey(orderLists){
            await Payment.getOrderKey()
            .then(async (response) => {
                console.log('orderKey', response.data)
                if(response.data){
                    if(this.sessionOrderUpdate == true){
                        this.orderKey = this.orderedList.eorderNumber
                    } else {
                        this.orderKey = response.data;
                    }
                    this.insertTempOrder(orderLists); // 임시주문정보 저장
                    let data = {
                        ORDERID: this.orderKey,
                        GUID: this.customerVO.Id,
                        // AMOUNT: '1',
                        AMOUNT: this.finalAmt,
                        ITEMNAME: encodeURIComponent(this.paymentItemName),
                        USERNAME: this.customerVO.HostName__c,
                        USERID: this.customerVO.CustomerCode__c,
                        // orderTempVO: orderLists
                        isQuickOrder: this.isQuickOrder
                    }
                    await Payment.callDanalApi(data)
                    .then((response) => {
                        if(response.data.STARTURL){
                            this.danalRes = response.data;

                            console.log(this.danalRes);
                            setTimeout(function() {
                                document.forms.refundForm.submit();
                            }, 200);
                        
                        } else {
                            this.orderButtonKey++;
                            throw 'STARTURL 없음'
                        }

                        if(response.data > 0 ){
                            this.$router.push({name:'OrderHistory',params: { orderMstSeq: this.result }})
                        }
                        
                    })
                    .catch((e) => {
                        //console.log("error : ", e);
                        if (e.response.data?.message == 'Duplicated') {
                            this.duplicateHandler(e.response.data);
                        } else {
                            this.orderButtonKey++;
                            this.popEzPayError = true;
                        }
                        return;
                    });
                }
            })
            .catch((e) => {
                console.log("error : ", e);
                this.orderButtonKey++;
                this.popEzPayError = true;
                return;
            });
            this.isLoading = false;
        },


        insertTempOrder(orderLists){
           let submit = {
               'orderNumber': this.orderKey,
                list : orderLists,
                tradeProgramCode: this.pickDc,
                'userId': this.customerVO.Id,
                'deliveryDt' : this.EdeliveryDate,
                'address':this.customerVO.Address__c,
                'request' : this.requestValue,
                'itemName' : encodeURIComponent(this.paymentItemName),
                'customerCode': this.customerVO.CustomerCode__c
            }

            const json = JSON.stringify(submit);
            // console.log('insertOrder submit : ',json)
            
            //임시주문 insert
            Order.insertTempOrder(json)
            .then((response) => {
                // 주문 완료 시 알림 종료
                this.$store.commit('isOrderPage', false);

                this.result = response.data;
                console.log('임시주문 insert result : ',this.result)

            })
            .catch((e) => {
                console.log("error : ", e);
                this.orderButtonKey++;
                this.popEzPayError = true;
            });
        },
        async callDanalApi(){
            
        },
        
        // 주문완료하기 버튼 클릭
        order : function(orderLists){
            console.log('****** syh 1 ****',this.orderLists)

            var submit = {};  
            

            this.deliveryCheck();
            this.setItemName();
           
            if (!this.EdeliveryDate) {
                    console.log('****** syh 2 ***EdeliveryDate*',this.EdeliveryDate)
                    this.$dialog('배송예정일이 존재하지 않아 주문을 완료 할 수 없습니다.');
                return;
            } else {

                //기존주문이 수정 / 마감전 , 상세 delete > 상세 insert
                // console.log('order 배송일:',this.EdeliveryDate,', this.sessionOrderUpdate',this.sessionOrderUpdate,', this.orderDeadLineCheck',this.orderDeadLineCheck,', this.deliveryDateCheck',this.deliveryDateCheck)
                console.log('****** syh 3 ***EdeliveryDate*')
                
                if(this.orderedList != null && this.sessionOrderUpdate == true && this.orderDeadLineCheck == false){
                console.log('****** syh 4 ***EdeliveryDate*')
                    if (this.payType == 'EZ') {
                        this.getOrderKey(orderLists);
                    } else {
                    
                        //  주문LIst있고, orderUpdate고 마감전이고.
                        // console.log('기존 주문수정')
                        this.result = '';
                        submit = {};  
                        submit = { //orderMst > eOrderNumber
                            'orderQty' : this.totalQuantity,
                            'orderMst' : this.orderedList.eorderNumber,
                            'statusCode': 'delete',
                            'address':this.customerVO.Address__c,
                            'requests' : this.requestValue,
                            'deliveryDt' : this.EdeliveryDate, //EdeliveryDateTime
                            list : this.orderLists,
                            paymentItemName: this.paymentItemName,
                            payType : this.payType,
                            bankVO:{
                                    vcBankCode: this.bankCode
                                },
                                dcVO: {
                                    codeDetail: this.pickDc,
                                    amount: this.dcAmt,
                                    customerId: this.customerVO.Id
                                },
                            paymentExpireDate: this.cutOffDateTime,
                            accountHolder: this.accountHolder
                        }

                        const json = JSON.stringify(submit);
                        // console.log('orderDelete submit : ',json)
                        
                        // 주문 update
                        console.log('****** syh 5 ***json*',json)

                        Order.updateOrder(json)
                        .then((response) => {
                        console.log('****** syh 6 ***response.data*',response.data)

                            // 주문 미완료 시 알림 종료
                            this.$store.commit('isOrderPage', false);

                            this.result = response.data;
                            // console.log('기존주문수정 update result :',this.result)
                            
                            //장바구니 delete
                            if(this.result != 0){
                                console.log('****** syh 7 ***')
                            
                                var params = [];
                                var a = {}

                                for(var index in this.orderLists){
                                    a = {'productCode':this.orderLists[index].productCode}
                                    params.push(a)
                                }
                                // console.log('기존주문수정 후 삭제 될 장바구니 리스트 : ',params)
                                console.log('****** syh 8 **params*'+params)

                                Cart.deleteCart(params)
                                .then((response) => {
                                            console.log('****** syh 9 ***response.data*',response.data)


                                    // console.log('장바구니 삭제후 result :',response.data)
                                    // console.log('주문완료 페이지 orderMstSeq : ',this.result)
                                    if(response.data > 0 ){
                                        this.$store.commit('orderUpdate', null);
                                        this.$router.replace({name:'OrderHistory',params: { orderMstSeq: this.result }})
                                    }

                                })
                                .catch((e) => {
                                console.log("error : ", e);
                                });

                            }else{
                                console.log("insertOrder Error!")
                            }
                        })
                        .catch((e) => {
                            console.log("error : ", e);
                            this.orderButtonKey++;
                            if (e.response.data.message=='No bulk accounts left') {
                                this.$dialog('현재 해당 은행은 이용이 불가합니다.<br>다른 은행을 선택해 주시기 바랍니다.');

                                return;
                            } else if (e.response.data.message=='Account mapping error') {
                                this.$dialog('서버 문제로 가상계좌 결제가 불가합니다.<br>다른 결제 수단을 선택해 주시기 바랍니다.');
                                return;
                            } else if (e.response.data.message) {
                                // alert(e.response.data.message);
                                console.log('error: '+e.response.data.message)
                                this.$dialog('결제 서비스 장애 안내<div class="paragraph" style="text-align:left"><br>현재 결제 시 승인 오류가 간헐적으로 발생하고 있습니다.<br>빠른 시간 내 조치하도록하겠습니다.<br>서비스 이용에 불편을 드려 죄송합니다.</div>');

                            }
                        });
                    }
                //기존주문이 수정 / 마감후 , 경고팝업 > 장바구니 delete > 상품리스트
                }else if(this.orderedList != null && this.sessionOrderUpdate == true && this.orderDeadLineCheck == true && this.deliveryDateCheck == false){
                                        console.log('****** syh 10 *****')
                    this.$store.commit('orderUpdate', null);
                    this.goToProduct = true //상품목록으로 가기 true
                    this.message = '금주 배송건은 이미 마감 되었습니다. 기존 주문건만 접수되었으니 새로 주문해주세요.'
                    this.popUpDeadLine.display = ''
                
                //마감 후 같은 배송날 있음, orderUpdate
                }else if(this.orderedList != null && this.orderDeadLineCheck == true  && this.deliveryDateCheck == true){
                                        console.log('****** syh 11 *****')
                    // console.log('마감 후 같은 배송날 있음, orderUpdate')
                    this.result = '';
                    submit = {};  
                    if (this.payType == 'EZ') {
                        this.getOrderKey(orderLists);
                    } else {
                        submit = { //orderMst > eOrderNumber
                            'orderQty' : this.totalQuantity,
                            'orderMst' : this.orderedList.eorderNumber,
                            'statusCode': 'delete',
                            'address':this.customerVO.Address__c,
                            'requests' : this.requestValue,
                            'deliveryDt' : this.EdeliveryDate, //EdeliveryDateTime
                            list : this.orderLists,
                            paymentItemName: this.paymentItemName,
                            payType : this.payType,
                            bankVO:{
                                    vcBankCode: this.bankCode
                                },
                                dcVO: {
                                    codeDetail: this.pickDc,
                                    amount: this.dcAmt,
                                    customerId: this.customerVO.Id
                                },
                            paymentExpireDate: this.cutOffDateTime,
                            accountHolder: this.accountHolder
                        }

                        const json = JSON.stringify(submit);
                        // console.log('orderDelete submit : ',json)
                            console.log('****** syh 12 ***json*',json)

                        
                        // 주문 update
                        Order.updateOrder(json)
                        .then((response) => {
                            console.log('****** syh 13 ***response.data*',response.data)

                            // 주문 미완료 시 알림 종료
                            this.$store.commit('isOrderPage', false);

                            this.result = response.data;
                            // console.log('마감후 배송 같은날 update result :',this.result)
                            
                            //장바구니 delete
                            if(this.result != 0){
                            
                                var params = [];
                                var a = {}

                                for(var index in this.orderLists){
                                    a = {'productCode':this.orderLists[index].productCode}
                                    params.push(a)
                                }
                                // console.log('마감후 배송 같은날 삭제 될 장바구니 리스트 : ',params)
                                console.log('****** syh 14 **params*'+params)

                                Cart.deleteCart(params)
                                .then((response) => {
                                console.log('****** syh 15 ***response.data*',response.data)

                                    // console.log('마감후 배송 같은날 장바구니 삭제후 result :',response.data)
                                    // console.log('마감후 배송 같은날 주문완료 페이지 orderMstSeq : ',this.result)
                                    if(response.data > 0 ){
                                        this.$store.commit('orderUpdate', null);
                                        this.$router.push({name:'OrderHistory',params: { orderMstSeq: this.result }})
                                    }

                                })
                                .catch((e) => {
                                console.log("error : ", e);
                                });

                            }else{
                                console.log("insertOrder Error!")
                            }
                        })
                        .catch((e) => {
                            console.log("error : ", e);
                            this.orderButtonKey++;
                            if (e.response.data.message=='No bulk accounts left') {
                                this.$dialog('현재 해당 은행은 이용이 불가합니다.<br>다른 은행을 선택해 주시기 바랍니다.');

                                return;
                            } else if (e.response.data.message=='Account mapping error') {
                                this.$dialog('서버 문제로 가상계좌 결제가 불가합니다.<br>다른 결제 수단을 선택해 주시기 바랍니다.');
                                return;
                            } else if (e.response.data.message) {
                                // alert(e.response.data.message);
                                console.log('error: '+e.response.data.message)
                                this.$dialog('결제 서비스 장애 안내<div class="paragraph" style="text-align:left"><br>현재 결제 시 승인 오류가 간헐적으로 발생하고 있습니다.<br>빠른 시간 내 조치하도록하겠습니다.<br>서비스 이용에 불편을 드려 죄송합니다.</div>');

                            }
                        });
                    }
                //신규주문
                } else {

                    if (this.payType == 'EZ') {
                        this.getOrderKey(orderLists);
                    } else {
                        submit = {
                            'orderQty' : this.totalQuantity,
                            'address':this.customerVO.Address__c,
                            'requests' : this.requestValue,
                            'deliveryDt' : this.EdeliveryDate,
                            list : orderLists,
                            payType : this.payType,
                            orderKey: this.orderKey,
                            // 간편결제시만 orderKey보냄.
                            bankVO:{
                                vcBankCode: this.bankCode
                            },
                            dcVO: {
                                codeDetail: this.pickDc,
                                amount: this.dcAmt,
                                customerId: this.customerVO.Id
                            },
                            paymentExpireDate: this.cutOffDateTime,
                            accountHolder: this.accountHolder
                        }

                        const json = JSON.stringify(submit);
                        // console.log('insertOrder submit : ',json)
                        
                        //신규주문 insert
                        Order.insertOrder(json)
                        .then((response) => {
                            // 주문 미완료 시 알림 종료
                            this.$store.commit('isOrderPage', false);

                            this.result = response.data;
                            // console.log('신규주문 insert result : ',this.result)

                            //장바구니 delete
                            if(this.isQuickOrder == false){
                                var params = [];
                                var a = {}

                                for(var index in orderLists){
                                    a = {'productCode':orderLists[index].productCode}
                                    params.push(a)
                                }
                                // console.log('신규주문 삭제 될 장바구니 리스트 : ',params)

                                Cart.deleteCart(params)
                                .then((response) => {
                                    // console.log('신규주문 장바구니 삭제후 result :',response.data)
                                    // console.log('신규주문 주문완료 페이지 orderMstSeq : ',this.result)
                                    if(response.data > 0 ){
                                        this.$store.commit('orderUpdate', null);
                                        this.$router.push({name:'OrderHistory',params: { orderMstSeq: this.result }})
                                    }
                                })
                                .catch((e) => {
                                    console.log("error : ", e);
                                    this.orderButtonKey++;
                                });
                            } else {
                                this.$store.commit('orderUpdate', null);
                                this.$router.push({name:'OrderHistory',params: { orderMstSeq: this.result }})
                            }
                        })
                        .catch((e) => {
                            console.log("error : ", e);
                            this.orderButtonKey++;
                            if (e.response.data.message=='No bulk accounts left') {
                                this.$dialog('현재 해당 은행은 이용이 불가합니다.<br>다른 은행을 선택해 주시기 바랍니다.');

                                return;
                            } else if (e.response.data.message=='Account mapping error') {
                                this.$dialog('서버 문제로 가상계좌 결제가 불가합니다.<br>다른 결제 수단을 선택해 주시기 바랍니다.');
                                return;
                            } else if (e.response.data.message=='Duplicated') {
                                this.duplicateHandler(e.response.data);
                                return;
                            } else if (e.response.data.message) {
                                // alert(e.response.data.message);
                                console.log('error: '+e.response.data.message)
                                this.$dialog('결제 서비스 장애 안내<div class="paragraph" style="text-align:left"><br>현재 결제 시 승인 오류가 간헐적으로 발생하고 있습니다.<br>빠른 시간 내 조치하도록하겠습니다.<br>서비스 이용에 불편을 드려 죄송합니다.</div>');

                            }
                        });

                    }
                    
                }
            }    
            // this.isLoading = false;
            
        },// //order()

        duplicateHandler(errorData){
            let errorInfo = errorData.info;
            let cutOffDateTime = errorInfo[1];
            let cutOffSplit = cutOffDateTime.split(' ');

            this.$dialog({
                type: 'alert',
                //message: '이미 등록된 주문건이 있습니다.<br>취소 또는 환불요청 후 다시 주문해주세요.',
                message: '기존 등록된 주문이 있습니다.<br>(신규 주문은 ' + cutOffSplit[0] + ' ' + this.$root.timeKor(cutOffSplit[1]) + ' 이후 가능합니다.)',
                callback: () => {
                    // 주문 미완료 알림 종료
                    this.$store.commit('isOrderPage', false);
                    // 주문현황으로 이동
                    this.$router.push({ name: 'OrderHistory' });
                }
            });
        },

        //장바구니 수정으로 들어와서, 마감 후 주문할 경우 > 경고팝업 > 장바구니 삭제 > 상품리스트
        goProductList :function(){
            var params = [];
            var a = {}

            for(var index in this.orderLists){
                a = {'productCode':this.orderLists[index].productCode}
                params.push(a)
            }

            Cart.deleteCart(params)
            .then((response) => {
                var result = response.data;
                if(result > 0){
                    this.$store.commit('orderUpdate', null);
                    this.$router.push({name:'Product'})
                }

            })
            .catch((e) => {
            console.log("error : ", e);
            });
            
        },


        close : function(){
            // this.popUpGoOut.display = 'none'
            this.popUpDeadLine.display = 'none';
            this.popEzPayError = false;
        },

        unLoadEvent: function (event) {
            if (this.canLeaveSite) return;

            event.preventDefault();
            event.returnValue = '';
        },

        checkValid(){ // 유효성 검사
            this.isLoading = true;
            
            console.log('this.totalQuantity=',this.totalQuantity);
            console.log('this.minQty=',this.minQty);

            if (this.total.quantity < this.minQty) {
                this.$dialog('최소 수량 ' + this.minQty + ' 이상 주문 가능합니다.');
                this.orderButtonKey++;
            } else if (!this.EdeliveryDate) {
                this.$dialog('배송예정일이 존재하지 않아 주문을 완료 할 수 없습니다.');
                this.orderButtonKey++;
            } else if(this.dcList && this.dcList.length > 1 && (this.pickDc == null || this.pickDc == '')) {
                this.orderButtonKey++;
                this.$dialog({
                    type: 'alert',
                    message: '할인 쿠폰을 선택해주세요.',
                    callback: () => {
                        //console.log('##callback', this.$refs.divDiscount);
                        $(this.$refs.divDiscount).attr("tabindex", -1).focus();
                    }
                });
            } else if(this.payType == '' || this.payType == null) {
                this.orderButtonKey++;
                this.$dialog({
                    type: 'alert',
                    message: '결제 수단을 선택해주세요.',
                    callback: () => {
                        //console.log('##callback', this.$refs.divPaymentMethod);
                        $(this.$refs.divPaymentMethod).attr("tabindex", -1).focus();
                    }
                });
            } else if(this.payType == 'VC' && (this.bankCode == null || this.bankCode == '')) {
                this.orderButtonKey++;
                this.$dialog('결제하실 가상계좌의 은행을 선택해주세요.');
            } else if (this.payType == 'EZ' && this.checkEZPolicy == false) {
                this.orderButtonKey++;
                this.$dialog('다날 간편결제 약관에 동의해주세요.');
            } else if(this.finalAmt <= 0) {
                this.orderButtonKey++;
                this.$dialog('1원 미만의 금액은 결제할 수 없습니다. 장바구니에서 상품을 추가해주세요.');
            } else {
                this.orderedRequests?this.requestValue = this.orderedList.requests :'';
                this.order(this.orderLists)
            }
        },
      
        showEZPolicy() {
            // 체크상태 유지하며 약관만 보여준다
            this.checkEZPolicy = !this.checkEZPolicy;

            this.isShowEZPolicy = true;
        }
    },

    beforeDestroy(){
        window.removeEventListener('beforeunload', this.unLoadEvent);
    }
};

</script>

<style lang="css">
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
 
 

</style>