<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">
            <div class="flexible">
                <div class="sec">
                    <table class="table_info">
                        <tbody>
                            <tr>
                                <th>소매점명</th>
                                <td>{{customer.Name}}</td>
                            </tr>
                            <tr>
                                <th>소매점코드</th>
                                <td>{{customer.CustomerCode__c}}</td>
                            </tr>
                            <tr>
                                <th>소매점주명</th>
                                <td>{{customer.HostName__c}}</td>
                            </tr>
                            <tr>
                                <th>전화번호</th>
                                <td>{{customer.MainPhoneNo__c}}</td>
                            </tr>
                            <tr>
                                <th>배송요일</th>
                                <td>{{$root.dayKor(customer.DeliveryDay__c)}}</td>
                            </tr>
                            <tr class="add">
                                <th>주소</th>
                                <td>
                                    {{customer.Address__c}}
                                </td>
                            </tr>
                            <tr>
                                <th>사업자번호</th>
                                <td>{{customer.BusinessRegiNo__c}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="lg_service_num">
                    <dl>
                        <dt>지점 번호</dt>
                        <dd>{{this.$store.state.customer.csTelNo}}</dd>
                    </dl>
                    <div class="button_area">
                        <button type="button" class="btn_call" @click="callCenter">전화걸기</button>
                    </div>
                </div>
            </div>
             <!-- <div class="top_option memberinfo">
                <div class="button_wrap">
                    <div><button type="button" class="btn_blk memberbold" @click="popPolicy()">개인정보 처리방침</button></div>
                </div>
            </div> -->
            <div class="total_button">
                <a class="btn_blk bigger" @click="logout">로그아웃</a>
            </div>

        </div>
        <!-- //container -->

        
    </div>
</template>

<script>
import Customer from '@/api/Customer'
import Code from '@/api/Code'

export default {
    data() {
        return {
            customer: ''
        }
    },
    created() {
        Customer.getDetail()
            .then(res => {
                //await
                this.customer = res.data;
            });
    },
    
    methods: {
        callCenter: function() {
            window.location.href = "tel:" + this.$store.state.customer.csTelNo;
        },
        logout() {
            this.$store.commit('logout');
        },
        // popPolicy() {
        //     this.$router.push({name:'Footer',params: { url: 'Member' }})
        // }
    }

};

</script>

<style lang="scss">
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
 

</style>