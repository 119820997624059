<template>
    <!-- popup -->
    <div class="layer_view_area" v-if="detail">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>공지사항</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="goBack(detail.viewCnt, detail.contentsMstSeq)"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->

        <div class="container">
            <!-- 공지사항 보기 -->
            <div class="list view">
    
                <div class="block_wrap">
                    <div class="title">{{detail.subject}}</div>
                    <dl class="txt_subject">
                        <dt>게시일 :</dt>
                        <dd>{{detail.registDttm}}</dd>
                    </dl>
                </div>
    
                <div class="board_wrap">
                    
                    <div v-if = "detail.filePath != null">
                        <div v-if = "detail.extension === 'avi' || detail.extension === 'wmv' || detail.extension === 'asf'
                            || detail.extension === 'flv' || detail.extension === 'mov' || detail.extension === 'mpeg'
                            || detail.extension === 'mpeg1' || detail.extension === 'mpeg2' || detail.extension === 'mpeg4'
                            || detail.extension === 'mp4'">
                            <p><video controls :src="detail.filePath"></video></p>
                        </div>
                        <div v-else>
                            <p><img :src=detail.filePath alt="공지사항 관련 이미지"></p>
                        </div>
                    </div>
                    <br />
                    <p v-html="detail.contents"></p>
                </div>
    
                <div class="board_button">
                    <a href="#" class="btn_blk" @click="goBack(detail.viewCnt, detail.contentsMstSeq)">목록</a>
                </div>
    
            </div>
            <!-- //공지사항 보기 -->
        </div>
    </div>
</template>

<script>
import Contents from '@/api/Contents'
import $ from 'jquery';

export default {
    props: ['id'],
    data() {
        return {
            detail: null
        };
    },
    watch: {
        id: function(newVal, oldVal) {
            this.getDetail(newVal);
        }
    },
    created() {
        this.routeName = this.$route.name;
        this.getDetail(this.id);
    }, 
    methods: {
        getDetail(id) {
            if (id) {
                Contents.getContentsDetail(id)
                .then(res => {
                    this.detail = res.data;
                });
            }
        },
        goBack(viewCnt, viewSeq) {
            //this.$router.push({name: 'Notice', params: {viewCnt: viewCnt, viewSeq: viewSeq}});

            viewCnt = viewCnt+1
            if(this.routeName == 'NoticeDetail'){
                //var URL = $(location).attr('protocol')+"//"+$(location).attr('host')+"/notice"
                //$(location).attr('href',URL);
                //this.$router.push({name: 'Notice', params: {viewCnt: viewCnt, viewSeq: viewSeq}});
                this.$router.go(-1);
            }else if(this.routeName == 'Notice'){
                this.detail = null;
                this.$router.push({name: 'Notice', params: {viewCnt: viewCnt, viewSeq: viewSeq}});
            }
        }
    },
};
</script>

<style >
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
</style>
