import Send from '../utils/Send.js'

export default {
    getCodes(codeId) {
        return Send({
            url: '/code',
            method: 'get',
            params: {
                codeId: codeId
            }
        })
    },

    getCategory(data) {
        return Send({
            url: '/code/category',
            method: 'get',
            params : data,
        })
    },

    getCode(codeId, code) {
        return Send({
            url: '/code/' + code,
            method: 'get',
            params: {
                codeId: codeId
            }
        })
    }    
}