<template>
<div>
    <div class="copr_info">
        <ul class="info_policy">
            <li>
                <a class="abold" @click="popPolicy()">개인정보 처리방침 (한국필립모리스(주))</a>
            </li>
            <li>
                <a class="abold" @click="popPolicy3()">개인정보 처리방침 ((주)영유통, (주)삼양인터내셔날, (주)한미상사)</a>
            </li>
            <li>
                <a @click="popAgreement()">이용약관</a>
            </li>
            <li > 
                <a @click="popCompany()">회사 정보</a>
            </li>
        </ul>
        <div class="info_app">
            <p>이 응용 프로그램 및 콘텐츠는 한국필립모리스(주)가 소매점주를 대상으로 점주 커뮤니케이션 및 비지니스 활동을 지원하는 사이트로 일반 소비자 접속은 제한되어 있습니다. 정해진 절차에 따른 거래처 등록 없이 설치된 앱(APP)은 삭제 해주십시오.</p>
        </div>
    </div>
    <!-- popPolicy -->
    <div class="layer_view_area" v-show="showPolicy">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>개인정보 처리방침</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="close()"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->

        <div class="container">
            <div class="cont_in">
                <div class="select_box">
                    <select v-model="policy">
                        <option v-for="option in policyOptions" v-bind:key="option.value" v-bind:value="{id: option.id, text: option.text}">{{option.text}}</option>                        
                    </select>
                    <span>{{policy.text}}</span>
                </div>
                <img :src="getPolicyImage()"/>
            </div>
        </div>
    </div>

    <!-- popPolicy3 -->
    <div class="layer_view_area" v-show="showPolicy3">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>개인정보 처리방침</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="close()"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->

        <div class="container">
            <div class="cont_in">
                <div class="select_box">
                    <select v-model="policy3">
                        <option v-for="option in policy3Options" v-bind:key="option.value" v-bind:value="{id: option.id, text: option.text}">{{option.text}}</option>                        
                    </select>
                    <span>{{policy3.text}}</span>
                </div>
                <img :src="getPolicy3Image()"/>
            </div>
        </div>
    </div>

    <!-- popAgreement -->
    <div class="layer_view_area" v-show="showAgreement">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>이용약관</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="close()"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->

        <div class="container">
            <div class="cont_in">
                <div class="select_box">
                    <select v-model="agreement">
                        <option v-for="option in agreementOptions" v-bind:key="option.value" v-bind:value="{id: option.id, text: option.text}">{{option.text}}</option>                        
                    </select>
                    <span>{{agreement.text}}</span>
                </div>
                <img :src="getAgreement()"/>
            </div>
        </div>
    </div>


    <!-- popCompany -->
    <div class="layer_view_area" v-show="showCompany">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>회사 정보</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="close()"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->
        <div class="container">
            <div class="sec">
                <table class="table_info">
                    <tbody>
                        <tr>
                            <th>법인명(상호)</th>
                            <td>(주)영유통</td>
                        </tr>
                        <tr> <!--https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1108120980&apv_perm_no=-->
                            <th>사업자 <br/>등록번호</th>
                            <td>211-86-00394 <!-- <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1108120980&apv_perm_no=" target="_blank">(사업자정보확인)</a> --></td>
                        </tr>
                        <tr class="add">
                            <th>주소</th>
                            <td>서울특별시 강남구 도산대로 445<br> (청담동, 엠빌딩 3층)</td>
                        </tr>
                        <tr>
                            <th>대표자(성명)</th>
                            <td>CHO NICHOLAS</td>
                        </tr>
                        <tr>
                            <th>대표번호</th>
                            <td>+82 2-549-5210</td>
                            <!-- <td><a href="tel:+82 2-3707-0700" >+82 2-3707-0700</a></td> -->
                        </tr>
                        <!-- <tr >
                            <th>고객만족센터 <br/> 일반 담배</th>
                            <td>+82 80-781-7000</td>
                            <td><a href="tel:+82 80-781-7000" >+82 80-781-7000</a></td>
                        </tr> -->
                        <tr>
                            <th>이메일</th>
                            <td>e-order@ydc.kr</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="sec">
                <table class="table_info">
                    <tbody>
                        <tr>
                            <th>법인명(상호)</th>
                            <td>(주)삼양인터내셔날</td>
                        </tr>
                        <tr> <!--https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1108120980&apv_perm_no=-->
                            <th>사업자 <br/>등록번호</th>
                            <td>217-81-09699 <!-- <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1108120980&apv_perm_no=" target="_blank">(사업자정보확인)</a> --></td>
                        </tr>
                        <tr class="add">
                            <th>주소</th>
                            <td>서울특별시 종로구 계동길31 <br> (재동, 보헌빌딩)</td>
                        </tr>
                        <tr>
                            <th>대표자(성명)</th>
                            <td>하영봉</td>
                        </tr>
                        <tr>
                            <th>대표번호</th>
                            <td>+82 2-3670-9633</td>
                            <!-- <td><a href="tel:+82 2-3707-0700" >+82 2-3707-0700</a></td> -->
                        </tr>
                        <!-- <tr >
                            <th>고객만족센터 <br/> 일반 담배</th>
                            <td>+82 80-781-7000</td>
                            <td><a href="tel:+82 80-781-7000" >+82 80-781-7000</a></td>
                        </tr> -->
                        <tr>
                            <th>이메일</th>
                            <td>e-order@esyi.co.kr</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="sec">
                <table class="table_info">
                    <tbody>
                        <tr>
                            <th>법인명(상호)</th>
                            <td>(주)한미상사</td>
                        </tr>
                        <tr> <!--https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1108120980&apv_perm_no=-->
                            <th>사업자 <br/>등록번호</th>
                            <td>607-81-17373 <!-- <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1108120980&apv_perm_no=" target="_blank">(사업자정보확인)</a> --></td>
                        </tr>
                        <tr class="add">
                            <th>주소</th>
                            <td>부산광역시 금정구 동천로7번길 20 <br> (회동동)</td>
                        </tr>
                        <tr>
                            <th>대표자(성명)</th>
                            <td>최철견</td>
                        </tr>
                        <tr>
                            <th>대표번호</th>
                            <td>+82 51-581-6572</td>
                            <!-- <td><a href="tel:+82 2-3707-0700" >+82 2-3707-0700</a></td> -->
                        </tr>
                        <!-- <tr >
                            <th>고객만족센터 <br/> 일반 담배</th>
                            <td>+82 80-781-7000</td>
                            <td><a href="tel:+82 80-781-7000" >+82 80-781-7000</a></td>
                        </tr> -->
                        <tr>
                            <th>이메일</th>
                            <td>e–order@hanmii.co.kr</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
<script>

export default {
    name: 'Footer',
    props: {
        pop: {
            default : ''
        },
    },
    data() {
        return {
            policy: '',
            policyOptions: [
                {text: '개인정보 처리방침 (시행일 : 2024/11/05 ~ 현재)', id: 'PMK_20241105_1.jpg'},
                {text: '개인정보 처리방침 (시행일 : 2024/03/30 ~ 2024/11/04)', id: 'PMK_20240330_1.jpg'},
                {text: '개인정보 처리방침 (시행일 : 2023/10/11 ~ 2024/03/29)', id: 'PMK_20231011_1.png'},
                {text: '개인정보 처리방침 (시행일 : 2022/05/14 ~ 2023/10/10)', id: 'PMK_20220514_1.png'},
                {text: '개인정보 처리방침 (시행일 : 2021/05/01 ~ 2022/05/13)', id: 'PMK_20210406_3.png'},
            ],
            policy3: '',
            policy3Options: [
                {text: '개인정보 처리방침 (시행일 : 2024/03/30 ~ 현재)', id: 'EZD_20240330_1.jpg'},
                {text: '개인정보 처리방침 (시행일 : 2022/05/14 ~ 2024/03/29)', id: 'EZD_20220514_2.png'},
                {text: '개인정보 처리방침 (시행일 : 2021/05/01 ~ 2022/05/13)', id: 'EZD_20210406_3.png'},
            ],
            agreement:'',
            agreementOptions : [
                {text: '이용약관 (시행일 : 2022/05/14 ~ 현재)', id: 'DTE_20220514.png'},
                {text: '이용약관 (시행일 : 2021/05/01 ~ 2022/05/13)', id: 'DTE_20210506.png'},
            ]
            ,
            showPolicy: false,
            showCompany : false,
            showAgreement : false,
            showPolicy3 : false,
            image : '',
        }
    },
    created() {
        this.policy = this.policyOptions[0];
        this.policy3 = this.policy3Options[0];
        this.agreement = this.agreementOptions[0];

        if(this.pop == 'popPolicyPmi'){
            this.showPolicy = true;
        }else if(this.pop == 'popAgreement'){
            this.showAgreement = true;
        }else if(this.pop == 'popCompany'){
            this.showCompany = true;
        }else if(this.pop == 'showPolicy3'){
            this.showPolicy3 = true
        }
    },
    methods: {
        popPolicy() {
            this.showPolicy = true;
        },
        popAgreement() {
            this.showAgreement = true;
        },
        getPolicyImage() {
            let image = require.context('@/assets/html/policy', false);
            return image('./' + this.policy.id);
        },
        getPolicy3Image(){
            let image = require.context('@/assets/html/policy', false);
            return image('./' + this.policy3.id);
        },
        getAgreement() {
            let image = require.context('@/assets/html/policy', false);
            return image('./' + this.agreement.id);
        },
        popCompany(){
            this.showCompany = true;
        },
        popPolicy3(){
            this.showPolicy3 = true;
        },
        close() {
            this.showPolicy = false;
            this.showCompany = false;
            this.showAgreement = false;
            this.showPolicy3 = false;

            if(this.pop != null && this.pop != ''){
                this.$router.go(-1);
            }
        },
        
    }
}

</script>

<style lang="scss">
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
</style>