<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">

            <!-- 공지사항 리스트 -->
            <div class="list">
                <div class="splitup_area condition_wrap">
                    <div class="s_left">
                        <span class="select_box">
                            <select name="selectType" v-model="selected" @change="changeType">
                                <option value="display_order">기본</option>
                                <option value="contents_mst_seq">최신순</option>
                                <option value="subject">제목순</option>
                            </select>
                            <span v-if="selected == display_order">기본</span>
                            <span v-if="selected == contents_mst_seq">최신순</span>
                            <span v-else-if="selected == subject">제목순</span>
                        </span>
                    </div>
                    <div class="s_right">
                        <span>전체 {{totCnt}}건</span>
                    </div>
                </div>

                <!-- 리스트 -->
                <ul class="content_list">
                    <li  v-for="notice of contents" :key="notice.contentsMstSeq">
                        <a href="#" class="block_wrap" v-on:click.prevent="popupOpen(`${notice.contentsMstSeq}`)">
                            <div class="thumb">
                                <!-- <div v-if = "notice.extension != null && notice.extension != ''">
                                    <div v-if = "notice.extension === 'avi' || notice.extension === 'wmv' || notice.extension === 'asf'
                                        || notice.extension === 'flv' || notice.extension === 'mov' || notice.extension === 'mpeg'
                                        || notice.extension === 'mpeg1' || notice.extension === 'mpeg2' || notice.extension === 'mpeg4'
                                        || notice.extension === 'mp4'">
                                        <p><video :src="notice.filePath"></video></p>
                                    </div>
                                    <div v-else>
                                        <p><img :src=notice.filePath alt="공지사항 관련 이미지"></p>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <div class="block_wrap"> -->
                                <div class="title" >
                                    {{notice.subject}}
                                    <span v-if = "notice.newDay === '1'" class="ico_red">NEW</span>
                                </div>
                                <div class="txt_subject">
                                    <span class="tit">게시일 :</span>
                                    <span class="cont">{{notice.registDttm}}</span>
                                    <span class="bar"></span>
                                    <span class="tit">조회 :</span>
                                    <span class="cont">{{notice.viewCnt}}</span>
                                </div>
                            <!-- </div> -->
                        </a>
                    </li>
                </ul>

                <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" spinner="waveDots">
                    <div class="no_result" slot="no-results">
                        게시글이 없습니다.
                    </div>
                    <div slot="no-more"></div>
                </infinite-loading>   

                <!-- //리스트 -->
            </div>
            <!-- //공지사항 리스트 -->

        </div>
        <!-- //container -->

        <!-- popup -->
        <Detail :id="$route.params.id"/>
    </div>
</template>

<script>
import Contents from '@/api/Contents'
import Detail from '@/components/notice/NoticeDetail'
import InfiniteLoading from 'vue-infinite-loading';
import $ from 'jquery';

export default {
    name: 'Contents',
    props: {
        contentsMstSeq: {
            type: String,
            default : ''
        },
        viewCnt: Number,
        viewSeq: String
    },
    components: {
        InfiniteLoading,
        Detail
    },

  data: () => {
    return {
        contents: [],
        startNo: 1,
        sortBy: "",
        infiniteId: +new Date(),
        contents_mst_seq: "contents_mst_seq",
        subject: "subject",
        display_order : 'display_order',
        selected: "display_order",
        totCnt: 0,
        sort: "contents_mst_seq",
        newDay: "",
    }
  },

  created() {

        //리스트 total
      Contents.getContentsList(this.sortBy, 'DESC', this.startNo, 'Notice')
      .then((response) => {
            this.totCnt = response.data[0].totCnt;
      })
      .catch((e) => {
        console.log("error : ", e);
      });

  },

  updated() {
      for(var i=0; i<this.contents.length; i++){
            if(this.contents[i].contentsMstSeq == this.viewSeq){
                this.contents[i].viewCnt = this.viewCnt;
            }
        }

        if(this.selected == this.display_order){
            this.sortBy = ''
        }else if(this.selected == this.subject){
            this.sortBy = this.subject
        }else {
            this.sortBy = this.contents_mst_seq
        }
  },

  methods: {
    popupOpen (id) {
        this.$router.push({name: 'NoticeDetail', params: {id: id}});
    },
    
    infiniteHandler($state) {
        Contents.getContentsList(this.sortBy, 'DESC', this.startNo, 'Notice')
        .then(({data}) => {
            setTimeout(() => {
            if (data.length) {
                this.startNo += 20;
                this.contents = this.contents.concat(data);
                console.log("after", this.contents.length, this.startNo);
                $state.loaded();
            } else {
                $state.complete();
            }
            }, 100)
        });
    },

    changeType() {
      this.startNo = 1;
      this.contents = [];
      this.infiniteId += 1;
    },


  }

};
</script>

<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';

 
</style>
