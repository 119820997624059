import Send from '../utils/Send.js'

export default {
    getDcList(data) {
        return Send({
            url: '/discount/list',
            method: 'get',
            params: data
        })
    },

}   