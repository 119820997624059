import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

//export default new Vuex.Store({
const store = new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    session: null,
    customer: null,
    cart: null,
    maintenance: null, // 시스템점검 정보
    isOrderPage: false,
    orderUpdate : null, // 주문 병합여부 (true / null)
    cartPopUpCheck : null,
    otpReqestCount : null, // 1분간 인증횟수 체크
    accessdenied: false,
    refundData: null,
    ezOrderData: null,
    loginKey: null,
    specialHeader: null,
    unitDefine: null,
  },
  mutations: {
    customer(state, customer) {
      state.customer = customer;
    },
    session(state, token) {
      let date = new Date();
      date.setMinutes(date.getMinutes()+30); // 세션 타임아웃 시간 30분
      state.session = {
        token: token,
        expire: date
      }
    },
    cart(state, cart) {
      state.cart = {
        count: cart.length
      }
    },
    refundData(state, refundData) {
      state.refundData = refundData
    },
    ezOrderData(state, ezOrderData) {
      state.ezOrderData = ezOrderData
    },
    specialHeader(state, specialHeader) {
      state.specialHeader = specialHeader
    },
    
    maintenance(state, data) {
      if (data) {
        if (state.maintenance == null) {
          state.maintenance = {
            start: data.start,
            end: data.end
          }
  
          // 로그아웃 처리 후 점검안내 페이지로 이동
          state.session = null;
          state.customer = null;
          state.cart = null;
          window.location.href = '/system';
        }
      } else {
        state.maintenance = null;
        window.location.href = '/login';
      }
    },
    isOrderPage(state, isOrderPage) {
      state.isOrderPage = isOrderPage;
    },
    orderUpdate(state, orderUpdate) {
      state.orderUpdate = orderUpdate;
    },  
    otpRequest(state) {
      let current = (new Date()).getTime();

      // 최초 인증이후 경과시간이 1분 미만인 경우 기존 횟수+1, 아니면 최초인증시간과 카운트 초기화
      if (state.otpRequest != null && (current - state.otpRequest.time) < 60*1000) {
        state.otpRequest.count++;
      } else {
        state.otpRequest = {
          time: current,
          count: 1
        }
      }
    },       
    cartPopUpCheck(state, cartPopUpCheck) {
      state.cartPopUpCheck = cartPopUpCheck;
    },
    accessdenied(state, isAccessDenied) {
      state.accessdenied = isAccessDenied;

      if (isAccessDenied) {
        window.location.href = '/access';
      } else {
        window.location.href = '/';
      }
    },
    logout(state) {
      state.session = null;
      state.customer = null;
      state.cart = null;
      state.orderUpdate = null;
      state.cartPopUpCheck = null;
      state.unitDefine = null;
      window.location.href = '/login';
    },
    loginKey(state, loginKey) {
      state.loginKey = loginKey;
    },
    unitDefine(state, unitDefine) {
      state.unitDefine = unitDefine;
    },  
  },
  actions: {
  },
  modules: {
  }
})

export default store