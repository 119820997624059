<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">

            <!-- 설문 리스트 -->
            <div class="list">
                <ul class="survey_wrap" v-for="survey of surveyList" :key="survey.sfid">
                    <li>
                        <a @click="goDetail(survey.sfid, survey.taken, survey.takenDate, survey.sign)">
                            <div class="in_top">
                                <div class="thumb">
                                    <img v-if="survey.filePath" :src="survey.filePath" alt="설문조사 이미지">
                                    <img v-else src="@/assets/styles/images/img_default.gif" alt="설문조사 이미지">
                                </div>
                                <div class="txt_area">
                                    <div v-if = "survey.taken == false">
                                        <div v-if = "survey.sign == 1">
                                            <span class="top ico_red">참여 전</span>
                                        </div>
                                        <div v-else>
                                            <span class="top ico_gray">미참여</span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <span class="top ico_gray">참여 완료</span>
                                    </div>
                                    <div class="title">{{survey.subject}}</div>
                                </div>
                            </div>
                            <div class="in_bottom">
                                <div v-if = "survey.sign == 1">
                                    <span class="date_1 t_red">설문 마감 D - {{survey.dday}}</span>
                                    <span class="date_2">{{survey.startDt}} ~ {{survey.endDt}}</span>
                                </div>
                                <div v-else>
                                    <span class="date_1">마감 완료</span>
                                    <span class="date_2">{{survey.startDt}} ~ {{survey.endDt}}</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>

                <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler" spinner="waveDots">
                    <div class="no_result" slot="no-results">
                        진행중인 설문조사가 없습니다.
                    </div>
                    <div slot="no-more"></div>
                </infinite-loading>   


            </div>
            <!-- 설문 리스트 -->

        </div>
        <!-- //container -->

        <div class="layerdim_wrap" v-if="message">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>{{message}}</p>
                            <p v-if="takeDate">(참여일 : {{takeDate}})</p>
                            <div class="btn_center">
                                <a class="btn_wht" @click="close()">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Detail :id="$route.params.id"/>
    </div>
</template>

<script>
import Survey from '@/api/Survey'
import Detail from '@/components/survey/SurveyDetail'
import InfiniteLoading from 'vue-infinite-loading';

export default {
    name: 'Survey',
    components: {
        Detail,
        InfiniteLoading
    },

    data: () => {
        return {
            surveyList: [],
            sortBy: "endDt",
            startNo: 1,
            takeDate: "",
            message: ""
        };
    },

    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                console.log('watch router to', to)
                if (to.name == 'Survey' && from.name == 'SurveyDetail') {
                    this.startNo = 1;
                    this.surveyList = [];
                    if (this.$refs.infiniteLoading) {
                        return this.$refs.infiniteLoading.stateChanger.reset();
                    }
                }
            }
        }
    },
    methods: {

        infiniteHandler($state) {
            Survey.getList({
                    "sort.sortBy": this.sortBy, 
                    "sort.desc": "DESC",
                    "page.startNo": this.startNo
                })
                .then(({data}) => {
                    setTimeout(() => {
                    if (data.length) {
                        this.startNo += 20;
                        this.surveyList = this.surveyList.concat(data);
                        //console.log("after", this.surveyList.length, this.startNo);
                        //console.log("dd", this.surveyList);
                        $state?.loaded();
                    } else {
                        $state?.complete();
                    }
                    }, 100)
                });
        },
        goDetail(sfid, taken, takeDate, sign) {
            if (taken == false) {
                if (sign == 1) {
                    this.$router.push({name: 'SurveyDetail', params: {id: sfid}});//, query: {accId: this.$store.state.customer.id}});
                } else {
                    this.message = '설문조사 기간이 만료되었습니다.';
                }
            } else {
                this.message = '이미 참여하셨습니다.';
                this.takeDate = takeDate.substring(0,10);
            }
        },
        close() {
            this.message = '';
            this.takeDate = '';
        }
    }   




};
</script>

<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
</style>
