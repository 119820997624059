<template>
    <div>
        <div class="wrap">
            <div class="checking_wrap">
                <div class="inner_area">
                    <h1>시스템 점검중</h1>
                    <p class="txt">시스템 점검시간동안 접속이 불가합니다.<br /> 양해바랍니다.</p>
                    <div class="date">
                        <div class="header">시작</div> 
                        <div class="text">{{$store.state.maintenance.start}}</div>
                    </div>
                    <div class="date">
                        <div class="header">종료</div> 
                        <div class="text">{{$store.state.maintenance.end}}</div>
                    </div>
                </div>
            </div>
    
        </div>
        <div class="lg_footer">
            <CustomerCenter/>
        </div>    
    </div>
</template>

<script>
import CustomerCenter from './CustomerCenter'
import System from '@/api/System'

export default {
    components: {
        CustomerCenter
    },  
    data: () => {
        return {
            start: "",
            end: ""
        };
    },
    created() {
        System.getCheck()
            .then(res => {
                // 시스템 점검 해제
                this.$store.commit('maintenance', false);
            });
        
    } 
};



</script>

<style >
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
</style>
    