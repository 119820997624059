import Send from '../utils/Send.js'

export default {
    getProduct(data) {
        return Send({
            url: '/product',
            method: 'get',
            params : data,
        })
    },
    getPackageDetailQuantity() {
        return Send({
            url: '/product/package/quantity',
            method: 'get'
        })
    }
}   