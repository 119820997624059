import Send from '@/utils/Send.js'

export default {
    post(params) {
        return Send({
            url: '/file',
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: params,
            timeout: 1200000
        })
    }
}