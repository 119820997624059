<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">
            <div class="top_information">
                <ul>
                    <li class="order_history">
                        <span class="r_box">최근주문</span>
                        <a href="/orderHistory" class="txt mt-1">{{orderDt}} {{statusCode}} </a>
                    </li>
                    <li class="product_order">
                        <div class="box_area">
                            <a href="/product" class="icon_1">
                                <p class="tit">상품주문</p>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="flexible">
                    <!-- <div class="m_section">
                        <div class="box_area red">
                            <a href="/product" class="icon_1">
                                <p class="tit">상품주문</p>
                            </a>
                        </div>
                    </div> -->
                <!-- 21.02.26 퍼블수정 : 배너추가, 배너아래 리스트 클래스명 변경 -->
                <div class="m_section">
                    <div class="banner_area">
                        <carousel v-model=index :per-page="1" :slidePerPage="1" class="banner_list" 
                            paginationActiveColor="#e01a31" 
                            paginationColor="#999" 
                            :paginationPadding=3>
                            <slide v-for="banner of banners" :key="banner.contentsMstSeq" class="slide_item" >
                                <img :src="banner.filePath" @click="moveUrl(banner.linkUrl, banner.boardName)">
                            </slide>
                        </carousel>
                        <div v-if="bannerLength === 1">
                            <ul class="slide_dots">
                                <li class="active"><button type="button">1</button></li>
                            </ul>
                        </div>
                    </div>
                    
                    <ul class="m_section_list">
                        <!-- <li class="box_area">
                            <a href="/notice" class="icon_2">
                                <p class="tit">공지사항</p>
                                <p>주요 공지 및 안내사항</p>
                            </a>
                        </li> -->
                        <!--
                        <li class="box_area middle">
                            <a href="/survey" class="icon_4">
                                <p class="tit">설문조사</p>
                            </a>
                        </li>
                        -->
                    </ul>
                </div>                
            </div>

            <!-- // 21.02.26 퍼블수정 : 배너추가, 배너아래 리스트 클래스명 변경 -->
            <!-- <div class="bottom_information">
                <a href="#" v-on:click.prevent="noticePopup()">{{subject}}</a>
            </div> -->
        </div>
        <!-- //container -->

        <!-- pop-up -->
        <div class="layerdim_wrap" v-if="file || content" style="background:rgba(0, 0, 0, 0.5);" >
            <div class="dim_inner">
                <div class="layer_wrap">
                    
                    <div class="layer_box mix" v-if="this.file != null && this.content != null">
                        <div class="layer_head">
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <!-- popup- 폴라로이드형 -->
                        <div>
                            <div class="image_area" >
                                <img :src=file alt="팝업 이미지">
                            </div>
                            <div class="text_area">
                                <p v-html="content"></p>
                            </div>
                        </div>
                        <div class="bottom_area">
                            <button type="button" @click="weekClose()">일주일간 그만보기</button>
                            <button type="button" @click="close()">닫기</button>
                        </div>

                    </div>
                    <div class="layer_box" v-if="this.file == null && this.content != null">
                        <div class="layer_head">
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <!-- popup- 텍스트형 -->
                        <div>
                            <div class="text_area">
                                <p v-html="content"></p>
                            </div>
                        </div>
                        <div class="bottom_area">
                            <button type="button" @click="weekClose()">일주일간 그만보기</button>
                            <button type="button" @click="close()">닫기</button>
                        </div>

                    </div>

                    <div class="layer_box" v-if="this.file != null && this.content == null">
                        <div class="layer_head">
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <!-- popup- 이미지형 -->
                        <div>
                            <div class="image_area">
                                <img :src=this.file alt="팝업 이미지">
                            </div>
                        </div>


                        <div class="bottom_area">
                            <button type="button" @click="weekClose()">일주일간 그만보기</button>
                            <button type="button" @click="close()">닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';
import Contents from '@/api/Contents'
import VueCookies from "vue-cookies"
import Order from '@/api/Order'
import Customer from '@/api/Customer'
import { Carousel, Slide } from 'vue-carousel';
import Log from "@/api/Log";

export default {

    components: {
        Carousel,
        Slide
    },

    data: () => {
        return {
            subject: "",
            sortBy: "regist_dttm",
            contentsMstSeq: "",
            pop: [],
            customerVO: "",
            customerCode : "",   
            order: "",
            statusCode: "",
            orderDt: "",
            banners: [],
            bannerLength: 0,
            index: 1,
            file: '',
            content: '',
            popIndex: 0,
            seq: ""
        }
    },

    beforeCreate(){
        // 고객정보
        Customer.getDetail()
        .then((response) => {
            this.customerVO = response.data
            this.customerCode = this.customerVO.CustomerCode__c
        })
        .catch((e) => {
        console.log("error : ", e);
        });
        
    },

    created() {
        // swipe 배너
        Contents.getContentsList('', '', this.startNo, 'Banner')
        .then((response) => {
            this.banners = response.data;
            this.bannerLength = this.banners.length;
        })
        .catch((e) => {
            console.log("error : ", e);
        });

        
        // 공지사항 배너
        Contents.getContentsList('contents_mst_seq', 'DESC', this.startNo, 'Notice')
        .then((response) => {
            this.subject = response.data[0].subject;
            this.contentsMstSeq = response.data[0].contentsMstSeq;
        })
        .catch((e) => {
            console.log("error : ", e);
        });

        // 팝업
        Contents.getContentsList('display_begin_dttm', 'DESC', this.startNo, 'Pop-up')
        .then((response) => {
            this.pop = response.data;
            this.file = this.pop[this.popIndex].filePath
            this.content = this.pop[this.popIndex].contents
            this.seq = this.pop[this.popIndex].contentsMstSeq

        })
        .catch((e) => {
            console.log("error : ", e);
        });

        // 최근주문 현황
        var params = {
            'limit' : 'true'
        }
        Order.getOrderHistory(params)
        .then((response) => {
            if(response.data[0] != null){
                var orderList = response.data[0]
                
                // 최근주문 날짜
                if(orderList.statusCode == 'Ordered' || orderList.statusCode == 'Approved' || orderList.statusCode == 'Waiting'  || orderList.statusCode == 'Paid'  
                    || orderList.statusCode == 'RefundRequested'  || orderList.statusCode == 'RefundConfirmed'
                    ||(orderList.statusCode == 'Canceled' && orderList.cancelReason == 'CR01')){
                    this.order = orderList.statusDt
                    var month = this.order.substr(5,2);
                    var day = this.order.substr(8,2);
                    this.orderDt = month+"/"+day;
                }else if(orderList.statusCode == 'Ended' || (orderList.statusCode == 'Canceled' && orderList.cancelReason != 'CR01')){
                    this.order = orderList.deliveryDt
                    var month1 = this.order.substr(5,2);
                    var day1 = this.order.substr(8,2);
                    this.orderDt = month1+"/"+day1;
                }
                

                // 최근주문 상태
                this.statusCode = orderList.statusCode

                if(this.statusCode == 'Ordered'){
                    this.statusCode = '주문완료';
                }else if(this.statusCode == 'Waiting'){
                    this.statusCode = '입금대기';
                }else if(this.statusCode == 'Paid'){
                    this.statusCode = '결제완료';
                }else if(this.statusCode == 'Approved'){
                    this.statusCode = '승인완료';
                }else if(this.statusCode == 'Ended'){
                    this.statusCode = '배송완료';
                }else if(this.statusCode == 'Canceled'){
                    this.statusCode = '주문취소';
                }else if(this.statusCode == 'RefundRequested'){
                    this.statusCode = '환불대기';
                }else if(this.statusCode == 'RefundConfirmed'){
                    this.statusCode = '환불완료';
                }
            }

        })
        .catch((e) => {
            console.log("error : ", e);
        });
    
    },

    updated(){
        
        if(this.popIndex != this.pop.length){
            //쿠키
            if (VueCookies.isKey(this.seq)) {
                this.popIndex = this.popIndex+1
            }
            if(this.pop.length == this.popIndex){
                this.file = '';
                this.content = '';
            }else{
                this.file = this.pop[this.popIndex].filePath
                this.content = this.pop[this.popIndex].contents
                this.seq = this.pop[this.popIndex].contentsMstSeq
            }

        }
        
    },


    methods : {

        // 일주일간 그만보기
        weekClose(){

            VueCookies.set(this.seq, new Date(), '7d');
            this.popIndex = this.popIndex+1;

            if(this.pop.length == this.popIndex){
                this.file = '';
                this.content = '';
            }else{
                this.file = this.pop[this.popIndex].filePath
                this.content = this.pop[this.popIndex].contents
                this.seq = this.pop[this.popIndex].contentsMstSeq
            }
            
        },

        close() {
            this.popIndex = this.popIndex+1;

            if(this.pop.length == this.popIndex){
                this.file = '';
                this.content = '';
            }else{
                this.file = this.pop[this.popIndex].filePath
                this.content = this.pop[this.popIndex].contents
                this.seq = this.pop[this.popIndex].contentsMstSeq
            }
        },

        noticePopup: function(){
            var URL = $(location).attr('protocol')+"//"+$(location).attr('host')+"/notice/"+this.contentsMstSeq
            //$(location).attr('href',URL);
            location.href = URL;

            //if(this.contentsMstSeq != ""){
                //this.$router.push({name: 'NoticeDetail', params: {id: this.contentsMstSeq}})
            //}
        },

        moveUrl: async function(linkUrl, boardName) {
            console.log('moveUrl', boardName);

            // customer + banner id 로그 insert
            var params = this.$store.state.customer.customerCode + ',' + boardName;
            try {
                await Log.insert('UI_BANNER', params, '');
            } catch (e) {
                console.log('insert log error');
            }

            if (linkUrl != null) {
                // servey 상세인 경우 customer id 추가 또는 교체
                if (linkUrl.indexOf('survey') > -1) {
                    if (linkUrl.indexOf('?') > -1) {
                        linkUrl = linkUrl.split('?')[0];
                    }
                    linkUrl += '?accId=' + this.$store.state.customer.id;
                }

                location.href = linkUrl;
            }
        }

        

    }

    

};
</script>

<style lang="scss">
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
 





</style>