<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">
            <!-- 주문현황 조회기간 선택 -->
            <div class="sec">
                <div class="section_search">
                    <h2 class="sub_title">조회기간 선택 (주문 수정일 기준)</h2>
                    <div class="wrap_top">
                        <span class="btn_area">
                            <button type="button" id="week" class="button_sch on" @click="clickDate('week')">1주일</button>
                        </span>
                        <span class="btn_area">
                            <button type="button" id="1" class="button_sch" @click="clickDate(1)">1개월</button>
                        </span>
                        <span class="btn_area">
                            <button type="button" id="3" class="button_sch" @click="clickDate(3)">3개월</button>
                        </span>
                        <span class="btn_area">
                            <button type="button" id="6" class="button_sch" @click="clickDate(6)">6개월</button>
                        </span>
                    </div>
                    <div class="wrap_search">
                        <div class="warp_date">
                            <div class="date_select">
                                <datepicker :date.sync="date1" />
                            </div>
                            <div class="dash">~</div>
                            <div class="date_select">
                                <datepicker :date.sync="date2" />
                            </div>
                        </div>
                        <a class="btn_gray" @click="clickSearchList(date1,date2)">조회</a>
                    </div>
                </div>
            </div>
            <!-- //주문현황 조회기간 선택 -->

            <!-- 주문현황 리스트 -->
            <div class="sec">
                <h2 class="hidden">주문리스트</h2>

                <!-- orderedList 주문현황 -->
                <div class="sub_sec" v-for="(ordered, index) in orderHistoryLists" :key="index">
                    <div class="top_option" >
                        <div class="detail_wrap">
                            <span class="ico_red" v-if="ordered.statusCode == 'Ordered'">주문완료</span>
                            <span class="ico_red" v-else-if="ordered.statusCode == 'Waiting'">입금대기</span>
                            <span class="ico_blue" v-else-if="ordered.statusCode == 'Paid'">결제완료</span>
                            <span class="ico_blue" v-else-if="ordered.statusCode == 'Approved'">승인완료</span>
                            <span class="ico_blue" v-else-if="ordered.statusCode == 'RefundRequested'">환불대기</span>
                            <span class="ico_gray" v-else-if="ordered.statusCode == 'RefundConfirmed'">환불완료</span>
                            <span class="ico_gray" v-else-if="ordered.statusCode == 'Ended'">배송완료</span>
                            <span class="ico_gray" v-else-if="ordered.statusCode == 'Canceled'">주문취소</span>
                            <strong class="right" v-if="ordered.statusCode == 'Ordered' 
                                || ordered.statusCode == 'Approved' || ordered.statusCode == 'Waiting' || ordered.statusCode == 'Paid' || ordered.statusCode == 'RefundRequested'" >{{ordered.statusDt}}</strong>
                            <strong class="right" v-if="ordered.statusCode == 'Ended'" >{{ordered.deliveryDt}}</strong>
                            <strong class="right" v-if="(ordered.statusCode == 'Canceled' && ordered.cancelReason == 'CR01') || ordered.statusCode == 'RefundConfirmed'">
                                    {{ordered.statusDt}}
                            </strong>
                            <strong class="right" v-if="ordered.statusCode == 'Canceled' && ordered.cancelReason != 'CR01'">
                                    {{ordered.deliveryDt}}
                            </strong>
                            <strong class="right" style="padding-left:5px" >(주문수정 : {{ordered.orderUpdateDt}})</strong>
                            <!-- <span class="" >(주문일:{{ordered.registDt}})</span> -->
                        </div>
                        <div class="detail_wrap">
                            <span class="ico_line">주문번호</span>
                            <strong class="right">{{ordered.eorderNumber}}</strong>
                        </div>
                        <div class="detail_wrap">
                            <span class="ico_line">주문정보</span>
                            <span class="amount" v-if="ordered.statusCode == 'Ended'">
                                <strong>{{ordered.amount-ordered.dcAmt | makeComma}}</strong>
                                원   
                            </span>
                            <span class="amount" v-if="ordered.statusCode != 'Ended'">
                                <strong>{{ordered.amount-ordered.dcAmt | makeComma}}</strong>
                                원   
                            </span>
                            <span>/</span>
                            <span class="amount" :id="'quantity_' + ordered.eorderNumber"></span>
                            <!-- <a class="btn_link" @click="showOrderDetail(`${ordered.eorderNumber}`)">주문상세</a> -->
                        </div>
                        <div class="detail_wrap">
                            <span class="ico_line">배송일</span>
                            <strong class="right" v-if="ordered.statusCode == 'Ordered'" style="color: #e01a31">{{ordered.deliveryDt}} ({{$root.dayKor(ordered.deliveryDt)}}) 예정</strong>
                            <strong class="right" v-else-if="ordered.statusCode == 'Canceled'">주문취소</strong>
                            <strong class="right" v-else style="color: #e01a31">{{ordered.deliveryDt}} ({{$root.dayKor(ordered.deliveryDt)}}) 확정</strong>
                        </div>
                        <div class="detail_wrap">
                            <span class="ico_line">결제방식</span>
                            <strong class="right" v-if="ordered.payType == 'VC'" >가상계좌</strong>
                            <strong class="right" v-else-if="ordered.payType == 'EZ'" >간편결제</strong>
                            <strong class="right" v-else>현금수금</strong>
                        </div>
                        <div v-if="ordered.payType == 'VC' && ordered.statusCode == 'Waiting'"  class="detail_wrap" style="color:#e31a33; font-size= 20px;font-weight:bold;">
                            * {{ordered.expireDate.substring(5,10).replace('-','/')}} ({{$root.dayKor(ordered.expireDate)}}) {{$root.timeKor(ordered.expireTime)}}까지 {{ordered.list[0].bankName}} 
                            <u style="color:#e31a33; font-size= 20px;font-weight:bold;" @click="fnCopy(ordered.list[0].account)">{{ordered.list[0].account}}</u><img alt="계좌번호 복사" src="../../assets/styles/images/icon_contents_copy.png" width="16px" height="16px" @click="fnCopy(account)">
                        </div>
                        
                    </div>

                    <div class="detail_fold accordion">
                        <div class="fold_button" v-if="ordered.isShowDetail">
                            <button type="button" class="bt_open" v-bind:class="{active: ordered.isShowDetail}" @click="toggleDetail(index)">
                                <span>주문상세내역 접기</span>
                            </button>
                        </div>                    
                        <div v-if="ordered.isShowDetail">
                            <div class="info">
                                <h2 class="sub_tit">상품 정보</h2>
                                <ul class="purchase_list orderwrap">
                                    <li  v-for="(product, index) in ordered.list" :key="index">
                                        <div class="product_info">
                                            <div class="thumb_img">
                                                <vue-image :src="product.imageUrl" size="75" />
                                            </div>
                                            <div class="des_area">
                                                <div class="name">{{product.productNm}}</div>

                                                <div class="composition">
                                                <span>{{product.addInfo1}}
                                                    <!-- <em></em> -->
                                                    </span>
                                                    <span> {{product.addInfo2}}
                                                    <!-- <em></em> -->
                                                    </span>
                                                </div>
                                                <div class="composition" v-if="product.addInfo1 == null">
                                                    <span><pre> </pre></span>
                                                </div>
                                                <div class="option">
                                                    <div class="count_area">
                                                        <input type="text" :value="product.quantity + ' (' + unitDefine[product.productType].name + ')'" readonly>
                                                    </div>
                                                    <div class="sum_area">
                                                        <strong>{{product.quantity * product.price | makeComma}}</strong>
                                                        <span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="info">
                                <h2 class="sub_tit">결제 정보</h2>
                                <table class="table_info">
                                    <tbody>
                                        <tr>
                                            <th>총 결제금액</th>
                                            <!-- <td style="color:#e31a33;">{{ordered.amount-(ordered.dcVO != null?ordered.dcVO.amount:0 ) | makeComma}} 원</td> -->
                                            <td style="color:#e31a33;">{{ordered.amount-ordered.dcAmt | makeComma}} 원</td>
                                        </tr>
                                        <tr>
                                            <th>상품가격</th>
                                            <td>{{ordered.amount | makeComma}} 원</td>
                                        </tr>
                                        <!-- <tr v-if="ordered.dcVO != null"> 
                                                <th>할인금액</th>
                                                <td>{{(ordered.dcVO != null?ordered.dcVO.amount:0 ) | makeComma}} 원</td>
                                            </tr>
                                        -->
                                        <tr v-if="ordered.dcName && ordered.dcAmt > 0">
                                            <th>할인금액</th>
                                            <td>{{ordered.dcAmt | makeComma}} 원 ({{ordered.dcName}})</td>
                                        </tr>
                                        <tr>
                                            <th>결제방식</th>
                                            <td>{{ordered.payType == 'VC'?'가상계좌':(ordered.payType == 'EZ'?'간편결제':'현금수금')}}</td>
                                        </tr>
                                        <tr v-if="ordered.payType == 'VC' && ordered.statusCode == 'Waiting'">
                                            <th>입금정보</th>
                                            <td>{{ordered.list[0].bankName}} {{ordered.list[0].account}} (예금주:{{accountHolder}})</td>
                                        </tr>
                                        <tr v-if="ordered.payType == 'VC' && ordered.expireDate && ordered.statusCode == 'Waiting'">
                                            <th>입금기한</th>
                                            <td :class="[ordered.statusCode == 'Waiting'? t_red :'']">{{ordered.expireDate}} {{$root.dayKor(ordered.expireDate)+'요일'}} {{$root.timeKor(ordered.expireTime)}} ({{ordered.expireTime}})</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="info">
                                <h2 class="sub_tit">배송 정보</h2>
                                <table class="table_info">
                                    <tbody>
                                        <tr>
                                            <th>소매점주</th>
                                            <td>{{name}}</td>
                                        </tr>
                                        <tr>
                                            <th>소매점명</th>
                                            <td>{{store}}</td>
                                        </tr>
                                        <tr>
                                            <th>배송예정일</th>
                                            <td>{{ordered.deliveryDt}} {{$root.dayKor(ordered.deliveryDt)+'요일'}}</td>
                                        </tr>
                                        <tr v-if="ordered.requests">
                                            <th>요청사항</th>
                                            <td>{{ordered.requests}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="fold_button">
                            <button type="button" class="bt_open" v-bind:class="{active: ordered.isShowDetail}" @click="toggleDetail(index)">
                                <span v-if="ordered.isShowDetail">주문상세내역 접기</span>
                                <span v-else>주문상세내역 펼치기</span>
                            </button>
                        </div>
                    </div>

                    <div class="top_option">
                        
                        <div class="button_wrap combo_form" v-if="ordered.statusCode == 'Ordered'">
                            <div><button type="button" :class="'btn_blk disabled'+ordered.eorderNumber" @click="orderUpdatePopUp(ordered)">주문 수정</button></div>
                            <div><button type="button" :class="'btn_gray disabled'+ordered.eorderNumber" @click="orderCancelPopUp(ordered)">주문 취소</button></div>
                        </div>
                        <div class="button_wrap" v-if="ordered.statusCode == 'Waiting'">
                            <div><button type="button" :class="'btn_gray disabled'+ordered.eorderNumber" @click="orderCancelPopUp(ordered)">주문 취소</button></div>
                        </div>
                        <div class="button_wrap" v-else-if="ordered.statusCode == 'Ended'">
                            <div><button type="button" class="btn_blk" @click="reOrderPopUp(ordered)">재주문 하기</button></div>
                        </div>
                        <div class="button_wrap" v-else-if="ordered.statusCode == 'Paid'&& ordered.payType == 'VC'">
                            <div><button type="button" class="btn_gray" @click="refundDeadLineCheck(ordered)">환불 요청</button></div>
                        </div>
                        <div class="button_wrap" v-else-if="ordered.statusCode == 'Paid' && ordered.payType == 'EZ'">
                            <div><button type="button" class="btn_gray" @click="orderCancelPopUp(ordered)">주문 취소</button></div>
                        </div>
                    </div>
                </div>
                <!-- orderedList 주문현황 끝 -->
                <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" spinner="waveDots">
                    <div slot="no-results" style="color: rgb(102, 102, 102); font-size: 14px; ">
                        주문건이 없습니다.
                    </div>
                    <div slot="no-more"></div>
                </infinite-loading>
            </div>
            <!-- 주문현황 리스트 -->
        </div>
        <!-- //container -->

        <!-- popup -->
        <!-- orderEndLists 주문완료 -->
        <div class="layer_view_area v2" v-bind:style="popupComplete">
            <!-- header -->
            <div class="header_wrap">
                <div class="header">
                    <h1>주문완료</h1>
                    <div class="hd_left">
                        <a class="aside_back" @click="popupClose()"><span>뒤로가기</span></a>
                    </div>
                </div>
            </div>
            <!-- //header -->
            
            <!-- container -->
            <div class="container">
                <div class="sub_container">
                    <!-- 주문완료 상단 알림글 -->
                    <div class="sec">
                        <div class="order_top">
                            <p class="order_txt" v-if="orderStatus == 'Ordered' || orderStatus == 'Paid'">고객님의 주문이 완료되었습니다.<br/>이용해 주셔서 감사합니다.</p>
                            <p class="order_txt_vc" v-if="orderStatus == 'Waiting'"><br>결제 완료를 위해 <br/>
                                <span class="order_t_bold">{{dashCutoffDate}}({{cutOffDayKor}}) {{cutOffTimeKor}} ({{this.planInfo.cutOffTime}})</span>까지<br>
                                <span class="order_t_bold">{{paymentAmt | makeComma}}원</span>
                                을 입금해주세요.<br><br>
                                입금 결과는 문자로 발송 됩니다. (최대 30분 이내)<br><br><br>
                                입금은행 <br>{{bankName}} <u @click="fnCopy(account)">{{account}}</u> &nbsp;
                                <img alt="계좌번호 복사" src="../../assets/styles/images/icon_contents_copy.png" width="20px" height="20px" @click="fnCopy(account)">
                                <br>예금주: {{accountHolder}} 
                                <!-- <a class="copy_ico" @click="fnCopy(account)"></a> -->
                            </p>
                            <!-- <p class="order_txt" v-if="orderStatus == 'Waiting'"></p> -->
                            
                            <div class="order_num">
                                주문번호
                                <span>{{orderMstSeq}}</span>
                            </div>
                            <br>
                        </div>
                    </div>
                    <!-- //주문완료 상단 알림글 -->
                    
                    <!-- 총 주문 금액 -->
                    <!-- <dl class="amount_area">
                        <dt>총 주문 금액</dt>
                        <dd>{{totalAmount | makeComma}}<span>원</span></dd>
                    </dl> -->
                    <div>
                        <dl class="amount_area">
                            <dt>총 결제 금액</dt>
                            <dd>{{paymentAmt | makeComma}}<span>원</span></dd>
                        </dl>
                        <dl class="amount_area">
                            <dt>총 주문 수량</dt>
                            <dd id="completeQuantity"></dd>
                        </dl>
                    </div>
                    <!-- 주문완료 리스트 -->
                    <div class="sec">
                        <h2 class="hidden">주문리스트</h2>
                        <ul class="purchase_list orderwrap">
                            <li v-for="(item, index) in orderEndLists" :key="index" >
                                <div class="product_info">
                                    <div class="thumb_img">
                                        <vue-image :src="item.imageUrl" size="75" />
                                    </div>
                                    <div class="des_area">
                                        <div class="name">{{item.productNm}}</div>
                                        <div class="composition">
                                            <span>{{item.addInfo1}}
                                                <!-- <em></em> -->
                                            </span>
                                            <span>{{item.addInfo2}}
                                                <!-- <em></em> -->
                                            </span>
                                        </div>
                                        <div class="composition" v-if="item.addInfo1 == null">
                                            <span><pre> </pre></span>
                                        </div>
                                        <div class="option">
                                            <div class="count_area">
                                                <input type="text" :value="item.quantity+' (' + unitDefine[item.productType].name + ')'" readonly>
                                            </div>
                                            <div class="sum_area">
                                                <strong>{{item.price * item.quantity | makeComma}}</strong>
                                                <span>원</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    <!-- 주문완료 리스트 -->
                
                    <!-- 배송 정보 -->
                    <div class="info">
                        <h2 class="sub_tit">배송 정보</h2>
                        <table class="table_info">
                            <tbody>
                                <tr>
                                    <th>소매점주</th>
                                    <td>{{name}}</td>
                                </tr>
                                <tr>
                                    <th>전화번호</th>
                                    <td>{{phone}}</td>
                                </tr>
                                <tr class="add">
                                    <th>주소</th>
                                    <td>
                                        [{{addressZipCode}}] {{address}}
                                    </td>
                                </tr>
                                <tr>
                                    <th>배송일자</th>
                                    <td>{{deliveryDate}} {{$root.dayKor(deliveryDate)}} 예정</td>
                                </tr>
                                <tr>
                                    <th>요청사항</th>
                                    <td>{{requests}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- //배송 정보 -->
                </div>
            <!-- //배송 정보 -->
            </div>
            <!--  - 버튼 -->
            <div class="total_button">
                <div class="combo_form">
                    <a class="btn_blk bigger" @click="goTo('/main')">홈으로 가기</a>
                    <a class="link_button" @click="goTo('/orderHistory')">주문현황으로가기</a>
                </div>
            </div>
        </div>
        <!-- orderEndLists 주문완료 -->
        
        <!-- 재주문 확인 팝업 -->
        <div class="layerdim_wrap" v-bind:style="popupReOrder">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">재주문</h2> -->
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>이미 장바구니에 <br>
                                담긴 상품이 있습니다. <br>
                                장바구니에 같이 담으시겠습니까?
                            </p>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht" @click="popupClose()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="newCart(false)">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        <!-- 주문취소 팝업 -->
        <div class="layerdim_wrap" v-bind:style="popupCancel">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">주문취소</h2> -->
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>{{orderDate}} 주문을 <br> 취소 하시겠습니까?</p>
                            
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht"  @click="popupClose()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="cancelOrder()">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 주문수정 팝업 -->
        <div class="layerdim_wrap" v-bind:style="popupUpdate">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">주문수정</h2> -->
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>이미 장바구니에 <br>
                                담긴 상품이 있습니다. <br>
                                장바구니에 같이 담으시겠습니까?
                            <br>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht" @click="popupClose()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="newCart(false)">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        <div class="layerdim_wrap" v-bind:style="popUpDeadLine">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">주문마감</h2> -->
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>배송 하루 전일 오전 11시 이후부터는<br> 주문 수정 / (결제) 취소가 불가합니다.</p>
                            <div class="btn_center">
                                <a class="btn_wht" @click="popupClose()">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
        <!-- popup -->
        <!-- 환불confirm 팝업 -->
        <div class="layerdim_wrap" v-bind:style="popupRefundConfirm">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">환불요청</h2> -->
                            <a class="btn_close" @click="popupClose()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>{{orderDate}} 주문을 <br> 환불 요청 하시겠습니까?</p>
                            
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht"  @click="popupClose()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="openRefund()">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //환불confirm 팝업 -->
        
        <!-- 환불 성공 후, 팝업 -->
        <div class="layerdim_wrap" v-bind:style="popupAfterRefund">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <h2 class="ly_title">{{popupMergeTxt}}</h2>
                            <a class="btn_close" @click="popupCloseAndReload()">닫기</a>
                        </div>
                        <div class="layer_body" style="text-align: center;">
                            <p>{{popupMergeTxt2}} 정상적으로<br>처리되었습니다. <br>
                            </p>
                            <div class="btn_center">
                                <div>
                                    <a class="btn_wht btn_wdh_full" style="min-width: 200px;"  @click="popupCloseAndReload()">확인</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //환불 성공 후, 팝업 -->
            
        <div class="layerdim_wrap" v-show="isLoading">
            <div class="dim_inner">
                <div class="loading_api">
                    <div></div>
                </div>
            </div>
        </div>  
    </div>
</template>
    
<script>
// import axios from "axios";
import $ from 'jquery'
import Code from '@/api/Code'
import Order from '@/api/Order'
import Cart from '@/api/Cart'
import Customer from '@/api/Customer'
import Payment from '@/api/Payment'
import datepicker from '@/components/common/datepicker'
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment'
import VueImage from '@/components/common/vue-image';

export default {
    //name:'OrderHistory',
    components: {
        datepicker,
        InfiniteLoading,
        VueImage
    },
    data: () => {
        return {
            orderMstSeq: '',
            orderHistoryLists: [],
            orderEndLists : [],
            orderDetails : [],
            cartList : [],
            date1 : "",                 //선택된 날짜 (1주일/1개월/3개월/6개월)
            date2 : "",                 //오늘날짜
            popupComplete: { display: "none" },             //주문완료 화면
            // popupDetail : { display: "none" },   //주문상세 화면
            popupCancel : { display: "none" },  //주문취소 팝업
            popupReOrder: { display: "none" },  //재주문확인 팝업(장바구니에 상품이 있을 시)
            popupUpdate: { display: "none" },   //주문수정 팝업(장바구니에 상품이 있을 시)
            // conten:{ display: "none" },
            popUpDeadLine : { display: "none" },    //배송전날 마감시간 -30분전 표시
            popupRefund: { display: "none" }, // 환불 화면
            popupRefundConfirm: { display: "none" }, // 환불 화면 이전 confirm창
            popupAfterRefund: { display: "none" }, // 환불 완료 후, 주문 병합confirm창
            name : "",              //소매점주명
            store : "",             //소매점명
            phone :"",              //전화번호
            address : "",           //주소
            addressZipCode : "",    //우편번호
            deliveryDate : "",      //배송요일
            totalAmount : "",       //총금액
            totalQuantity : "",     //총수량
            // sell : 0,               //price * qty
            requests: "",           //요청사항

            detailOrderMstSeq : "", //주문번호
            orderDate : "",         //주문일자

            amount : 0,         //주문현황 날짜별 총금액
            quantity : 0,       //주문현황 날짜별 총수량

            orderVO : [],       //취소 할 주문
            customerVO : [],    //로그인한 고객 정보

            cart : {},          //재주문할 cart 데이터 담기
            isOrdered : true,
            // status : '',

            orderDeadLine : "", //주문마감시간
            nowDate : '',       //오늘날짜(일)
            getDate : '',       //배송날짜(일)
            moment : '',        
            nowTime : 0,        //현재시간

            startNo: 1,         //페이징 start (기본 20개씩)
            infiniteId: +new Date(),
            curDate : '',       //오늘날짜(0000-00-00)
            goRouteName: '',
            cutOffDays : 2,             //주문마감일수(n일전마감)
            cutOffDate : null,          //주문마감일
            cutOffTimeKor: '',          // 결제마감시간(한글) ex) 오후1시
            dlvDate : null,             //배송일  
            DeliveryDayKor: '',   
            DeliveryDayKorShort:'', 
            cutOffDayKor:'',
            
            bankName: '',
            account: '',
            payType: '',
            orderStatus: '',
            paymentAmt:'',

            dashCutoffDate:'',
            planInfo:'',

            bankList: [],
            pickBank: '',
            accountNo:'',
            accountName:'',

            popupMergeTxt:'',
            popupMergeTxt2:'',

            refundActive:false,
            orderData:'',
            isLoading: false,

            customerLevel2:'',
            accountHolder:'',

            unitDefine: {}
        }
    },

    beforeRouteLeave(to, from, next) {
        if(this.goRouteName == 'updateCart' || to.name == 'Footer'){
            next()
        }else{
            next(false)
        }
    },
    

        name : 'OrderHistory',

    async created() {
        // 제품타입 코드 조회 (단위명, 단위수량)
        this.unitDefine = await this.$root.getUnitDefine();
        // 목록 로딩 시 quantityListData에 값이 저장되지 않아 반복적으로 API를 호출하므로 최초에 1회 로딩
        await this.$root.totalQuantityAmount();

        // 주문마감시간 가져오기
        Code.getCode('C00002', 'C01')
            .then(res => {
                var deadline = res.data.refValue1.split(':')
                // this.orderDeadLineDisplay = res.data.refValue1; 
                this.orderDeadLine = parseInt(deadline[0]+deadline[1]); //ex)1300
                // console.log('주문 마감시간 : ',this.orderDeadLine)
            });

        //로그인한 고객정보
        //console.log('this.$store.state.customer.deliveryDay', this.$store.state.customer.deliveryDay);
        switch(this.$store.state.customer.deliveryDay) {
            case 'MON':
                this.deliveryNum = 1;
                break;
            case 'TUE':
                this.deliveryNum = 2;
                break;
            case 'WED':
                this.deliveryNum = 3;
                break;
            case 'THU':
                this.deliveryNum = 4;
                break;
            case 'FRI':
                this.deliveryNum = 5;
                break;
        }
        // 입금주명 설정
        this.customerLevel2 = this.$store.state.customer.level2; 
        switch (this.customerLevel2) {
            case "R1":
                this.accountHolder = "영-온라인결제";
                break;
            case "R2":
                this.accountHolder = "삼양-온라인결제";
                break;
            case "R3":
                this.accountHolder = "한미-온라인결제";
                break;
            default:
                this.accountHolder = "DTE DigitalPMK";
                break;
        }
        Customer.getDetail()
        .then((response) => {
            this.customerVO = response.data
            // console.log(this.customerVO)
            this.name = this.customerVO.HostName__c
            this.store = this.customerVO.Name;
            this.phone = this.customerVO.MainPhoneNo__c
            this.address = this.customerVO.Address__c
            this.addressZipCode = this.customerVO.AddressZipcode__c
        })
        .catch((e) => {
        console.log("error : ", e);
        });

        // 배송계획 조회
        Customer.getPlan()
        .then((response) => {
            let plan = response.data;
            this.planInfo = plan;
            //예상배송일
            this.EdeliveryDate = plan.dlvDate;
            this.dashCutoffDate = plan.cutOffDate;
            this.cutOffDate = parseInt(plan.cutOffDate.replace(/-/g, ''));
            this.cutOffDayKor = this.$root.dayKor(this.planInfo.cutOffDate);
            this.cutOffTimeKor = this.$root.timeKor(this.planInfo.cutOffTime);
            //console.log('주문마감일', this.cutOffDate);
            //console.log('배송예정일', this.dlvDate);

            this.nowTime = parseInt(moment(new Date()).format('HHmm'));
        })
        .catch((e) => {
        console.log("error : ", e);
        });        

        Payment.getBankList({isVc:''}).then((res) => {
            this.bankList = res.data;
        })
       
        this.today(true)
        //date2 값 구하기(오늘날짜)


        // console.log('날짜: ',this.date1,this.date2)
       
        //주문현황 list
        

        // 장바구니 개수 저장
        Cart.getCart()
            .then(res => {
                this.$store.commit('cart', res.data);
            });

        // console.log('params', this.$route.params.orderMstSeq);
        // console.log('query', this.$route.query.orderMstSeq);

        if(this.$route.query.orderMstSeq){
            this.orderMstSeq = this.$route.query.orderMstSeq;
        } else if(this.$route.params.orderMstSeq) {
            this.orderMstSeq = this.$route.params.orderMstSeq;
        }
        console.log('this.orderMstSeq', this.orderMstSeq)

        //주문완료 popUp
        if(this.orderMstSeq){
            // 주문 미완료 시 알림 종료
            this.$store.commit('isOrderPage', false);
            this.$store.commit('orderUpdate', null);
            this.popupComplete.display="";
            this.$route.meta.title = "주문완료";
            console.log('주문완료this.$route.meta.title: '+this.$route.meta.title)
            // axios.
            // get("http://localhost:8080/api/v1/order/"+this.orderMstSeq)
            console.log('주문완료 팝업-this.orderMstSeq: '+this.orderMstSeq)
            Order.getOrderDetail(this.orderMstSeq)
            .then(async (response) => {
                this.orderEndLists = response.data;
                console.log('주문완료 orderEndLists : ',this.orderEndLists)
                // this.orderMstSeq = this.orderEndLists[0].orderMstSeqccc
                
                this.requests=this.orderEndLists[0].requests
                var amount=0
                var add = 0;
                for(var i=0 ; i<this.orderEndLists.length ; i++) {
                    amount += parseInt(this.orderEndLists[i].price * this.orderEndLists[i].quantity);
                    add += parseInt(this.orderEndLists[i].quantity)
                }
                this.totalAmount=amount
                this.totalQuantity = add
                
                this.deliveryDate = this.orderEndLists[0].deliveryDt;
                this.DeliveryDayKor = this.$root.dayKor(this.deliveryDate);
                this.bankName = this.orderEndLists[0].bankName;
                this.account = this.orderEndLists[0].account;
                this.payType = this.orderEndLists[0].payType;
                this.orderStatus = this.orderEndLists[0].stauts;
                this.paymentAmt = parseInt(this.orderEndLists[0].paymentAmt);

                let total = await this.$root.totalQuantityAmount(this.orderEndLists);
                $('#completeQuantity').text(total.quantityString);
            })
            .catch((e) => {
                console.log("error : ", e);
            });

        }
   },
   computed:{
       depositDt(){
           var deliveryDate = new Date(this.deliveryDate);
           var dt = new Date(deliveryDate-1);
           return dt;
       },
   },

    methods:{
        async setTotal(list, domId) {
            let result;
            result = await this.$root.totalQuantityAmount(list);
            //console.log('getTotal', result);
            $('#' + domId).html(result.quantityHtml);
            return result;
        },
        allCheck : function(){
            if($('input[id=check01]:checkbox').is(":checked")){
               $("input[id=check02]:checkbox").prop("checked", true);
               $("input[id=check03]:checkbox").prop("checked", true);
               $("input[id=check04]:checkbox").prop("checked", true);
                //전체 ischecked true
                this.refundActive = true;
                // this.refundActiveStyle.backgroundColor = '#e31a33';
                this.updateIsckecked(true)  


            }else{

               $("input[id=check02]:checkbox").prop("checked", false);
               $("input[id=check03]:checkbox").prop("checked", false);
               $("input[id=check04]:checkbox").prop("checked", false);

                //전체 ischecked false
                this.refundActive = false;
                this.updateIsckecked(false)  
            }
            
        },

         selectBox : function(checkBoxList){
            //약관선택 비교 전체선택 on | off
            if($("input:checkbox[class=check]:checked").length == 3){
                $('input[id=check01]:checkbox').prop("checked", true);
                this.refundActive = true;
            }else{
                $('input[id=check01]:checkbox').prop("checked", false);
                this.refundActive = false;
            }

            if($("input[id="+checkBoxList+"]:checkbox").is(":checked")){
                //선택된 상품 ischecked true
                this.updateIsckecked(true,checkBoxList)  

            }else{
                //선택된 상품 ischecked false
                this.updateIsckecked(false,checkBoxList)  

            }


        },
        fnCopy(text){
            navigator.clipboard.writeText(text)
                .then(res=>{
                    alert('계좌번호가 복사되었습니다.');

                })
        },

        popRefundup(ordered){ // 환불 confirm창 띄우기
            this.popupRefundConfirm.display = '';
            this.orderDate = ordered.registDt
            this.orderData = ordered
        },
        openRefund(){ // 환불페이지 오픈
            // this.popupRefundConfirm.display = 'none'
            // let _this = this;
            // this.popupRefund.display = '';
            console.log(JSON.stringify(this.orderData))
            // this.$router.push({path: '/refund'}).catch(() => {});
            
            this.$store.commit('specialHeader', 'refund');
            this.$store.commit('refundData', {eorderNumber: this.orderData.eorderNumber, orderDate: this.orderDate, list: this.orderData.list});
            setTimeout(function() {
                location.href = '/refund';
            }, 200);
            
        },
        evtRefund(){
            // this.popupRefund.display = 'none';
            this.popupMergeTxt = '환불요청'
            this.popupMergeTxt2 = '환불요청이'
            this.popupAfterRefund.display = '';
        },

        infiniteHandler($state) {
            if (!this.date1) {
                this.date1 = moment().subtract(7, 'd').format('YYYY-MM-DD')
                this.date2 = moment().format('YYYY-MM-DD')
                //console.log('검색 기본날짜 -7일 : ',this.date1)
            }

            var params = {
                'startDt':this.date1,
                'endDt':this.date2,
                'page.startNo': this.startNo
            }
            Order.getOrderHistory(params)
            .then(({data}) => {
                setTimeout(() => {
                if (data.length) {
                    this.startNo += 20;
                    this.orderHistoryLists = this.orderHistoryLists.concat(data);
                    //console.log('주문현황 ',this.orderHistoryLists)
                    //console.log('orderHistory 예상배송요일 계산 값 : ',this.getDate, this.EdeliveryDate) 

                    //날짜별 총금액, 총수량
                    for(var index in this.orderHistoryLists){
                        this.amount = 0
                        this.quantity = 0

                        for(var index1 in this.orderHistoryLists[index].list){
                            this.amount += parseInt(this.orderHistoryLists[index].list[index1].price * this.orderHistoryLists[index].list[index1].quantity)
                            this.quantity += parseInt(this.orderHistoryLists[index].list[index1].quantity)
                        }

                        this.orderHistoryLists[index].amount = this.amount
                        this.orderHistoryLists[index].orderQty = this.quantity

                        this.setTotal(this.orderHistoryLists[index].list, 'quantity_' + this.orderHistoryLists[index].eorderNumber);
                    }

                    //console.log("after", this.orderHistoryLists.length, this.startNo);
                    $state.loaded();
                } else {
                    $state.complete();
                }
                }, 100)
            });
        },


        //주문상세 popUp
        // showOrderDetail (seq) {
        //     this.popupDetail.display="";
        //     Order.getOrderDetail(seq)
        //     .then((response) => {
        //         this.orderDetails = response.data;
        //         console.log(this.orderDetails)
        //         this.detailOrderMstSeq = this.orderDetails[0].orderMstSeq;
        //         this.orderDate = (this.orderDetails[0].registDttm).substring(0,10); 
        //     })
        //     .catch((e) => {
        //         console.log("error : ", e);
        //     });
        // },
        
        //날짜로 주문현황 검색
        clickDate : function (date){

            var now = new Date();
            var year = '';
            var month = '';
            var day = '';

            //1주일
            if(date == 'week'){
                var oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
                year = oneWeekAgo.getFullYear(); 
                month = new String(oneWeekAgo.getMonth()+1); 
                day = new String(oneWeekAgo.getDate()); 
                if(month.length == 1){   month = "0" + month; } 
                if(day.length == 1){ day = "0" + day; } 
                
                this.date1=year + "-" + month + "-" + day;

            //1개월 3개월 6개월
            }else{
                var oneMonthAgo = new Date(now.setMonth(now.getMonth() - (date*=1)));
                year = oneMonthAgo.getFullYear(); 
                month = new String(oneMonthAgo.getMonth()+1); 
                day = new String(oneMonthAgo.getDate()); 
                if(month.length == 1){   month = "0" + month; } 
                if(day.length == 1){ day = "0" + day; } 
                
                this.date1=year + "-" + month + "-" + day;
            }

            this.today(true)
            //date2 값 구하기(오늘날짜)
            // now = new Date(); 
            // year = now.getFullYear(); 
            // month = new String(now.getMonth()+1); 
            // day = new String(now.getDate()); 
            // if(month.length == 1){   month = "0" + month; } 
            // if(day.length == 1){ day = "0" + day; } 

            // this.date2=year + "-" + month + "-" + day;

            //선택된 버튼 css
            $('.button_sch').removeClass("on");
            $('#'+date).addClass("on");

            this.startNo = 1;
            this.orderHistoryLists = [];
            this.infiniteId += 1;
            this.startDt = this.date1;
            this.endDt = this.date2;

        },
     
        // 선택날짜 조회
        clickSearchList (date1,date2){

            this.startNo = 1;
            this.orderHistoryLists = [];
            this.infiniteId += 1;
            this.startDt = date1;
            this.endDt = date2;
        },

        //주문취소 팝업
        orderCancelPopUp : function(ordered){
            this.orderVO = ordered
            this.today(false)
            /*
            console.log('ordered.statusCode', ordered.statusCode)
            console.log('nowDateNum', nowDateNum)
            console.log('this.cutOffDate', this.cutOffDate)
            console.log('this.orderVO.deliveryDt', this.orderVO.deliveryDt)
            console.log('this.EdeliveryDate', this.EdeliveryDate)
            console.log('this.nowTime', this.nowTime)
            console.log('this.orderDeadLine', this.orderDeadLine)
            */
            //console.log('', )

            /*
            if(this.deliveryNum == 1){ // 해당 소매점 배송요일이 월요일이면
                // 입금대기 상태가 아니고, 오늘이 마감일이고 지금시간이 마감시간을 지났거나 같고 주문정보의 배송일과 현재 배송예정일이 다르면
                if((nowDateNum == this.cutOffDate && this.nowTime >= this.orderDeadLine) && this.orderVO.deliveryDt != this.EdeliveryDate){
                    this.popUpDeadLine.display=""
                    return
                // 주문정보의 배송일보다 현재 배송예정일이 더 이후 날짜이거나, 배송예정일이 없으면
                }else if(((this.orderVO.deliveryDt < this.EdeliveryDate) || !this.EdeliveryDate)){
                    this.popUpDeadLine.display=""
                    return
                // 주문정보의 배송일이 오늘이거나, 오늘 이전이면
                }else if((this.curDate >= this.orderVO.deliveryDt) ){
                    this.popUpDeadLine.display=""
                    return
                }else {
                    this.popupCancel.display=""
                    this.orderDate = ordered.registDt
                }
            }else{ // 오늘이 마감일이고, 주문정보의 배송일과 현재 배송예정일이 다르고, 지금시간이 마감시간을 지났거나 같으면
                if(nowDateNum == this.cutOffDate && this.orderVO.deliveryDt != this.EdeliveryDate && this.nowTime >= this.orderDeadLine ){
                    this.popUpDeadLine.display=""
                    return
                // 주문정보의 배송일보다 현재 배송예정일이 더 이후 날짜이거나, 배송예정일이 없으면
                }else if(((this.orderVO.deliveryDt < this.EdeliveryDate) || !this.EdeliveryDate)){
                    this.popUpDeadLine.display=""
                    return
                // 주문정보의 배송일이 오늘이거나, 오늘 이전이면
                }else if(this.curDate >= this.orderVO.deliveryDt){
                    this.popUpDeadLine.display=""
                    return
                }else {
                    this.popupCancel.display=""
                    this.orderDate = ordered.registDt
                }
            }
            */
            
            if (ordered.isActive == 'Y') {
                this.popupCancel.display = ""
                this.orderDate = ordered.registDt;
            } else {
                this.popUpDeadLine.display = ""
            }
            
        },

        // 환불요청 마감체크 팝업
        refundDeadLineCheck(ordered){
            this.orderVO = ordered
            /*
            if(this.deliveryNum == 1){ // 해당 소매점 배송요일이 월요일이면
                // 입금대기 상태가 아니고, 오늘이 마감일이고 지금시간이 마감시간을 지났거나 같고 주문정보의 배송일과 현재 배송예정일이 다르면
                if(ordered.statusCode != 'Waiting' && (nowDateNum == this.cutOffDate && this.nowTime >= this.orderDeadLine) && this.orderVO.deliveryDt != this.EdeliveryDate){
                    this.popUpDeadLine.display=""
                    return
                // 주문정보의 배송일보다 현재 배송예정일이 더 이후 날짜이거나, 배송예정일이 없으면
                }else if(ordered.statusCode != 'Waiting' && ((this.orderVO.deliveryDt < this.EdeliveryDate) || !this.EdeliveryDate)){
                    this.popUpDeadLine.display=""
                    return
                // 주문정보의 배송일이 오늘이거나, 오늘 이전이면
                }else if(ordered.statusCode != 'Waiting' && (this.curDate >= this.orderVO.deliveryDt) ){
                    this.popUpDeadLine.display=""
                    return
                }else {
                    this.popRefundup(ordered);
                }
            }else{ // 오늘이 마감일이고, 주문정보의 배송일과 현재 배송예정일이 다르고, 지금시간이 마감시간을 지났거나 같으면
                console.log('--------this.nowTime:  ', this.nowTime);
                console.log('---------this.orderDeadLine:  ',this.orderDeadLine);
                // if(ordered.statusCode != 'Waiting' && nowDateNum == this.cutOffDate && this.orderVO.deliveryDt != this.EdeliveryDate && this.nowTime >= this.orderDeadLine ){
                if(ordered.statusCode != 'Waiting' && nowDateNum == this.cutOffDate && this.orderVO.deliveryDt == this.EdeliveryDate && this.nowTime >= this.orderDeadLine ){
                    this.popUpDeadLine.display=""
            console.log('ssyyhh 2')

                    return
                // 주문정보의 배송일보다 현재 배송예정일이 더 이후 날짜이거나, 배송예정일이 없으면
                }else if(ordered.statusCode != 'Waiting' && ((this.orderVO.deliveryDt < this.EdeliveryDate) || !this.EdeliveryDate)){
                    this.popUpDeadLine.display=""
            console.log('ssyyhh 3')

                    return
                // 주문정보의 배송일이 오늘이거나, 오늘 이전이면
                }else if(ordered.statusCode != 'Waiting' && this.curDate >= this.orderVO.deliveryDt){
                    this.popUpDeadLine.display=""
            console.log('ssyyhh 4')

                    return
                }else {
            console.log('ssyyhh 5')

                    this.popRefundup(ordered);
                }
            }
            */

            if (ordered.isActive == 'Y') {
                this.popRefundup(ordered);
            } else {
                this.popUpDeadLine.display = ""
            }
        },

        //취소주문 update
        cancelOrder : function(){
            this.isLoading = true;
            
            // sessionStorage.removeItem('orderUpdate');
            this.$store.commit('orderUpdate', null);
            // console.log('주문취소 this.$store.state.orderUpdate : ',this.$store.state.orderUpdate)

            this.popupCancel.display="none"

            let params = JSON.parse(JSON.stringify(this.orderVO));

            params.statusCode = 'Canceled'
            params.dcVO = {
                codeDetail: this.orderVO.dcCode,
                amount: this.orderVO.dcAmt,
                customerId: this.customerVO.Id
            }
            

            if (params.payType == 'EZ') { 
                params.statusCode = 'RefundConfirmed'
                Payment.cancelEZ(params)
                .then((response) => {
                    this.isLoading = false;
                    if(response.data == 'success' || response == 'success'){
                        
                        console.log(response)
                        console.log(response.data)
                        // window.location.reload()
                        this.popupMergeTxt = '주문취소'
                        this.popupMergeTxt2 = '주문취소가'
                        this.popupAfterRefund.display = ''
                        
                    }else{
                        this.$dialog('주문 취소 처리에 실패했습니다.');
                        console.log('order update failed!')
                    }
                })
                .catch((e) => {
                    let message = e.response?.data?.message;
                    console.log('error message', message);
                    if ('Expired' == message) {
                        this.$dialog('마감시간이 지나 취소할 수 없습니다.');
                    } else {
                        this.$dialog('주문 취소 처리에 실패했습니다.');
                    }
                    this.isLoading = false;
                    console.log("error : ", e);
                });
            } else {
                Order.updateOrder(params)
                .then((response) => {
                    if(response.data == 'success'){
                        // window.location.reload()
                        this.popupMergeTxt = '주문취소'
                        this.popupMergeTxt2 = '주문취소가'
                        this.popupAfterRefund.display = ''
                        // console.log('주문취소 성공')
                        this.clickSearchList(this.startDt, this.endDt);
                    }else{
                        console.log('order update failed!')
                    }
                })
                .catch((e) => {
                    let message = e.response?.data?.message;
                    console.log('error message', message);
                    if ('Expired' == message) {
                        this.$dialog('마감시간이 지나 취소할 수 없습니다.');
                    } else if ('Impossible' == message) {
                        this.$dialog('취소할 수 없는 상태입니다.');
                    } else if ('Refund' == message) {
                        //this.popRefundup(this.orderVO);
                        this.orderDate = this.orderVO.registDt;
                        this.orderData = this.orderVO;
                        this.openRefund();
                   }
                })
                .finally(() => {
                    this.isLoading = false;
                });
            }
            
            
            
        },

        //재주문 팝업
        reOrderPopUp : function(ordered){
            this.orderVO = ordered;

            Cart.getCart()
            .then((response) => {
                this.cartList = response.data;

                //장바구니에 상품있으면 팝업 > 병합 and 삭제 후 생성
                if(this.cartList.length > 0){
                    this.popupReOrder.display="";
                    
                //장바구니에 상품이 없으면 바로 장바구니 insert   
                }else{

                    // sessionStorage.removeItem('orderUpdate');
                    this.$store.commit('orderUpdate', null);
                    // console.log('재주문 장바구니 없을 시 this.$store.state.orderUpdate : ',this.$store.state.orderUpdate)

                    for(var idx in this.orderVO.list){
                        this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].quantity};     
                        this.cartList.push(this.cart)
                    }
                    
                    // console.log('장바구니 담긴 상품',this.cartLists)
                    const json = JSON.stringify(this.cartList);

                    Cart.insertCart(json)
                    .then((response) => {
                        if(1 == response.data){
                            this.goRouteName = 'updateCart';
                            this.$router.push({name:'Cart'})
                        }else{
                            console.log("재주문하기 장바구니 상품 없을시 insert error!")
                        }
                    })
                    .catch((e) => {
                    console.log("error : ", e);
                    });
                }

            })
            .catch((e) => {
            console.log("error : ", e);
            });
            
            
        },

        //재주문(삭제후 주문하기) > 담긴 cart delete > 재주문 cart insert
        // reOrder : function(){

        //     sessionStorage.removeItem('orderUpdate');

        //     this.popupReOrder.display="none";
        //     var params = [{'customerCode':this.customerVO.CustomerCode__c}];

        //     Cart.deleteCart(params)
        //     .then((response) => {
        //         var result = response.data;

        //         //cart delete 성공
        //         if(result > 0){
        //             this.cart={};
        //             this.cartList = [];
        //             for(var idx in this.orderVO.list){
        //                 this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].quantity};     
        //                 this.cartList.push(this.cart)
        //             }
                    
        //             const json = JSON.stringify(this.cartList);
                        
        //             //cart insert
        //             Cart.insertCart(json)
        //             .then((response) => {
        //                 if(1 == response.data){
        //                     this.$router.push({name:'Cart'})
        //                 }else{
        //                     console.log("재주문하기 장바구니 상품 있을시 insert error!")
        //                 }
        //             })
        //             .catch((e) => {
        //             console.log("error : ", e);
        //             });


        //         }else{
        //             console.log('장바구니 삭제 에러!')
        //         }

        //     })
        //     .catch((e) => {
        //     console.log("error : ", e);
        //     });
        // },

        //주문수정 팝업
        orderUpdatePopUp : function(ordered){ // 주문수정 불가한 경우 팝업 오픈
            this.orderVO = ordered
            // console.log(parseInt(this.moment(this.getDate).subtract(2, 'd').format('DD')), parseInt(this.moment(this.getDate).subtract(1, 'd').format('DD')), this.nowDate)
            //console.log(nowDateNum, this.cutOffDate, this.nowTime, this.orderDeadLine, this.orderVO.deliveryDt, this.EdeliveryDate);
            //console.log(this.curDate, this.orderVO.deliveryDt)
            //console.log("orderVO:  "+this.orderVO+", this.today:"+this.today+", this.deliveryNum:"+this.deliveryNum+", this.cutOffDate:"+this.cutOffDate+", this.orderDeadLine:"+this.orderDeadLine+", this.orderVO.deliveryDt:"+this.orderVO.deliveryDt+", this.EdeliveryDate:"+this.EdeliveryDate);

            /*
            if(this.deliveryNum == 1){ // 해당 소매점 배송요일이 월요일이면
                if((nowDateNum == this.cutOffDate && this.nowTime >= this.orderDeadLine) && this.orderVO.deliveryDt != this.EdeliveryDate){
                    this.popUpDeadLine.display=""
                    // alert("1");
                    return

                }else if((this.orderVO.deliveryDt < this.EdeliveryDate) || !this.EdeliveryDate){
                    this.popUpDeadLine.display=""
                    // alert("2");
                    return

                }else if(this.curDate >= this.orderVO.deliveryDt){
                    this.popUpDeadLine.display=""
                    // alert("3");
                    return
                }
            }else{ // 해당 소매점 배송요일이 월요일이 아니면
                //배송전날 마감시간 이후 수정 안됨.
                if(nowDateNum == this.cutOffDate && this.orderVO.deliveryDt != this.EdeliveryDate && this.nowTime >= this.orderDeadLine ){
                    this.popUpDeadLine.display=""
                    // alert("4");
                    return
                }else if((this.orderVO.deliveryDt < this.EdeliveryDate) || !this.EdeliveryDate){
                    this.popUpDeadLine.display=""
                    // alert("5");
                    return                    
                }else if(this.curDate >= this.orderVO.deliveryDt){
                    this.popUpDeadLine.display=""
                    // alert("6");
                    return
                }
            }
            */
            
            if (ordered.isActive != 'Y') {
                this.popUpDeadLine.display = ""
                return;
            }

            Cart.getCart()
            .then((response) => {
                //  alert("Cart.getCart().then((response)--7");
                this.cartList = response.data;
                console.log(this.cartList)
                
                //장바구니에 상품있으면 팝업 후 1.병합하기(추가하기) > cart병합 / 2.새로생성 > cart삭제
                if(this.cartList.length > 0){
                    this.popupUpdate.display="";
                    // alert("8, cartlist.length"+this.cartList.length);
                //장바구니에 상품이 없으면 바로 장바구니 insert   
                }else{
                    // alert("9");
                    // sessionStorage.removeItem('orderUpdate');
                    // sessionStorage.setItem('orderUpdate', true);
                    this.$store.commit('orderUpdate', true); //주문수정
                    console.log('주문취소 장바구니 상품 없음1 this.$store.state.orderUpdate : ',this.$store.state.orderUpdate)
                    
                     console.log('9'+this.orderVO)
                    for(var idx in this.orderVO.list){
                        this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].quantity};     
                        this.cartList.push(this.cart)
                    }
                    
                    const json = JSON.stringify(this.cartList);
                    console.log('10'+json)

                    Cart.insertCart(json)
                    .then((response) => {
                        if(1 == response.data){
                            this.goRouteName = 'updateCart';
                            this.$router.push({name:'Cart'})
                        }else{
                            console.log("주문수정 장바구니 상품 없을시 insert error!")
                        }
                    })
                    .catch((e) => {
                    console.log("error : ", e);
                    });
                }

            })
            .catch((e) => {
            console.log("error : ", e);
            });
        },

        //주문수정 / 재주문하기 > 팝업 병합하기(추가하기)
        mergeCart : function(checked){ //checked말고 숫자로 상태주는걸로 수정해야함. true:주문수정,false:재주문, cancel:주문취소
            this.$store.commit('orderUpdate', null); // 주문수정 상태 아님.
            this.popupUpdate.display="none"; // 주문수정창 오픈
            this.cart={};
            this.cartList = [];

            if (checked == true) {
                this.$store.commit('orderUpdate', true);
            }

            for(var idx in this.orderVO.list){
                this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].quantity,'clickValue': 'cartMerge'};     
                this.cartList.push(this.cart)
            }

            // console.log('주문수정 cartList : ',this.cartList)
            const json = JSON.stringify(this.cartList);
            console.log('병합2 : ',json)

            //cart merge
            Cart.updateCart(json)
            .then((response) => {
                console.log('병합3 : ',response)
                
                if(1 == response.data){
                    console.log('병합4 : ',response.data)
                    this.goRouteName = 'updateCart';
                    this.$router.push({name:'Cart'})
                }else{
                    console.log("재주문하기 장바구니 상품 있을시 insert error!")
                }
            })
            .catch((e) => {
            console.log("error : ", e);
            });

        },

        //주문수정,재주문하기 > 팝업 새로담기
        newCart(checked){
            this.$store.commit('orderUpdate', null);

            //장바구니 조회
            Cart.getCart() 
            .then((response) => {

                this.cartList = response.data;
                console.log('새로2 cartList: '+this.cartList)
                //장바구니에 상품있으면 삭제 후 담기
                if(this.cartList.length > 0){
                    console.log('새로3 this.cartList.length: '+this.cartList.length)
                    // this.popupReOrder.display="";
                    
                    this.popupReOrder.display="none";
                    var params = [{'customerCode':this.customerVO.CustomerCode__c}];
                    
                    Cart.deleteCart(params)
                    .then((response) => {
                    console.log('새로4 deleteCart: '+response.data)
                        
                        var result = response.data;

                        //cart delete 성공
                        if(result > 0){
                            this.cart={};
                            this.cartList = [];

                            if (checked == true) {//주문수정true
                                this.$store.commit('orderUpdate', true);
                            }

                            for(var idx in this.orderVO.list){
                                this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].quantity,'isOrdered':true};     
                                this.cartList.push(this.cart)
                            }
                            const json = JSON.stringify(this.cartList);
                            console.log('새로담기5 : ',json)

                            //cart insert
                            Cart.insertCart(json)
                            .then((response) => {
                                console.log('insertCart새로담기6 : ',response.data)

                                if(1 == response.data){
                                    this.goRouteName = 'updateCart';
                                    this.$router.push({name:'Cart'})
                                }else{
                                    console.log("새로7 재주문하기 장바구니 상품 있을시 insert error!")
                                }
                            })
                            .catch((e) => {
                            console.log("error : ", e);
                            });
                        }else{
                            console.log('장바구니 삭제 에러!')
                        }
                    })
                    .catch((e) => {
                    console.log("error : ", e);
                    });

                    
                
                }else{ //장바구니에 상품이 없으면 바로 장바구니 insert   

                    // sessionStorage.removeItem('orderUpdate');
                    this.$store.commit('orderUpdate', null);
                    console.log('새로8 재주문 장바구니 없을 시 this.$store.state.orderUpdate : ',this.$store.state.orderUpdate)
                    console.log('새로9 this.orderVO.list: '+this.orderVO.list)
                    for(var idx in this.orderVO.list){
                        this.cart = {'productCode' : this.orderVO.list[idx].productCode, 'quantity' : this.orderVO.list[idx].quantity};     
                        this.cartList.push(this.cart)
                    }
                    
                    // console.log('장바구니 담긴 상품',this.cartLists)
                    const json = JSON.stringify(this.cartList);
                    console.log('새로10 json: '+json)
                    
                    Cart.insertCart(json)
                    .then((response) => {
                    console.log('새로11 insertCart: '+response.data)

                        if(1 == response.data){
                            this.goRouteName = 'updateCart';
                            this.$router.push({name:'Cart'})
                        }else{
                            console.log("재주문하기 장바구니 상품 없을시 insert error!")
                        }
                    }) // E(api):Cart.insertCart
                    .catch((e) => {
                    console.log("error : ", e);
                    }); // E(api):Cart.insertCart
                }// E: 장바구니에 상품이 없으면 바로 장바구니 insert   

            }) // E(api):Cart.getCart
            .catch((e) => {
            console.log("error : ", e);
            }); // E(api):Cart.getCart
        },
        popupClose() { // 전체 팝업 닫기
            this.$store.commit('specialHeader', null);
            // this.popupDetail.display="none";
            this.popupComplete.display="none";
            this.$route.meta.title = "주문현황"; // 
            console.log('주문현황.$route.meta.title: '+this.$route.meta.title)
            this.popupCancel.display="none"
            this.popupReOrder.display="none"
            this.popupUpdate.display="none"
            this.popUpDeadLine.display="none"
            this.popupRefund.display="none"
            this.popupRefundConfirm.display="none";
            this.popupAfterRefund.display = "none";
        }, 
        popupCloseAndReload() { // 전체 팝업 닫기 & 주문현황으로 이동
            // this.popupDetail.display="none";
            this.popupComplete.display="none";
            this.$route.meta.title = "주문현황";
            console.log('주문현황.$route.meta.title: '+this.$route.meta.title)
            this.popupCancel.display="none"
            this.popupReOrder.display="none"
            this.popupUpdate.display="none"
            this.popUpDeadLine.display="none"
            this.popupRefund.display="none"
            this.popupRefundConfirm.display="none";
            this.popupAfterRefund.display = "none";
            location.href='/orderHistory'
        }, 

        toggleDetail : function(index) { // 주문상세 펼치기/접기
            let item = this.orderHistoryLists[index];
            // isShowDetail 값 토글
            item.isShowDetail = !item.isShowDetail;
            this.$set(this.orderHistoryLists, index, item);
        },
        
        today : function(checked){
            //date2 값 구하기(오늘날짜)
            this.date2 = moment().format('YYYY-MM-DD')
            this.curDate = moment().format('YYYY-MM-DD')
            // console.log('오늘 날짜 date2 : ',this.date2 ,', curDate : ',this.curDate)
            // var now = new Date(); 
            // var year = now.getFullYear(); 
            // var month = new String(now.getMonth()+1); 
            // var day = new String(now.getDate()); 
            // if(month.length == 1){   month = "0" + month; } 
            // if(day.length == 1){ day = "0" + day; } 
            // if(checked == true){
            //     this.date2=year + "-" + month + "-" + day;
            // }else if(checked == false){
            //     this.curDate = year + "-" + month + "-" + day;
            // }
        },
        goTo(path){
            this.$route.meta.title = "주문현황";
            console.log('this.$route.meta.title: '+this.$route.meta.title)
            location.href=path
            
        },

        

    },
        
        

   

}
</script>

<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
 



</style>