import Send from '@/utils/Send.js'

export default {
    sendOTP(params) {
        return Send({
            baseURL: process.env.VUE_APP_HEROKU_OAUTH_URL,
            url: '/otp',
            method: 'post',
            params: params
        })
    },
    checkOTP(params) {
        return Send({
            baseURL: process.env.VUE_APP_HEROKU_OAUTH_URL,
            url: '/otp',
            method: 'get',
            params: params
        })
    },
    getToken(params) {
        return Send({
            baseURL: process.env.VUE_APP_HEROKU_OAUTH_URL,
            url: '/token',
            method: 'get',
            params: params
        })        
    },
    refresh(params) {
        return Send({
            baseURL: process.env.VUE_APP_HEROKU_OAUTH_URL,
            url: '/refresh',
            method: 'get',
            params: params
        })        
    },

    insertSimple(params) {
        return Send({
            baseURL: process.env.VUE_APP_HEROKU_OAUTH_URL,
            url: '/simple',
            method: 'post',
            data: params
        })
    },

    simple(params) {
        return Send({
            baseURL: process.env.VUE_APP_HEROKU_OAUTH_URL,
            url: '/login',
            method: 'post',
            data: params
        })
    },
}