import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueConfirmDialog from 'vue-confirm-dialog'

import Code from '@/api/Code'
import Product from '@/api/Product'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
//import RootLayout from './layouts/RootLayout'
//import 'expose-loader?$!expose-loader?jQuery!jquery'

//Vue.component('RootLayout', RootLayout)

// 대화상자
import VueDialog from '@/components/common/vue-dialog'
Vue.use(VueDialog)


Vue.config.productionTip = false

//천단위 콤마
Vue.filter("makeComma", val =>{
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

new Vue({
    router,
    store,
    render: h => h(App),
    methods: {
        goTo(url) {
            if (this.$store.state.isOrderPage) {
                this.$dialog(
                    {
                        // title: '확인',
                        message: '아직 주문이 완료되지 않았습니다. \n그래도 이동하시겠습니까?',
                        button: {
                            yes: '예',
                            no: '아니요'
                        },
                        callback: confirm => {
                            console.log('root.goto confirm');
                            if (confirm) {
                                this.$store.commit('isOrderPage', false);
                                //location.href = url;
                                this.$router.push(url);
                            }
                        }
                    }
                );                
            } else {
                location.href = url;
            }
        },
        goToFooter(url,showPop) {
            if (this.$store.state.isOrderPage) {
                this.$dialog(
                    {
                        // title: '확인',
                        message: '아직 주문이 완료되지 않았습니다. \n그래도 이동하시겠습니까?',
                        button: {
                            yes: '예',
                            no: '아니요'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.$store.commit('isOrderPage', false);
                                this.$router.push({name: url ,params: { pop: showPop }})
                            }
                        }
                    }
                );                
            } else {
                this.$router.push({name: url ,params: { pop: showPop }})
            }
        },
        // 날짜를 한글로 변환
        dayKor(dashDate) { // dashDate: yyyy-MM-dd  
            let week = '';

            if (dashDate) {
                if (dashDate.length == 3) {
                    switch (dashDate) {
                        case 'MON':
                            week = '월요일';
                            break;
                        case 'TUE':
                            week = '화요일';
                            break;
                        case 'WED':
                            week = '수요일';
                            break;
                        case 'THU':
                            week = '목요일';
                            break;
                        case 'FRI':
                            week = '금요일';
                            break;
                                                
                        default:
                            break;
                    }
                } else {
                    let year = parseInt(dashDate.substring(0,4));
                    let month = parseInt(dashDate.substring(5,7));
                    let day = parseInt(dashDate.substring(8,10));
                    let date = new Date(year, month-1, day);
                    const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
                    week = WEEKDAY[date.getDay()];
                }
            }

            return week;
        },
        // 시간을 한글로 변환
        timeKor(time){ // time: HH24:MI
            let hour = parseInt(time.substring(0,2));
            let min = parseInt(time.substring(3,5));
            let kor = '';
            if(hour>=12){
                kor = '오후 '
                if(hour==12) kor = kor+hour+'시'
                else kor = kor+(hour-12)+'시'
            } else {
                kor = '오전 '
                if(hour==0) kor = kor+'12시'
                else kor = kor+hour+'시'
            }

            if(min>0) kor = kor+''+min+'분';
            
            return kor;
        },
        async getUnitDefine() {
            if (!this.unitDefineData) {
                await Code.getCodes('PRD_TYPE')
                    .then(res => {
                        console.log('getUnitDefine', res);

                        let data = {};

                        res.data.forEach(el => {
                            data[el.sfid] = {
                                name: el.refValue2,
                                quantity: el.refValue1 ? el.refValue1.split(',') : [1]
                            }
                        });
                        this.unitDefineData = data;
                        //console.log('unitDefine', this.unitDefine);
                    });
            }
            
            console.log('this.unitDefineData',this.unitDefineData);

            return this.unitDefineData;
        },
        async totalQuantityAmount(selected) {
            //console.log('this.unitDefineData at total',this.unitDefineData);
            let unitDefine = await this.getUnitDefine();
            
            //패키지 상세 수량 조회
            if (!this.quantityListData) {
                await Product.getPackageDetailQuantity()
                    .then(({data}) => {
                        console.log('getPackageDetailQuantity', data);
                        this.quantityListData = data;
                    });
            }
            let quantityList = this.quantityListData;

            // 초기 데이터 로딩을 위해 위까지는 그냥 통과
            if (!selected) {
                return;
            }
            
            //금액
            let quantity = 0;
            let amount = 0;

            //단위명별 수량 초기화
            let quantityByUnitName = {};
            let unitDefineKeys = Object.keys(unitDefine);
            unitDefineKeys.forEach(key => {
                quantityByUnitName[unitDefine[key].name] = 0;
            })

            let sum = function(sel, pack){
                if (pack) {
                    pack.forEach(p => {
                        quantityByUnitName[p.unitname] += p.quantity * sel.quantity
                    });
                } else {
                    //console.log('sel.productType',sel);
                    quantityByUnitName[unitDefine[sel.productType].name] += sel.quantity;
                    //console.log('unitDefine['+sel.productType+']', unitDefine[sel.productType]);
                }

                //quantity += sel.quantity;
                amount += sel.price * sel.quantity;
            }

            //console.log('quantityByUnitName', quantityByUnitName);
            //console.log('selected', selected);
            //console.log('quantity list', quantityList);

            if (selected.length) {
                // Array
                //console.log('is array');
                selected.forEach(item => {
                    sum(item, quantityList[item.productCode]);
                });
            } else {
                // Object
                let selectedKeys = Object.keys(selected);
                selectedKeys.forEach(key => {
                    sum(selected[key], quantityList[key]);
                });
            }

            let quantityString = '';
            let quantityHtml = '';
            let quantityByUnitNameKeys = Object.keys(quantityByUnitName);
            quantityByUnitNameKeys.forEach(key => {
                let el = quantityByUnitName[key];

                if (el > 0) {
                    quantityString += ' + ' + el + key;
                    quantityHtml += ' + <strong>' + el + '</strong>' + key;
                }
            });
            quantityString = quantityString.substring(3);
            quantityHtml = quantityHtml.substring(3);

            // 총 수량은 담배만 계산
            quantity = quantityByUnitName['갑'];
            
            console.log(quantityString, quantity, amount);

            return {
                'quantityString': quantityString,
                'quantityHtml': quantityHtml,
                'quantity': quantity,
                'amount': amount
            }
        }
    }
}).$mount('#app')
