import Send from '../utils/Send.js'

export default {
    getList(params) {
        return Send({
            url: '/survey',
            method: 'get',
            params: params
        })
    },
    getDetail(params) {
        return Send({
            url: '/survey/' + params.id,
            method: 'get'
        })
    }
}