<template>
    <div class="container">
        <div class="sub_container">
            <div class="ls_header">
                <img src="../../assets/styles/images/icon_login_lock.png" alt="간편비밀번호입력)">
            </div>
            <!-- s : 간편 비밀번호 입력-->
            <div class="ls_content">
                <div class="ls_notice div_middle">
                    <span v-html="notice"></span>
                </div>
                <!-- s : 비밀번호 입력 check dots-->
                <div class="easy_pw_dots">
                    <div class="pw_dot" v-for="n in length" :key='n' :class="{checked: (n <= password.length)}"></div>
                </div>
                <!-- e : 비밀번호 입력 check dots-->
                <!-- s : 키패드-->
                <div class="easy_pw_numbers on">
                    <!--버튼 활성시 on 클래스 추가-->
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">1</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">2</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">3</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">4</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">5</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">6</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">7</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">8</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number" @click="clickKeypad">9</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number pw_img" @click="clickKeypad('reset')">
                            <img src="../../assets/styles/images/icon_login_refresh2.png" alt="비밀번호초기화">
                        </div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number"  @click="clickKeypad">0</div>
                    </div>
                    <div class="layout_pw_number">
                        <div class="pw_number pw_img" @click="clickKeypad('delete')">
                            <img src="../../assets/styles/images/icon_login_backspace2.png" alt="비밀번호삭제">
                        </div>
                    </div>
                </div>
                <!-- e : 키패드                                -->
            </div>
            <!-- e : 간편 비밀번호 입력-->
            <div v-if="mode == 'login'" class="ls_notice" style="margin-top:-25px">
                <i class="icon_notice"></i><span class="fs-17" @click="resetSimple">비밀번호 재설정</span>
            </div>
            <!-- 소매점 선택 팝업 -->
            <div v-if="stores" class="layerdim_wrap" style="display:table">
                <div class="dim_inner">
                    <div class="layer_wrap">
                        <div class="layer_box">
                            <!-- <div class="layer_head">
                                <h2 class="ly_title">소매점 선택</h2>
                            </div> -->
                            <div class="layer_body">
                                <div class="layer_content">
                                    <p id="ptext">소매점 선택</p>
                                    <br>
                                    <table class="mb-2">
                                        <colgroup>
                                            <col width="50%"/>
                                            <col/>
                                            <col width="50%"/>
                                        </colgroup>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <div class="radio" v-for="store in stores" :key="store.customerCode">
                                                    <input type="radio" name="rbtn_store" :id="'rbtn_store_' + store.customerCode" v-model="selectedStore" :value="store.customerCode" @click="clickStore" />
                                                    <label :for="'rbtn_store_' + store.customerCode">{{store.storeName}}</label>
                                                </div>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="t_red pt-2 ta-c" v-show="storeMessage">{{storeMessage}}</div>
                                <div class="btn_center">
                                    <a class="btn_wht" @click="login()">확인</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>       
    </div>
</template>

<script>
    import Login from '@/api/Login'
    import Cart from '@/api/Cart'

    export default {
        props: {
            mode: {
                type: String,
                default: 'regist'
            },   
            id: {
                type: String,
                default: null
            }, 
            otp: {
                type: String,
                default: null
            }
        },
        data: () => {
            return {
                password: '',
                length: 6,
                noticeList: [
                    '로그인 시 사용할<br>간편 <font class="bold">비밀번호 6자리</font>를 눌러주세요.',
                    '다시 한 번 입력해주세요.',
                    '간편비밀번호 6자리를 눌러주세요.'
                ],
                notice: '',
                isCompleted: false,
                beforePassword: '',
                stores: null,
            };
        },
        mounted() {

            if (this.mode == 'regist') {
                if (this.id == null) {
                    this.$router.push('/login');
                }

                this.notice = this.noticeList[0];
                
                this.$dialog('최초 인증 후 간편비밀번호 6자리를 등록하시면 이후 등록한 비밀번호로 로그인 가능합니다.');
            } else {
                this.notice = this.noticeList[2];
            }
            
        },
        methods: {
            clickKeypad(command) {
                if (this.isCompleted) {
                    //console.log('이미완료');
                    return false;
                }

                if ('reset' == command) {
                    this.password = '';
                } else if ('delete' == command) {
                    this.password = this.password.substring(0, this.password.length-1);
                } else {
                    let key = command.target.innerHTML;
                    this.password += key;

                    if (this.password.length >= this.length) {
                        this.complete();
                    }
                }
                //console.log(this.password);
            },
            complete() {
                //console.log('입력완료 : ' + this.password);
                this.isCompleted = true;

                if ('login' == this.mode) {
                    this.login();
                } else if ('regist' == this.mode) {
                    if (this.beforePassword.length == 0) {
                        //최초 입력
                        this.isCompleted = false;
                        this.notice = this.noticeList[1];
                        this.beforePassword = this.password;
                        this.password = '';
                    } else {
                        //다시 입력
                        if (this.isCompleted) {
                            if (this.beforePassword != this.password) {
                                this.isCompleted = false;

                                this.$dialog(
                                    {
                                        // title: '확인',
                                        message: '비밀번호가 서로 일치하지 않습니다. \n처음부터 다시 입력하시겠습니까?',
                                        button: {
                                            yes: '예',
                                            no: '아니요'
                                        },
                                        callback: confirm => {
                                            if (confirm) {
                                                this.notice = this.noticeList[0];
                                                this.beforePassword = '';
                                            } 
                                            this.password = '';
                                        }
                                    }
                                );  
                            } else {
                                //console.log('비밀번호 일치');
                                Login.insertSimple({
                                    id: this.id,
                                    otp: this.otp,
                                    password: this.password
                                })
                                .then(res => {
                                    //console.log(res);
                                    this.$store.commit('loginKey', res.data.loginKey);
                                    this.$dialog(
                                        {
                                            // title: '알림',
                                            message: '간편비밀번호가 등록되었습니다.<br>메인화면으로 이동합니다.',
                                            button: {
                                                yes: '확인',
                                            },
                                            callback: confirm => {
                                                this.login();
                                            }
                                        }
                                    );
                                })
                                .catch(error => {
                                    this.isCompleted = false;
                                    this.password = '';
                                    this.$dialog(
                                        {
                                            // title: '알림',
                                            message: '간편비밀번호 등록에 실패했습니다.',
                                            button: {
                                                yes: '확인',
                                            }
                                        }
                                    ); 
                                })
                            }
                        }

                    }
                }
            },
            login(customerCode) {
                //console.log(this.selectedStore);
                
                if (this.stores) {
                    if (!this.selectedStore) {
                        this.storeMessage = '소매점을 선택해주세요.';
                        return false;
                    } else {
                        customerCode = this.selectedStore;
                    }
                }

                //console.log('this.$store.state.loginKey', this.$store.state.loginKey);

                Login.simple({
                        loginKey: this.$store.state.loginKey,
                        password: this.password,
                        customerCode: customerCode
                    })
                    .then(res => {
                        if (res.data.token != null) {
                            //this.stores = null;
                            // 토큰정보 저장
                            this.$store.commit('session', res.data.token);
                            //console.log(this.$store.state.session);
                            // 고객정보 저장
                            this.$store.commit('customer', res.data.customer);
                            //console.log(this.$store.state.customer);
                            // 장바구니 개수 저장
                            Cart.getCart()
                                .then(res => {
                                    this.$store.commit('cart', res.data);
                                    
                                    window.location.href = "/main";
                                });
                            
                            //this.$router.push({name: 'Main'});
                        } else {
                            this.stores = res.data.stores;
                        }
                    })
                    .catch(error => {
                        this.resetKeypad('reset');
                        if ('No LoginKey' == error.response.data.message) {
                            // this.$dialog('<div class="paragraph" style="text-align:left; font-size:17.5px;">다른 기기에서 접속한 이력으로<br>간편비밀번호 재설정이 필요합니다. 전화번호로 인증 하신 후<br>간편비밀번호를 다시 등록해주세요.</div>');
                            this.$dialog(
                                {
                                    title: '알림',
                                    message: '다른 기기에서 접속한 이력으로 간편비밀번호 재설정이 필요합니다. 전화번호로 인증 하신 후 간편비밀번호를 다시 등록해주세요.',
                                    button: {
                                        yes: '확인',
                                    },callback: confirm => {
                                        console.log(confirm)
                                        this.$store.commit('loginKey', null);
                                        this.$router.push('/login');
                                    }
                                }
                            );
                            // this.resetSimple();
                        } else if ('Invalid Password' == error.response.data.message) {
                            this.$dialog('비밀번호가 유효하지 않습니다.');
                        } else {
                            this.$dialog('서버 에러 발생');
                        }
                    })
            },
            resetKeypad() {
                this.isCompleted = false;
                this.password = '';
            },
            resetSimple() {
                this.$dialog(
                    {
                        // title: '확인',
                        message: '전화번호로 인증 후 간편비밀번호를 다시 등록해주세요',
                        button: {
                            yes: '확인'
                        },
                        callback: confirm => {
                            this.$store.commit('loginKey', null);
                            this.$router.push('/login');
                        }
                    }
                );  
            }
        }
    };
</script>

<style>
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
</style>