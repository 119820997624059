<template>
    <!-- popup -->
    <div class="layer_view_area" v-if="detail">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>홍보 및 교육자료</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="goBack()"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->

        <div class="container">
            <!-- 홍보자료 보기 -->
            <div class="list view">
    
                <div class="block_wrap">
                    <div class="title">{{detail.subject}}</div>
                    <dl class="txt_subject">
                        <dt>게시일 :</dt>
                        <dd>{{detail.registDttm}}</dd>
                    </dl>
                </div>
    
                <div class="board_wrap">
                    
                    <div v-if = "detail.extension != null && detail.extension != ''">
                        <div v-if = "detail.extension === 'avi' || detail.extension === 'wmv' || detail.extension === 'asf'
                            || detail.extension === 'flv' || detail.extension === 'mov' || detail.extension === 'mpeg'
                            || detail.extension === 'mpeg1' || detail.extension === 'mpeg2' || detail.extension === 'mpeg4'
                            || detail.extension === 'mp4'">
                            <p><video controls :src="detail.filePath"></video></p>
                        </div>
                        <div v-else>
                            <p><img :src=detail.filePath alt="홍보자료 관련 이미지"></p>
                        </div>
                    </div>
                    <br />
                    <p v-html="detail.contents"></p>
                    <p v-if="detail.linkUrl">URL : <span style="color:#00b4f6;">{{detail.linkUrl}}</span></p>
                    
                </div>
    
                <div class="board_button">
                    <a href="#" class="btn_blk" @click="goBack()">목록</a>
                </div>
    
            </div>
            <!-- //홍보자료 보기 -->
        </div>
    </div>
</template>

<script>
import Contents from '@/api/Contents'

export default {
    props: ['id'],
    data() {
        return {
            detail: null
        };
    },
    watch: {
        id: function(newVal, oldVal) {
            this.getDetail(newVal);
        }
    },
    created() {
        this.getDetail(this.id);
    }, 
    methods: {
        getDetail(id) {
            if (id) {
                Contents.getContentsDetail(id)
                    .then(res => {
                        this.detail = res.data;
                    });
            }
        },
        goBack() {
            this.detail = null;
            window.history.back(); 
        }
    },
};
</script>

<style >
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
</style>
