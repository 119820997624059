<template>
    <div class="vd-overlay" :class="{fullsize: dialog.type == 'fullscreen'}" v-show="isShow" @click="handleClickOverlay" id="vueDialog">
        <div class="vd-container">
            <div class="vd-header">
                <!-- <h4 class="vd-title">{{dialog.title}}</h4> -->
                <button class="vd-btn-close" @click.stop="e => handleClickButton(e, false)">닫기</button>
            </div>
            <div class="vd-body">
                <div class="vd-text" v-show="dialog.message" v-html="dialog.message"></div>
                <div class="vd-form" v-show="dialog.form"></div>
                <div class="vd-validate" v-html="validateMessage"></div> 
                <div class="vd-btn-grid" :class="{ isMono: !dialog.button.no || !dialog.button.yes }">
                    <button v-if="dialog.button.no" @click.stop="e => handleClickButton(e, false)" class="vd-btn no">
                        {{ dialog.button.no }}
                    </button>
                    <button
                        v-if="dialog.button.yes"
                        :disabled="dialog.auth ? !password : false"
                        @click.stop="e => handleClickButton(e, true)"
                        class="vd-btn yes">
                        {{ dialog.button.yes }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { events } from './events'
import $ from 'jquery'

export default {
    name: 'VueDialog',
    data() {
        return {
            isShow: false,
            password: null,
            dialog: {
                auth: false,
                type: '',
                title: '',
                message: '',
                form: null,
                button: {}
            },
            formParent: null,
            params: {},
            validateMessage: null,
            openerRouteName: null, 
        }
    },
    watch: {
        $route: function(to) {
            console.log('vue-dialog watch route', to.name);
            // 라우터 변화 감지 시 창 닫기
            if (to.name != this.openerRouteName) {
                this.close();
            }
        }
    },
    mounted() {
        if (!document) return
        events.on('open', this.open);
        events.on('close', this.close);
        // document.addEventListener('keyup', this.handleKeyUp)
    },
    unmounted() {
        console.log('vue-dialog unmounted');
    },
    methods: {
        resetState() {
            this.password = null;
            this.formParent = null;
            this.dialog = {
                auth: false,
                type: '',
                title: '',
                message: '',
                form: null,
                button: {},
                callback: () => {}
            }
        },
        handleClickButton({ target }, confirm) {
            if (target.id == 'vueDialog') return
            if (confirm && this.dialog.auth && !this.password) return
            this.close(confirm);
        },
        handleClickOverlay({ target }) {
            console.log(target);
            if (target.id == 'vueDialog') {
                this.close(false);
            }
        },
        handleKeyUp({ keyCode }) {
            if (keyCode == 27) {
                this.handleClickOverlay({ target: { id: 'vueDialog' } })
            }
            if (keyCode == 13) {
                this.handleClickButton({ target: { id: '' } }, true)
            }
        },
        async close(confirm) {
            //console.log('vue-dialog on close');
            let base = this;
            let message = null;
            this.validateMessage = null;

            // callback
            if (this.dialog.callback) {
                //console.log(this.dialog.callback);

                let res = null;
                
                if (confirm) {
                    res = {
                        validate(val) {
                            base.validateMessage = val;
                        },
                        message(val) {
                            message = val;
                        },
                    }
                }

                let ret = await this.dialog.callback(res, this.password);

                //console.log('vue-dialog callback ret : ' + ret);
                //console.log('vue-dialog callback end');
                
                // YES인데 리턴이 false이거나 validateMessage가 있는 경우 닫기 취소
                if (confirm && (ret == false || base.validateMessage)) return;

                this.dialog.callback = () => {};
            }

            this.isShow = false;

            //폼을 원래위치로 이동
            if (this.dialog.form && this.formParent) {
                let form = this.dialog.form;
                //console.log(form);
                this.formParent.appendChild(form);
                form.style.display = 'none';
            }

            if (message) {
                this.$dialog(message);
            }

            //scroll-Top
            $('.vd-container').scrollTop(0);
        },
        open(params) {
            this.openerRouteName = this.$route.name;
            console.log('vue-dialog method open', this.openerRouteName);
            //console.log('vue-dialog window.noDialog : ' + window.noDialog);
            // 세션이 없는 경우 대화상자 출력방지
            if (window.noDialog) {
                window.noDialog = false;
                return; 
            }

            this.resetState();
            this.params = params;
            this.isShow = true;
            //console.log(this.params);

            // set params to dialog state
            Object.entries(params).forEach(param => {
                if (typeof param[1] == typeof this.dialog[param[0]]) {
                    this.dialog[param[0]] = param[1]
                }
            });

            // 기본값 셋팅
            this.dialog.title = this.dialog.title || (this.dialog.type=='confirm'?'확인':'알림');

            if (this.dialog.type != 'fullscreen') {
                this.dialog.button['yes'] = this.dialog.button['yes'] || '확인';
            }

            if (this.dialog.type == 'confirm') {
                this.dialog.button['no'] = this.dialog.button['no'] || '취소';
            }

            if (params.form) {
                this.dialog.form = params.form;

                let form = null;

                if (typeof params.form == 'object') {
                    form = params.form;
                } else {
                    form = document.querySelector(params.form);
                }
                
                //console.log(this.dialog.form);
                //console.log(form);
                
                if (form) {
                    this.formParent = form.parentElement;

                    document.querySelector('.vd-form').innerHTML = '';
                    document.querySelector('.vd-form').appendChild(form);

                    form.style.display = 'block';

                    this.dialog.form = form;
                }
            }
        }
    }
};

</script>