import Send from '../utils/Send.js'

export default {
    getBankList(data) {
        return Send({
            url: '/payment/bank',
            method: 'get',
            params : data,
        })
    },
    getBankListRefund(data) {
        return Send({
            url: '/payment/bank/refund',
            method: 'get',
            params : data,
        })
    },
    getOrderKey() {
        return Send({
            url: '/payment/key',
            method: 'get'
        })
    },
    callDanalApi(data) {
        return Send({
            url: '/payment/ez/call',
            method: 'post',
            params : data,
        })
    },
    refund(params) {
        return Send({
            url: '/payment/vc/refund',
            method: 'post',
            data : params,
        })
    },
    cancelEZ(params) {
        return Send({
            url: '/payment/ez/cancel',
            method: 'post',
            data : params,
            headers : {'Content-Type': 'application/json'},
        })        
    },
    
    // insertOrder(json) {
    //     return Send({
    //         url: '/order',
    //         method: 'post',
    //         data : json,
    //         headers : {'Content-Type': 'application/json'},
    //     })
    // },
    // getOrderDetail(data) {
    //     return Send({
    //         url: '/order/'+data,
    //         method: 'get',
    //     })        
    // },
    // getOrderHistory(data) {
    //     return Send({
    //         url: '/order',
    //         method: 'get',
    //         params : data,
    //     })        
    // },
    // updateOrder(params) {
    //     return Send({
    //         url: '/order',
    //         method: 'put',
    //         data : params,
    //         headers : {'Content-Type': 'application/json'},
    //     })        
    // },
    
}