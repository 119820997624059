import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Product from '../components/product/Product.vue'
import Main from '../components/main/Main.vue'
import Footer from '../components/main/Footer.vue'
import Member from '../components/main/Member.vue'
import Cart from '../components/cart/Cart.vue'
import Login from '../components/main/Login.vue'
import LoginSimple from '../components/main/LoginSimple.vue'
import Notice from '../components/notice/Notice.vue'
import Promotion from '../components/promotion/Promotion.vue'
import Survey from '../components/survey/Survey.vue'
import SurveyDetail from '../components/survey/SurveyDetail.vue'
import OrderHistory from '../components/order/OrderHistory.vue'
import Order from '../components/order/Order.vue'
import Refund from '../components/order/Refund.vue'
import DeliveryPlan from '../components/customer/DeliveryPlan.vue'

import FileUpload from '../components/salesforce/FileUpload.vue'
import Preview from '../components/salesforce/Preview.vue'

import System from '../components/main/System.vue'
import Access from '../components/main/Access.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {unauthorized : true}
  },
  {
    path: '/login/simple',
    name: 'LoginSimple',
    component: LoginSimple,
    props: true,
    meta: {unauthorized : true}
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    props: true,
    alias: ['/'],
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/fileUpload',
    name: 'FileUpload',
    component: FileUpload,
    meta: {unauthorized : true}
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    props: true,
    meta:{
      title: '상품목록'
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    props: true,
    meta:{
      title: '장바구니'
    }
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    props: true,
    meta:{
      title: '공지사항'
    }
  },
  {
    path: '/notice/:id',
    name: 'NoticeDetail',
    component: Notice,
    meta:{
      title: '공지사항'
    }
 },
  {
    path: '/promotion',
    name: 'Promotion',
    component: Promotion,
    props: true,
    meta:{
      title: '교육자료'
    }
  },
  {
    path: '/promotion/:id',
    name: 'PromotionDetail',
    component: Promotion,
    meta:{
      title: '교육자료'
    }    
 },
  {
    path: '/survey',
    name: 'Survey',
    component: Survey,
    props: true,
    meta:{
      title: '설문조사'
    }
  }
  ,
  {
    path: '/survey/:id',
    name: 'SurveyDetail',
    component: Survey
  }
  ,
  {
    path: '/orderHistory',
    name: 'OrderHistory',
    component: OrderHistory,
    props: true,
    meta:{
      title: '주문현황'
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    props: true,
    meta:{
      title: '주문'
    }
  },
  {
    path: '/refund',
    name: 'Refund',
    component: Refund,
    props: true,
    meta:{
      title: '환불요청'
    }
  },
  {
    path: '/member',
    name: 'Member',
    component: Member,
    props: true,
    meta:{
      title: '소매점 정보'
    }
  },
  {
    path: '/deliveryPlan',
    name: 'DeliveryPlan',
    component: DeliveryPlan,
    props: true,
    meta:{
      title: '배송일정'
    }
  }, 
  {
    path: '/preview',
    name: 'Preview',
    component: Preview,
    meta: {unauthorized : true, title: '미리보기'}
  },
  {
    path: '/system',
    name: 'System',
    component: System,
    props: true,
    meta: {unauthorized : true}    
  },
  {
    path: '/access',
    name: 'Access',
    component: Access,
    meta: {unauthorized : true}    
  },
  {
    path: '/footer',
    name: 'Footer',
    component: Footer,
    props: true,
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( async(to, from, next) => {
  if (to.fullPath.indexOf('/access') == -1 && store.state.accessdenied){
    // 접근불가 상태인데 접근불가 페이지가 아니면 안내페이지로 이동
    return next('/access');
  }

  if (to.fullPath.indexOf('/system') == -1 && store.state.maintenance){
    // 점검시간인데 안내페이지가 아니면 안내페이지로 이동
    return next('/system');
  }

  // 권한이 필요 없는 페이지이거나 토큰이 있는 경우 통과
  if (to.fullPath.indexOf('assets') > -1 
      || to.matched.some(record => record.meta.unauthorized) 
      || store.state.session){
    return next();
  }

  // 로그인페이지로 이동
  return next('/login');
})

export default router
