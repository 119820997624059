<template>
    <div class="container">
        <!-- container -->
        <div class="sub_container">

            <!-- 홍보자료 리스트 -->
            <ul class="sec_list" v-for="promotion of contents" :key="promotion.contentsMstSeq">
                <li>
                    <a href="#" v-on:click.prevent="popupOpen(`${promotion.contentsMstSeq}`)">
                        <div class="thumb">
                            <div v-if = "promotion.extension != null && promotion.extension != ''">
                                <div v-if = "promotion.extension === 'avi' || promotion.extension === 'wmv' || promotion.extension === 'asf'
                                    || promotion.extension === 'flv' || promotion.extension === 'mov' || promotion.extension === 'mpeg'
                                    || promotion.extension === 'mpeg1' || promotion.extension === 'mpeg2' || promotion.extension === 'mpeg4'
                                    || promotion.extension === 'mp4'">
                                    <p><video :src="promotion.filePath"></video></p>
                                </div>
                                <div v-else>
                                    <p><img :src=promotion.filePath alt="홍보자료 관련 이미지"></p>
                                </div>
                            </div>
                        </div>
                        <div class="block_wrap">
                            <div class="title">
                                {{promotion.subject}}
                                <span v-if = "promotion.newDay === '1'" class="ico_red">NEW</span>
                            </div>
                            <dl class="txt_subject">
                                <dt>게시일 :</dt>
                                <dd>{{promotion.registDttm}}</dd>
                            </dl>
                        </div>
                    </a>
                </li>
                
            </ul>

            <infinite-loading @infinite="infiniteHandler" spinner="waveDots">
                <div class="no_result" slot="no-results">
                    게시글이 없습니다.
                </div>
                <div slot="no-more"></div>
            </infinite-loading>  

            <!-- //홍보자료 리스트 -->

        </div>
        <!-- //container -->

        <!-- popup -->
        <Detail :id="$route.params.id"/>
    </div>
</template>

<script>
import Contents from '@/api/Contents'
import Detail from '@/components/promotion/PromotionDetail'
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components: {
        InfiniteLoading,
        Detail
    },

    data: () => {
        return {
            contents: [],
            startNo: 1,
        };
    },

    methods: {
        popupOpen (id) {
            this.$router.push({name: 'PromotionDetail', params: {id: id}});
        },
      
        infiniteHandler($state) {
          Contents.getContentsList('contents_mst_seq', 'DESC', this.startNo, 'PR')
          .then(({data}) => {
            setTimeout(() => {
            if (data.length) {
                this.startNo += 20;
                this.contents = this.contents.concat(data);
                console.log("after", this.contents.length, this.startNo);
                $state.loaded();

            } else {
                $state.complete();
            }
            }, 100)
        });
        }
    },
};
</script>

<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
</style>
