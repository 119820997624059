<template>
    <!-- container -->
    <div class="container">
        <div class="delivery">
            <span class="ico_blue">이번배송</span>
            <strong class="right">{{current}}</strong>
        </div>
        <div class="delivery top_line" >
            <span class="ico_gray">다음배송</span>
            <strong class="right">
                <div v-if="!planList">
                    배송일정이 없습니다.
                </div>
                <div v-for="plan of planList" v-bind:key="plan">
                    {{plan}}
                </div>
            </strong>
        </div>
    </div>
    <!-- //container -->
</template>

<script>
// import axios from "axios";
import $ from 'jquery'
import Code from '@/api/Code'
import Order from '@/api/Order'
import Cart from '@/api/Cart'
import Customer from '@/api/Customer'
import moment from 'moment'

export default {
    name:'DeliveryPlan',
    data: () => {
        return {
            current : null,
            planList : [],
        }
    },
    created() {

        // 배송계획 조회
        Customer.getPlans()
        .then((response) => {
            let plan = response.data;

            console.log(plan);
            if (plan.length > 0) {
                this.current = plan[0];
                if (plan.length > 1) {
                    this.planList = plan.slice(1, plan.length);
                    console.log(this.planList);
                } else {
                    this.planList = null;
                }
            }
        })
        .catch((e) => {
            console.log("error : ", e);
        });        

   },

}
</script>

<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
</style>