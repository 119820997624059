<template>
    <!-- popup -->
    <div class="layer_view_area" v-if="isShow">
        <!-- header -->
        <div class="header_wrap">
            <div class="header">
                <h1>설문조사</h1>
                <div class="hd_left">
                    <a class="aside_back" @click="goBack()"><span>뒤로가기</span></a>
                </div>
            </div>
        </div>
        <!-- //header -->
        <div class="survey_area">
            <div id="survey"></div>
        </div>
        <div class="layerdim_wrap" v-if="showConfirm">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()" v-if="isConfirmed == false">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>
                                최종제출 후 수정이 불가능합니다.
                                <br/>제출하시겠습니까?
                            </p>
                            <div class="btn_center combo_form" v-if="isConfirmed == false">
                                <div>
                                    <a class="btn_wht" @click="close()">취소</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="confirm()">확인</a>
                                </div>
                            </div>                            
                            <div class="btn_center" v-if="isConfirmed == true">
                                <div>
                                    <a class="btn_wht">저장 중...</a>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Survey from '@/api/Survey'
import $ from 'jquery';

export default {
    name: "Survey",
    props: ['id'],
    data() {
        return {
            isShow: false,
            showConfirm: false,
            isConfirmed: false,
            isCompleted: false,
            message: ''
        }
    },
    watch: {
        id: function (newVal, oldVal) {
            this.getDetail(newVal);
        }
    },
    created() {
        this.routeName = this.$route.name;

        let sfid = null;

        if (this.$route.name == 'Preview') {
            sfid = this.$route.query.id;
        } else {
            sfid = this.id;
        }

        this.getDetail(sfid);
    }, 
    methods: {
        async getDetail(sfid) {
            if (!sfid) {
                this.isShow = false;
                return;
            }

            this.isShow = true;

            let result = false;

            await Survey.getList({
                    "sfid": sfid
                })
                .then(({ data }) => {
                    console.log('data', data);

                    if (data && data.length > 0) {
                        if (data[0].taken == true) {
                            this.$dialog({
                                type: 'alert',
                                message: '이미 참여하셨습니다.',
                                callback: () => {
                                    this.goBack();
                                }
                            });
                            result = false;
                        } else {
                            result = true;
                        }
                    } else {
                        // 데이터 없는 경우 설문 대상 아님
                        this.$dialog({
                            type: 'alert',
                            message: '설문조사 대상이 아닙니다.',
                            callback: () => {
                                this.goBack();
                            }
                        });
                        result = false;
                    }
                });

            if (!result) {
                return;
            }

            await Survey.getDetail({id: sfid})
                .then(res => {
                    $('#survey').html(res.data);

                    // accId 주입 (주입하지 않아도 정상적으로 등록됨)
                    let $form = $('#survey').find('form');
                    //$form.prepend('<input type="hidden" id="accId" name="accId" value="' + this.$store.state.customer.id + '"/>');
                    //console.log('$form', $form.find('input[name="accId"]'));
                    //console.log('$form', $form.parent().html());
                    //$form.attr('action', $form.attr('action') + '&accId=' + this.$store.state.customer.id)
                    //console.log('$form', $form.attr('action'));

                    // 버튼 커스터마이징
                    let $submit = $('#survey').find('.slds-button--brand');

                    // 버튼명 변경
                    $submit.val('최종제출하기');

                    let onclick = $submit[0].onclick;
                    $submit[0].onclick = null;

                    let base = this;
                    $submit.click(function () {
                        let inputNames = new Array();
                        let questionNo = '';

                        // 필수입력 체크 (radio, checkbox만 체크, 주관식은 제외)
                        $('input').each(function () {
                            if (inputNames.indexOf(this.name) == -1) { // 배열형(radio,checkbox)은 최초 1회만 체크
                                inputNames.push(this.name);
                                if (this.type == 'radio' || this.type == 'checkbox') {
                                    if ($('input[name="' + this.name + '"]:checked').length == 0) {
                                        // 문항 추출
                                        let question = $(this).parentsUntil('.slds-box').last().parent().find('.slds-form-element__label').text();
                                        questionNo += ', ' + question.split(':')[0];
                                    }
                                }
                            }
                        });

                        if (questionNo.length > 0) {
                            questionNo = questionNo.substring(2);
                            base.$dialog(questionNo + '번 문항은 필수입력입니다.');
                            return false;
                        }

                        if (!base.isConfirmed) {
                            base.showConfirm = true;
                            return false;
                        } else {
                            // 최종 확인이 된 경우 저장
                            onclick();
                            $submit.parent().parent().hide();

                            //setTimeout(function() {
                            //history.back();
                            //}, 3000);

                            let interval = setInterval(function () {
                                // salseforce측 스크립트의 실행 완료 여부를 알 수 없기 때문에
                                // 저장 시 버튼이 감춰졌다 다시 보여지는 과정을 이용해 완료여부 체크
                                let display = $submit.parent().css('display');
                                if (display == 'inline') {
                                    clearInterval(interval);
                                    base.showConfirm = false;
                                    base.isCompleted = true;
                                    base.$dialog({
                                        type: 'alert',
                                        message: '저장되었습니다.<br/>참여해주셔서 감사합니다.',
                                        callback: () => {
                                            base.goBack();
                                        }
                                    });
                                }
                            }, 500);
                        }
                    });
                })
                .catch(error => {
                })
        },
        confirm() {
            //this.showConfirm = false;
            this.isConfirmed = true;
            $('#survey').find('.slds-button--brand').click();
        },
        close() {
            if (this.isCompleted) {
                this.goBack();
            }
            this.showConfirm = false;
            this.message = '';
        },
        goBack() {
            this.isShow = false;
            console.log('this.routeName',this.routeName);
            if (this.routeName == 'SurveyDetail') {
                // 링크를 타고 온 경우
                //this.$router.push({ name: 'Survey'});
                this.$router.go(-1);
            } else if (this.routeName == 'Survey') {
                // 리스트에서 온 경우
                this.$router.push({ name: 'Survey' });
                //this.$router.go(-1);
            }            
        }
    }




};
</script>

<style >
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
    @import '../../assets/styles/css/survey.css';
</style>
