<template>
    <div class="container">

        <!-- container -->
        <div class="sub_container">
            <!-- 상단 알림 -->
            <div class="sec">
                <div class="Notification_wrap" v-bind:style="deadLineOrder">
                    <span>{{orderDeadLineDisplay}} 까지 수정 가능</span>
                    <span>{{ countDown }}분 남음</span>
                </div>
            </div>
            <!-- //상단 알림 -->

            <!-- 장바구니 선택 & 삭제 -->
            <div class="sec">
                <div class="splitup_area">
                    <div class="s_left">
                        <div class="checkbox">
                            <input id="check01" class="allCheck" type="checkbox" name="myInput" @click="allCheck(cartLists)"/>
                            <label for="check01">전체선택</label>
                        </div>
                    </div>
                    <div class="s_right">
                        <a @click="selectDelete(cartLists)">선택삭제</a>
                    </div>
                </div>
            </div>
            <!-- //장바구니 선택 & 삭제 -->

            <!-- 장바구니 리스트 -->
            <div class="sec">
                <ul class="purchase_list" >
                    <div class="section_search" v-if="cartLists.length == 0 ">
                        <h2 class="sub_title">장바구니가 비어있습니다</h2>
                    </div>
                    <li v-for="cartList of cartLists" :key="cartList.productCode">
                        <div class="splitup_area">
                            <div class="s_left">
                                <div class="checkbox">
                                    <input :id="cartList.productCode"  type="checkbox" class="selectBtn" name="myInput" @click="selectBox(cartList)"/>
                                    <label :for="cartList.productCode">선택</label>
                                </div>
                            </div>                            
                            <div class="s_right">
                                <a @click="oneDelete(cartList.productCode)">삭제</a>
                            </div>
                        </div>
                        <div class="product_info">
                            <div class="thumb_img">
                                <vue-image :src="cartList.imageUrl" size="75" />
                            </div>
                            <div class="des_area">
                                <div class="name">{{cartList.productNm}}</div>
                                <div class="composition">
                                    <span>{{cartList.addInfo1}}
                                        <!-- <em></em> -->
                                    </span>
                                    <span>{{cartList.addInfo2}}
                                        <!-- <em></em> -->
                                    </span>
                                </div>
                                <div class="composition" v-if="cartList.addInfo1 == null">
                                    <span><pre> </pre></span>
                                </div>
                                <div class="option">
                                    <div class="count_area">
                                        <input type="text" :id="cartList.productCode" :class="'qty input'+cartList.productCode" :value="cartList.quantity+' ' + unitDefine[cartList.productType].name" readonly>
                                        <a class="btn_minus" @click="qty('minus',cartList)">
                                            <span>-1</span>
                                        </a>
                                        <a class="btn_plus" @click="qty('plus',cartList)">
                                            <span>+1</span>
                                        </a>
                                    </div>
                                    <div class="sum_area">
                                        <strong :class="'total totalPrice'+cartList.productCode">{{cartList.amount | makeComma}}</strong>
                                        <span>원</span>
                                    </div>
                                </div>
                                <p class="prerequisite_txt">*최소 {{cartList.minQuantity}}{{unitDefine[cartList.productType].name}}이상</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 장바구니 리스트 -->

            <!-- 21.02.15 수정 : 주문예상금액 전체에 sec 클래스 div 추가 -->
        </div>
        <!-- //container -->
        <!-- 총 주문 금액 -->
        <div class="bottom_area">
            <dl class="amount_area">
                <dt>주문예상금액</dt>
                <dd class="amount" id="amount" v-if="total.amount"><span>{{total.quantityString}}</span> <span>/</span>
                    <span>{{total.amount | makeComma}} 원</span></dd>
                <dd class="amount" id="amount" v-if="total.amount==0"><span>0 원</span></dd>
            </dl>
        
            <!-- 버튼 -->
            <div class="total_button">
                <div class="combo_form">
                    <router-link to="/product"><a class="btn_blk bigger">상품추가하기</a></router-link>
                    <a class="link_button" @click="selectOrder(cartLists)">주문하러가기</a>
                </div>
            </div>
            <!-- //버튼 -->
        </div>

        <!-- SKU별 상품 최소/최대 -->
        <div class="layerdim_wrap cartpopup1" v-bind:style="popUpMinMax"> 
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>{{productNm}} <br> {{minMax}} 주문 수량은 {{minMaxQuantity}} 입니다.</p>
                            <div class="btn_center">
                                <a class="btn_wht" @click="close()">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 주문확정 가는 팝업 -->
        <div class="layerdim_wrap cartpopup2" v-bind:style="popUpOrder">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>{{message}}</p>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht" @click="close()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="goOrder(false)">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- grade별 최소/최대 -->
        <div class="layerdim_wrap cartpopup1" v-bind:style="popUpAllQty"> 
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <!-- <p>총 구매하실 수 있는 수량은 <br> {{min}} ~ {{max}}갑 입니다.</p> -->
                            <p>최소 수량 {{min}} 이상 주문 가능합니다.</p>
                            <div class="btn_center">
                                <a class="btn_wht" @click="close()">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 상품 개수 체크 -->
        <div class="layerdim_wrap" v-bind:style="product0">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                        <p>주문할 상품이 없습니다.</p>
                            <div class="btn_center">
                                <a class="btn_wht" @click="close()">확인</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 배송일 같은 완료건 있을시 병합 -->
        <div class="layerdim_wrap cartpopup2" v-bind:style="cartOrderEqual">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>이미 등록된 주문이 있습니다. <br> 추가 하시겠습니까?</p>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht" @click="close()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="cartMerge()">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 배송대기중(승인된) 주문건 안내 -->
        <div class="layerdim_wrap cartpopup2" v-if="showPopupApproved">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div class="layer_head">
                            <!-- <h2 class="ly_title">알림</h2> -->
                            <a class="btn_close" @click="close()">닫기</a>
                        </div>
                        <div class="layer_body">
                            <p>아직 배송대기중(승인완료된) 주문이 있습니다. <br> 그래도 새 주문을 이어가시겠습니까?</p>
                            <div class="btn_center combo_form">
                                <div>
                                    <a class="btn_wht" @click="close()">아니요</a>
                                </div>
                                <div>
                                    <a class="btn_wht" @click="goOrder()">예</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import Code from '@/api/Code'
import Cart from '@/api/Cart'
import Product from '@/api/Product'
import Customer from '@/api/Customer'
import Order from '@/api/Order'
import moment from 'moment'
import VueImage from '@/components/common/vue-image';

export default {
    components: {
        VueImage
    },    
    data: () => {
        return {
            customerVO : [],            //로그인한 고객 정보
            cart : {},
            cartLists : [],             //장바구니 리스트
            cartListsTemp : [],         //장바구니 선택 리스트
            orderQty:0,                 //수량
            amount:0,                   //주문예상금액
            selectList:[],              //선택된 상품들
            select:{},                  //선택된 상품 id
            clickValue : 'cartUpdate',  //수량변경 분기
            // quantityCheck : false,
            minMax : '',                //최대, 최소 팝업 표시
            minMaxQuantity : 0,         //수량 최대 최소 팝업 표시
            popUpMinMax : {display : 'none'},   //상품의 최소/최대 팝업
            popUpOrder : {display : 'none'},    //주문확정 가는 팝업
            popUpAllQty : {display : 'none'},   //소매점주 등급별 최소/최대 팝업
            // quantity0 : {display : 'none'},     //선택된 상품수량 0개 팝업
            product0 : {display : 'none'},      //선택된 상품이 없는 팝업
            productNm : '',             //상품명
            min : 0,                    //상품 최소수량
            // max : 0,                    //상품 최대수량
            all : 0,                    //소매점주 등급 최소최대 수량
            allQty:0,                   //선택된 상품 총 수량
            // sessionList : [],           //체크된 상품 세션저장
            deliveryNum : '',           //배송요일 getDay값
            EdeliveryDate : '',         //예상 배송일
            countDown : 30,             //남은 주문마감시간
            orderedLists : null,          //최근 주문 1건(Ordered인 경우만)
            sessionOrderUpdate : '',    //session 저장된 orderUpdate(수정일 시 true)
            // sessionPopUpCehcek : '',
            sessionCartPopUpCheck : '', //장바구니 병합 후 "기존주문 수정하시겠습니까?" 팝업 미노출 
            cartOrderEqual : {display : 'none'},    //주문병합 팝업
            // orderUpdate : {display : 'none'},
            deadLineOrder : {display : 'none'},     //배송전날 마감시간 -30분전 표시
            message : '',                   //팝업 메시지
            orderDeadLine : '',             //주문마감시간
            orderDeadLineDisplay : '',      //주문마감시간 표시
            orderDeadLineCheck : false,     //배송전날 마감시간 체크(true : 지남 / false : 안지남)
            getDate : '' ,                  //배송날짜
            // deliveryDateCheck : false,
            countMinutes : 0,              //상단 마감시간 카운트다운
            // lastDate : 0,               //요번달 마지막날
            cutOffDays : 2,             //주문마감일수(n일전마감)
            cutOffDate : null,          //주문마감일
            dlvDate : null,             //배송일
            isApproved : null,          //승인된주문건 유무
            showPopupApproved : false,   //배송대기중(승인완료) 안내 팝업

            newProductList : [], //신제품목록

            unitDefine: {},
            total: {},
        }
    },
    async created() {

        // 제품타입 코드 조회 (단위명, 단위수량)
        this.unitDefine = await this.$root.getUnitDefine();

        // 주문마감시간 가져오기
        await Code.getCode('C00002', 'C01')
            .then(res => {
                // console.log('cart 주문마감시간 ',res.data);
                var deadline = res.data.refValue1.split(':')
                this.orderDeadLineDisplay = res.data.refValue1;
                this.orderDeadLine = parseInt(deadline[0]+deadline[1])
                // console.log('주문 마감시간 : ',this.orderDeadLine)
            });

        // 고객 최소최대수량 가져오기
        Code.getCode('C00001', this.$store.state.customer.grade)
            .then(res => {
                //codeDetail 최소값 없는경우 50으로 세팅
                /*if('46'==this.$store.state.customer.level3) {
                    this.min = 70
                }else */if(res.data.refValue1==null){
                    this.min = 70
                }else{
                    this.min = parseInt(res.data.refValue1)
                }
                // this.max = parseInt(res.data.refValue2)
            });
        

        //로그인한 고객정보
        Customer.getDetail()
        .then((response) => {
            this.customerVO = response.data
            // console.log('cart 소매점주 정보 ',this.customerVO
        })
        .catch((e) => {
        console.log("error : ", e);
        });

        // 배송계획 조회
        Customer.getPlan()
        .then((response) => {
            let plan = response.data;

            //예상배송일
            this.EdeliveryDate = plan.dlvDate;

            // this.orderDeadLine = 1200
            var cutOffTimeSplit = plan.cutOffTime.split(':');
            var deadLineMinute = cutOffTimeSplit[1];
            var deadLineHour = cutOffTimeSplit[0];
            var orderDeadLineTime;
            //console.log('cutOffTimeSplit : ', cutOffTimeSplit, ',deadLineMinute : ', deadLineMinute, ', deadLineHour : ', deadLineHour);

            if(deadLineMinute == '00'){
                orderDeadLineTime = parseInt(deadLineHour-1 +"30")
                this.countMinutes = 60
                // console.log('00분일때 orderDeadLineTime',orderDeadLineTime)
            }else if(deadLineMinute == '30'){
                orderDeadLineTime = parseInt(deadLineHour+"00")
                this.countMinutes = 30
                // console.log('00분 아닐때 orderDeadLineTime',orderDeadLineTime)
            }

            // 주문마감일 계산
            this.cutOffDate = parseInt(plan.cutOffDate.replace(/-/g, ''));
            this.dlvDate = parseInt(plan.dlvDate.replace(/-/g, ''));
            //console.log('주문마감일', this.cutOffDate);
            //console.log('배송예정일', this.dlvDate);

            var nowDateNum = parseInt(moment(new Date()).format('YYYYMMDD'));
            var nowTime = parseInt(moment(new Date()).format('HHmm'));
            //console.log('현재시간',  nowTime)

            // 주문마감시간 표시
            //console.log('nowDateNum : ',nowDateNum, '배송cutoff 띄우는 날짜 : ',this.cutOffDate, ', this.orderDeadLine : ',this.orderDeadLine, ', orderDeadLineTime : ',orderDeadLineTime, ',nowTime : ',nowTime);
            //if (nowDateNum == this.cutOffDate && this.orderDeadLine != '' && nowTime >= orderDeadLineTime && nowTime <= this.orderDeadLine){
                if (nowDateNum == this.cutOffDate && this.orderDeadLine != '' && nowTime >= orderDeadLineTime && nowTime <= this.orderDeadLine){    
                this.deadLineOrder.display='';
                this.countDownTimer()
            }            
        })
        .catch((e) => {
        console.log("error : ", e);
        });  
        
        // 신제품 조회
        /*
        Product
            .getProduct({
                'badgeCd':'P01',
                'sort.sortBy': 'default',
                'sort.desc': 'ASC'
            })
            .then(({data}) => {
                console.log('new product',data);  
                this.newProductList = data;  
            });
        */

        Cart.getCart()
        .then(async (response) => {
            this.cartLists = response.data;
            this.cartListsTemp = [...this.cartLists];

            //장바구니 0개 일 시, 수정분기 삭제
            if(this.cartLists.length == 0){
                // sessionStorage.removeItem('orderUpdate');
                this.$store.commit('orderUpdate', null);
            }
            // console.log('장바구니 리스트',this.cartLists)
            for(var i in this.cartLists){
                var totalPrice = this.cartLists[i].quantity * this.cartLists[i].price
                this.cartLists[i].amount = totalPrice;
                this.amount += totalPrice;
                this.cartLists[i].finalPrice = this.amount
                this.allQty += this.cartLists[i].quantity
                // $('.amount').text((this.amount).toLocaleString('ko-KR')+'원');

            }

            //console.log('this.cartLists', this.cartLists);
            this.total = await this.$root.totalQuantityAmount(this.cartLists);
            //console.log('this.total', this.total);
            // this.sessionList = JSON.parse(sessionStorage.getItem('selectedCartList'));
            // console.log(this.sessionList)
            // // $("input[name=myInput]:checkbox").prop("checked", true);
            // $("input[id=cartList]:checkbox").prop("checked", true);
            // // $("input[name=myInput]:checkbox").prop("checked", false);
            // $(".selectBtn").prop("checked", true);
                
            //     if(this.sessionList != null){
            //         console.log('있는데')
            //         // $("input[name=myInput]:checkbox").prop("checked", true);
            //         // $("input[id=check01]:checkbox").prop("checked", true);
            //         // $("input[id=MFKSFT]:checkbox").prop("checked", true);
            //         totalPrice = 0
            //         this.amount = 0;
            //         this.allQty = 0;
                    
            //             for(var idx in this.sessionList){
            //                 // console.log('id : ',$('#'+this.sessionList[idx].productCode).attr('id'))
            //                 console.log($('#MFKSFT').val())
            //                 console.log('id : ',$('input[class=selectBtn]').attr('id'))
            //                 console.log('val : ',$('input[id='+this.sessionList[idx].productCode+']:checkbox').val())
            //                 // $('input[id='+this.this.sessionList[idx].productCode+']:checkbox').prop("checked", true);
                            
                            
            //                 console.log(this.sessionList[idx].productCode)
            //                 var sesseionQuantity = ''
            //                 sesseionQuantity = this.sessionList[idx].quantity.split(' ')
            //                 totalPrice = sesseionQuantity[0] * this.sessionList[idx].price
            //                 this.amount += totalPrice;
            //                 this.allQty += parseInt(sesseionQuantity[0]);
            //             }
            //     }
                    
                
            //     else{ 
                    //리스트 출력 delay 후 체크박스 전체체크 
                    setTimeout(function() {
                        $("input[name=myInput]").prop("checked", true);
                    }, 100);
                // }

        })
        .catch((e) => {
        console.log("error : ", e);
        });

        var limit = {'limit' : 'true'}

        Order.getOrderHistory(limit)
        .then((response) => {
            if(response.data != null && (response.data[0].statusCode == 'Ordered' || response.data[0].statusCode == 'Waiting' || response.data[0].statusCode == 'Paid')){
                this.orderedLists = response.data[0]
                // if(this.EdeliveryDate == )
            }
            // console.log('cart 주문현황 최신1건 ',this.orderedLists)
        })
        .catch((e) => {
        console.log("error : ", e);
        });

        //첫 장바구니화면 전체 ischecked true
        this.updateIsckecked(true)

        this.sessionOrderUpdate = this.$store.state.orderUpdate
        this.sessionCartPopUpCheck = this.$store.state.cartPopUpCheck
        // console.log('orderUpdate : ',this.sessionOrderUpdate,typeof(this.sessionOrderUpdate), ', popUpCehcek: ',this.sessionCartPopUpCheck,typeof(this.sessionCartPopUpCheck))

        // 남은 주문 마감시간 -> 문구 노출시 시작으로 변경
        // this.countDownTimer()

        
    },
    

    methods : {
        // 실시간 남은 주문 마감시간 
        countDownTimer() {

            var now = new Date();	// 현재 날짜 및 시간
            var minutes = now.getMinutes();	// 분
            
            this.countDown = this.countMinutes - minutes
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    if(this.countDown == 0 || this.countDown == 60){
                        this.deadLineOrder.display='none'
                    }
                    this.countDownTimer()
                }, 60000)
            }
        },

        allCheck : async function(allCartLists){
            if($('input[id=check01]:checkbox').is(":checked")){
                this.amount = 0;
                this.allQty = 0;
                $("input[name=myInput]:checkbox").prop("checked", true);

                for(var index in allCartLists){
                    var totalPrice = this.cartLists[index].quantity * this.cartLists[index].price
                    this.amount += totalPrice;
                    this.allQty += this.cartLists[index].quantity;

                }

                // $("input[id="+selectBoxList.productCode+"]:checkbox").is(":checked")
              this.cartListsTemp=[...this.cartLists];
                //전체 ischecked true
                this.updateIsckecked(true)  

            }else{

                $("input[name=myInput]:checkbox").prop("checked", false);
                this.amount = 0;
                this.allQty = 0;

                this.cartListsTemp.splice(0,this.cartLists.length);
                //전체 ischecked false
                this.updateIsckecked(false)  
                
            }
          this.total = await this.$root.totalQuantityAmount(this.cartListsTemp);
        },

        selectBox : async function(selectBoxList){
          let productSfid=0;

          this.cartListsTemp.forEach(function(e,index){
            if(e.productSfid==selectBoxList.productSfid) {
              productSfid=index;
            }
          })

            //장바구니개수 체크된상품 수 비교 > 전체선택 on | off
            if(this.cartLists.length == $("input:checkbox[class=selectBtn]:checked").length){
                $('input[id=check01]:checkbox').prop("checked", true);
            }else{
                $('input[id=check01]:checkbox').prop("checked", false);
            }

            if($("input[id="+selectBoxList.productCode+"]:checkbox").is(":checked")){
                this.amount += (selectBoxList.price * selectBoxList.quantity)
                this.allQty += selectBoxList.quantity

                this.cartListsTemp.push(selectBoxList);

                //선택된 상품 ischecked true
                this.updateIsckecked(true,selectBoxList.productCode)

            }else{
                this.amount -= (selectBoxList.price * selectBoxList.quantity)
                this.allQty -= selectBoxList.quantity
                this.cartListsTemp.splice(productSfid,1);

                //선택된 상품 ischecked false
                this.updateIsckecked(false,selectBoxList.productCode)

            }
          this.total = await this.$root.totalQuantityAmount(this.cartListsTemp);
        },

        //상품수량 증가/감소
        qty : function(type,listCart){
        
            let step = parseInt(this.unitDefine[listCart.productType].quantity[0]);

            if(type == 'plus'){
                this.orderQty = parseInt($('.input'+listCart.productCode).val());
                
                //최대수량 팝업
                // if(this.orderQty >= listCart.maxQuantity && listCart.maxQuantity != 0){
                            
                //     this.popUpMinMax.display = ''
                //     this.minMax = '최대'
                //     this.productNm = listCart.description
                //     this.minMaxQuantity = listCart.maxQuantity           
                
                // }else{
                        this.orderQty += step;
                    $('.input'+listCart.productCode).val(this.orderQty);
                    $('.totalPrice'+listCart.productCode).text((listCart.price*this.orderQty).toLocaleString('ko-KR'));

                    if($("input[id="+listCart.productCode+"]:checkbox").is(":checked")){
                        this.amount += (listCart.price * step);
                        this.allQty += step;
                    }
                    
                // }
                //변경된 수량 넣어주기
                for(var idx in this.cartLists){
                    if(this.cartLists[idx].productCode == listCart.productCode){
                        this.cartLists[idx].quantity = this.orderQty
                    }
                }
                
                this.realTimeUpdateQty(this.orderQty,listCart.productCode)


            }else if(type == 'minus'){
                this.orderQty = parseInt($('.input'+listCart.productCode).val());

                //최소수량 팝업
                if(this.orderQty <= listCart.minQuantity && listCart.minQuantity > 0){
                    this.popUpMinMax.display = ''
                    this.minMax = '최소'
                    this.productNm = listCart.productNm
                    this.minMaxQuantity = listCart.minQuantity + this.unitDefine[listCart.productType].name;
                }else if(this.orderQty > 0 ){
                    this.orderQty -= step;
                    $('.input'+listCart.productCode).val(this.orderQty);
                    $('.totalPrice'+listCart.productCode).text((listCart.price*this.orderQty).toLocaleString('ko-KR'));
                    
                    if($("input[id="+listCart.productCode+"]:checkbox").is(":checked")){
                        this.amount -= (listCart.price * step);
                        this.allQty -= step;
                    }
                }

                //변경된 수량 넣어주기
                for(var idx1 in this.cartLists){
                    if(this.cartLists[idx1].productCode == listCart.productCode){
                        this.cartLists[idx1].quantity = this.orderQty
                    }
                }

                this.realTimeUpdateQty(this.orderQty,listCart.productCode)

            }else{
                this.$dialog('product qty error');
            }
            
            // $('.amount').text((this.amount).toLocaleString('ko-KR')+'원');

        },

        //실시간 수량 update
        realTimeUpdateQty : async function(orderQty,productCode){
            // this.total = await this.$root.totalQuantityAmount(this.cartLists);
            this.total = await this.$root.totalQuantityAmount(this.cartListsTemp);

            var params = [{
                'quantity':orderQty,
                'productCode': productCode, 
                'clickValue' : this.clickValue
            }]

            Cart.updateCart(params)
            .then(() => {
                
            })
            .catch((e) => {
            console.log("error : ", e);
            });

        },

        oneDelete : function(productCode){

            var params = [];
            var a = {}
            a = {'productCode':productCode}
            params.push(a)

            // this.select = {};
            // this.selectList = [];
            // for(var i in this.cartLists){
    
            //     if($('#'+this.cartLists[i].productCode).is(":checked") == true){
            //         this.select = {'productCode' : $('#'+this.cartLists[i].productCode).attr('id')
            //                         ,'quantity':$('.input'+this.cartLists[i].productCode).val()
            //                         ,'price': this.cartLists[i].price};
            //         this.selectList.push(this.select);
                    
            //     }
            // }

            // console.log('삭제, 선택된 상품들 : ',this.selectList)

            // sessionStorage.removeItem('selectedCartList');
            // sessionStorage.setItem('selectedCartList', JSON.stringify(this.selectList));

            Cart.deleteCart(params)
            .then((response) => {
                var result = response.data;
                if(result == 1){
                    window.location.reload()
                }

            })
            .catch((e) => {
            console.log("error : ", e);
            });
        },

        selectDelete : function(cartLists){
            this.selectList = [];
            for(var i in cartLists){
    
                if($('#'+cartLists[i].productCode).is(":checked") == true){
                    this.select = {'productCode' : $('#'+cartLists[i].productCode).attr('id')};
                    this.selectList.push(this.select);
                    
                }
            }
            //장바구니 없는거 체크

            if($("input:checkbox[name=myInput]:checked").length > 0){
                Cart.deleteCart(this.selectList)
                .then((response) => {
                    var result = response.data;
                    if(result > 0){
                        window.location.reload()
                    }
                    
                })
                .catch((e) => {
                console.log("error : ", e);
                });
            }else{
                console.log('삭제할 상품을 선택해주세요.')
            }


        },


        selectOrder : async function(cartLists){
            this.select = {}
            this.selectList = [];
            var strArry = '';

            this.checkDeadLine();

            let totalQuantity = 0;
            let cartListString = '';

            for(var i in cartLists){
                strArry = ($('.input'+cartLists[i].productCode).val()).split(' ');
                if($('#'+cartLists[i].productCode).is(":checked") == true && strArry[0] > 0){
                    this.select = {'productCode' : $('#'+cartLists[i].productCode).attr('id'),'quantity':strArry[0],
                                    'productNm':''};
                    this.selectList.push(this.select);

                    totalQuantity += parseInt(strArry[0]);
                    cartListString += cartLists[i].productCode + '|';
                }
            }

            //주문 할 상품 없습니다.
            if(this.selectList.length <= 0){
                this.product0.display = ''

            }else if(this.selectList.length > 0){ //완료된 주문건 있을 시
                let isDuplicated = false;
                let duplicateInfo = [];

                // 진행중인 주문 존재 여부 조회
                await Order.validateDuplicate()
                    .catch((e) => {
                        isDuplicated = true;
                        try {
                            duplicateInfo = e.response.data.info;
                            //console.log('duplicateInfo', duplicateInfo);
                        } catch (error) {
                            console.log(error);
                        }
                    });

                if (isDuplicated == true) {
                    if (duplicateInfo[0] == 'Paid' || duplicateInfo[0] == 'Waiting') {
                        this.$dialog('이미 등록된 주문건이 있습니다.<br>취소 또는 환불요청 후 다시 주문해주세요');
                        return;
                    } else if (duplicateInfo[0] == 'Approved') {
                        let cutOffSplit = duplicateInfo[1].split(' ');
                        this.$dialog('기존 등록된 주문이 있습니다.<br>(신규 주문은 ' + cutOffSplit[0] + ' ' + this.$root.timeKor(cutOffSplit[1]) + ' 이후 가능합니다.)');
                        return;
                    } else if (this.sessionOrderUpdate == null && duplicateInfo[0] == 'Ordered') {
                        this.cartOrderEqual.display = '';
                        return;
                    }
                }

                for(var idx in cartLists){
                    for(var idx1 in this.selectList){
                        //최대 수량 체크
                        // if(this.selectList[idx1].productCode == cartLists[idx].productCode && cartLists[idx].quantity > cartLists[idx].maxQuantity && cartLists[idx].maxQuantity != 0){
                        //     this.popUpMinMax.display = ''
                        //     this.minMax = '최대'
                        //     this.productNm = cartLists[idx].description
                        //     this.minMaxQuantity = cartLists[idx].maxQuantity  
                        //     return

                        //최소 수량 체크
                        // }else 
                        if(this.selectList[idx1].productCode == cartLists[idx].productCode && cartLists[idx].quantity < cartLists[idx].minQuantity && cartLists[idx].minQuantity > 0){
                            this.popUpMinMax.display = ''
                            this.minMax = '최소'
                            this.productNm = cartLists[idx].productNm
                            this.minMaxQuantity = cartLists[idx].minQuantity + this.unitDefine[cartLists[idx].productType].name
                            return
                        }

                        //선택된 상품중 수량0 체크
                        // else if(this.selectList[idx1].productCode == cartLists[idx].productCode && this.selectList[idx1].quantity == 0 ){
                        //     this.quantity0.display = ''
                        //     this.productNm = cartLists[idx].description
                        //     return
                        // }

                    }

                }

                //console.log('totalQuantity: ' + totalQuantity);
                //console.log('this.min: ' + this.min);

                //소매점주 등급 최소최대
                if (this.total.quantity < this.min) {
                    this.popUpAllQty.display = ''
                    return
                }

                //주문이 수정된경우(주문현황 > 주문수정 / 장바구니에서 기존주문 병합) 
                if(this.sessionOrderUpdate == true && this.sessionCartPopUpCheck  == null){
                    this.message = '기존주문을 수정 하시겠습니까?'
                    this.popUpOrder.display='';
                    return

                }else if(this.sessionOrderUpdate == true && this.sessionCartPopUpCheck  == true){
                    // console.log('goOrder')
                    this.goOrder();
                    return
                /*
                //배송전날 마감시간 지남
                }else if(this.orderDeadLineCheck == true){
                    // console.log('배송전날 마감시간 지남')
                    this.message = '주문하기 페이지로 이동하시겠습니까?'
                    this.popUpOrder.display='';
                    return;
                */
                //승인건유무 체크
                }else if(this.customerVO.isApproved == 'true'){
                    this.showPopupApproved = true;
                    return;
                }

                this.goOrder();

                // console.log('새로운 주문')
                //this.message = '주문하기 페이지로 이동하시겠습니까?'
                //this.popUpOrder.display='';
            
            }
                    

        },

        //주문완료와 장바구니 병합
        cartMerge : function (){
            this.cart = {}
            this.cartList = []
            // sessionStorage.setItem('orderUpdate', true);
            this.$store.commit('orderUpdate', true);
            // sessionStorage.setItem('popUpCehcek', true);
            this.$store.commit('cartPopUpCheck', true);
            for(var idx in this.orderedLists.list){
                    this.cart = {'productCode' : this.orderedLists.list[idx].productCode, 'quantity' : this.orderedLists.list[idx].quantity,'clickValue': 'cartMerge'};     
                    this.cartList.push(this.cart)
            }
                
            const json = JSON.stringify(this.cartList);

            Cart.updateCart(json)
            .then((response) => {
                if(1 == response.data){
                    window.location.reload()
                }else{
                    console.log("주문완료 같은 상품이 장바구니 있을 시 병합 error!")
                }
            })
            .catch((e) => {
            console.log("error : ", e);
            });

        },

        //cart Table ischecked 
        updateIsckecked : function(ischecked,productCode){
            var params = [{
                'clickValue':'ischecked',
                'ischecked':ischecked,
                'productCode' : productCode,
            }]

            Cart.updateCart(params)
            .then((res) => {
                if(res.data == 0){
                    console.log('cart table ischecked update failed!!')
                }
            })
            .catch((e) => {
            console.log("error : ", e);
            });
        },

        //팝업닫기
        close : function(){
            this.popUpMinMax.display = 'none'
            this.popUpOrder.display = 'none'
            this.popUpAllQty.display = 'none'
            // this.quantity0.display = 'none'
            this.product0.display = 'none'
            this.cartOrderEqual.display = 'none'
            // this.orderUpdate.display = 'none'

            this.showPopupApproved = false;
            
        },

        //주문하기 페이지 이동
        goOrder : function(checkNew){
            if (checkNew != false) {
                let cartListString = '';

                for(var i in this.cartLists){
                    cartListString += this.cartLists[i].productCode + '|';
                }
    
                //console.log('cartListString',cartListString);
    
                //신제품 포함여부 확인
                //console.log('this.newProductList',this.newProductList);
                if (this.newProductList.length > 0 && cartListString.indexOf(this.newProductList[0].productCode) == -1) {
                    this.$dialog({
                        type: 'confirm',
                        message: this.newProductList[0].productNm + '<br><br>제품이 주문내역에 없습니다.<br>추가하시겠습니까?',
                        button: {
                            yes: '예',
                            no: '아니요'
                        },
                        callback: res => {
                            if (res) {
                                //console.log('callback','yes');
                                Cart
                                    .insertCart([
                                        {
                                            'productCode': this.newProductList[0].productCode,
                                            'quantity': this.newProductList[0].minQuantity
                                        }
                                    ])
                                    .then((response) => {
                                        this.isLoading = false;  
                                        this.result = response.data;
                                        if(this.result > 0){
                                            this.$dialog({
                                                type: 'alert',
                                                message: '장바구니에 상품이 담겼습니다.',
                                                callback: () => {
                                                    window.location.reload();
                                                }
                                            });
                                        } else {
                                            console.log("장바구니 추가 에러");
                                        }
                                    })
                                    .catch((e) => {
                                        this.isLoading = false;  
                                        console.log("error : ", e);
                                    });
                            } else {
                                //console.log('callback','no');
                                this.goOrderComplete();
                            }
                        }
                    });
                } else {
                    this.goOrderComplete();
                }
            } else {
                this.goOrderComplete();
            }

            // sessionStorage.removeItem('popUpCehcek');
            //this.$store.commit('cartPopUpCheck', null);
            //this.$router.push({name:'Order'})
        },

        goOrderComplete(){
            this.$store.commit('cartPopUpCheck', null);
            this.$router.push({name:'Order'})
        },

        goNewOrder(){ // 기존 주문을 수정하시겠습니까? 아니요 했을때 ...
            this.$store.commit('cartPopUpCheck', null);
            this.$router.push({name:'Order'})
        },

        //장바구니 총금액 , 총수량 계산
        // allOrderResult : function(cartLists){
        //     for(var idx in cartLists){
        //         var totalPrice = this.cartLists[idx].quantity * this.cartLists[idx].price
        //         this.amount += totalPrice;
        //         this.allQty += this.cartLists[idx].quantity;
        //     }
        // },

        //배송전날 주문마감시간 
        checkDeadLine (){

            var Now = new Date();
            var nowHour = Now.getHours();
            var nowMinutes = Now.getMinutes();
            var nowDate = Now.getDate();
            if(nowMinutes <= 9){ nowMinutes = "0"+nowMinutes}
            var nowTime = parseInt(nowHour+''+nowMinutes)
            // this.orderDeadLine = 1200
            // nowTime = 1430
            // nowDate = 9

            // console.log('nowDate : ',nowDate, '배송전날 날짜 : ',parseInt(moment(this.getDate).subtract(1, 'd').format('DD')), ', this.orderDeadLine : ',this.orderDeadLine, ',nowTime : ',nowTime,'this.EdeliveryDate : ',this.EdeliveryDate)
            // console.log(nowDate == parseInt(moment(this.getDate).subtract(1, 'd').format('DD')) , nowTime >= this.orderDeadLine , nowDate == parseInt(moment(this.getDate).format('DD')))
            // console.log(nowDate,parseInt(moment(this.getDate).subtract(3, 'd').format('DD')), parseInt(moment(this.getDate).format('DD')), nowTime, this.orderDeadLine)
            let nowDateNum = parseInt(moment(new Date()).format('YYYYMMDD'));
            
            /* 재계산필요없음 (routeplan 202112)
            if((nowDateNum == this.cutOffDate && nowTime >= this.orderDeadLine) || (nowDateNum == this.cutOffDate && nowDateNum <= this.dlvDate)){
                this.EdeliveryDate = moment(this.getDate).add(7,'d').format('YYYY-MM-DD')
            }*/
            
            //배송전날 마감시간 지남
            if(nowDateNum == this.cutOffDate && nowTime >=this.orderDeadLine){
                this.orderDeadLineCheck = true
                // console.log('배송전날 마감시간 지남',this.orderDeadLineCheck)
            }
            

        },

        //장바구니 수정으로 들어와서, 마감 후 주문할 경우 > 경고팝업 > 장바구니 삭제 > 상품리스트
        goProductList :function(){
            var params = [];
            var a = {}

            for(var index in this.cartLists){
                a = {'productCode':this.cartLists[index].productCode}
                params.push(a)
            }

            Cart.deleteCart(params)
            .then((response) => {
                var result = response.data;
                if(result > 0){
                    // sessionStorage.removeItem('orderUpdate');
                    this.$store.commit('orderUpdate', null);
                    this.$router.push({name:'Product'})
                }

            })
            .catch((e) => {
            console.log("error : ", e);
            });
            
        },

    }





    

};
</script>

<style lang="scss">
    @import '../../assets/styles/css/common.css';
    @import '../../assets/styles/css/default.css';
    
    

</style>