<template>
    <div>
        <Notice v-if="type == 'Notice'" :id=id />
        <Promotion v-else-if="type == 'PR'" :id=id />
        <Survey v-else-if="type == 'Survey'" :id=id />
        <Main v-else-if="type == 'Pop-up'" :id=id />
        <div class="preview_wrap" @click="alert()"></div>
    </div>
</template>
<script>
import { computed } from '@vue/composition-api';
import Notice from '@/components/notice/NoticeDetail';
import Promotion from '@/components/promotion/PromotionDetail';
import Survey from '@/components/survey/SurveyDetail';
import Main from '@/components/main/Main-preview';

export default {
    name: 'Preview',
    components: {
        Notice,
        Promotion,
        Survey,
        Main
    },
    data() {
        return {
            type: '',
            id: ''
        }
    },
    mounted() {
        this.type = this.$route.query.type;
        this.id = this.$route.query.id;
    },
    methods: {
        alert() {
            alert('미리보기 화면입니다.');
        }
    }
}
</script>