<template>
<div class="container">
    <!-- container -->
    <div class="sub_container">
        <!-- 2021.02.11 수정 ------------ container 안으로 sub_container 추가 하였습니다. -->   
        <!-- 상단 알림 -->
        <div class="sec">
            <div class="Notification_wrap" v-bind:style="deadLineOrder">
                <span>{{orderDeadLineDisplay}} 까지 수정 가능</span>
                <span>{{ countDown }}분 남음</span>
            </div>
        </div>
        <!-- //상단 알림 -->
        <!-- 20220914 ~ 상품 리스트 - 셀렉트박스 및 검색 컨텐츠 상단 -->
        <div class="top_sec">
            <div class="cate_area">
                <div class="cate_scroll">
                    <ul>
                        <li :class="{'active':category == ''}" @click="changeCategory('')">전체상품</li>
                        <li v-for="(item,idx) in categoryList" :key="idx" :class="{'active':category == item.code}" @click="changeCategory(item.code)">{{item.name}}</li>
                    </ul>
                    <div class="all_btn_area" @click="isCategoryOpen = !isCategoryOpen">
                        <span></span>
                    </div>
                </div>
                <div v-show="isCategoryOpen" class="cate_open">
                    <ul>
                        <li :class="{'active':category == ''}" @click="changeCategory('', 0)">전체상품</li>
                        <li v-for="(item,idx) in categoryList" :key="idx" :class="{'active':category == item.code}" @click="changeCategory(item.code, idx+1)">{{item.name}}</li>
                    </ul>
                    <div class="all_btn_area" @click="isCategoryOpen = !isCategoryOpen">
                        <span></span>
                    </div>
                </div>                        
            </div>
        </div>
        <!-- 20220914 ~ //상품 리스트 - 셀렉트박스 및 검색 컨텐츠 상단 -->

        <!-- 20220914 ~상품 리스트 - 리스트 보기 옵션 -->
        <div class="sort_sys">
            <b>정렬방식</b>
            <div class="filters">
                <span :class="{'filter_sel':sortBy == 'default'}" @click="sortSearch('default')">기본순</span>
                <span :class="{'filter_sel':sortBy == 'name'}" @click="sortSearch('name')">이름순</span>
                <span :class="{'filter_sel':sortBy == 'fav'}" @click="sortSearch('fav')">자주구매순</span>
            </div>
        </div>
        <!-- 20220914 ~//상품 리스트 - 리스트 보기 옵션 -->

        <!-- 20220914 ~ 상품 리스트 - 디자인 변경 -->
        <div class="list_wrap" id="list3">
            <ul class="list_area_03">
                <li v-for="(productList, idx) of productOddList" :key="idx">
                    <div class="product_item" v-for="product of productList" :key="product.productCode">
                        <div class="image_box" @click="showQuantity(product)">
                            <vue-image :src="product.imageUrl" />
                            <span class="noti" v-if="product.recentlyOrderQty !=  0">자주 구매</span>
                            <div class="image_dimmed" v-if="selectedProduct[product.productCode] && selectedProduct[product.productCode].quantity > 0">
                                <p>{{selectedProduct[product.productCode].quantity}}</p>
                            </div>
                        </div>
                        <div class="product_info">
                            <p class="product_tit">
                                <a @click="showQuantity(product)">{{product.productNm}}</a>
                                <template v-if="product.badgeCd">
                                    &nbsp;
                                    <div v-if="product.badgeCd == 'P01'" class="badge_box new">
                                        <span>신제품</span>
                                    </div>
                                    <div v-if="product.badgeCd == 'P02'" class="badge_box limit">
                                        <span>한정판</span>
                                    </div>
                                </template>  
                            </p>
                            <div class="price_box">
                                <span class="flag" @click="showDetail(product)">자세히</span>
                                <strong v-if="unitDefine[product.productType]" class="price">
                                    공급가 <b>{{product.price | makeComma}}</b>원 ({{unitDefine[product.productType].name}})
                                </strong>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" spinner="waveDots">
                <div slot="no-results" style="color: rgb(102, 102, 102); font-size: 14px; ">
                    상품이 없습니다.
                </div>
                <div slot="no-more"></div>
            </infinite-loading>
        </div>
        <!-- 20220914 ~ //상품 리스트 - 디자인 변경 -->
        <!-- 상품 리스트 - 버튼 -->
        <!-- <div class="total_button">
            <div class="combo_form">
                <div class="total_info">
                    <span id="total_price">총 금액</span>
                    <strong class="amount">0</strong>
                    <span>원</span>
                </div>
                <a class="link_button" @click="addCart()">장바구니 담기</a>
            </div>
        </div> -->
        <!-- //상품 리스트 - 버튼 -->
        
    </div>
    <!-- //container -->
    <!--20220914 ~ 상품 리스트 - 버튼 -->
    <!-- <div class="product_fixed">
        <div v-if="totalAmount > 0" class="total_info">
            <span id="total_price">총 금액</span>
            <span class="amount">{{totalQuantity}} / {{totalAmount | makeComma}}<span> 원</span></span>
        </div>
        <div class="order_area">
            <div class="combo_form">
                <div class="cart" @click="addCart()">
                장바구니 담기
                </div>
                <div class="order_btn">
                    <div>
                        즉시 주문
                        <span></span>
                    </div>
                    <p>(진행 중인 주문이 있습니다.)</p>
                </div>
            </div>
        </div>
        <div class="nav_footer">
            <ul>
                <li><router-link to="/main"></router-link></li>
                <li><router-link to="/cart"></router-link></li>
                <li><router-link to="/orderHistory"></router-link></li>
                <li><router-link to="/notice"></router-link></li>
            </ul>
        </div>
    </div> -->
    <div class="product_fixed">
        <div v-if="total.amount > 0" class="total_info">
          <span id="total_price">총 금액</span>
          <span class="amount">{{total.quantityString}} / {{total.amount | makeComma}}<span> 원</span></span>
        </div>
<!--        <div v-if="total.amount > 0" class="total_info">
            <span id="total_price">총 금액
              <button style="margin-left: 10px;" class="showList" @click="toggleDetail()">
                <span id="closeToggle" v-if="isShowDetailList"></span>
                <span id="openToggle" v-else></span>
              </button>
            </span>
            <span class="amount">{{total.quantityString}} / {{total.amount | makeComma}}<span> 원</span></span>
        </div>
        <div v-if="isShowDetailList" class="total_info" style="min-height:70px; height: auto;padding-bottom: 15px;">
          <span id="total_price1">
            <div v-for="(selProduct,idx) of selectedProduct" :key="idx"><div v-if="selProduct.quantity > 0">{{selProduct.productNm}}</div></div>
          </span>
          <span class="amount">
            <div style="direction: rtl;" v-for="(selProduct,idx) of selectedProduct" :key="idx"><div v-if="selProduct.quantity > 0">{{selProduct.quantityString}} / {{selProduct.price*selProduct.quantity | makeComma}}<span> 원</span></div></div>
          </span>
        </div>-->
        <div class="order_area">
            <div class="combo_form">
                <div class="cart" @click="addCart()">
                장바구니 담기
                </div>
                <div class="order_btn" :class="{order_before_btn: orderError}" @click="quickOrder">
                    <div>
                        즉시 주문
                        <span></span>
                    </div>
                    <p v-if="orderError">({{orderErrorMessage[orderError].short}})</p>
                </div>
            </div>
        </div>
    </div>    
    <!--20220914 ~ //상품 리스트 - 버튼 -->

    <div class="layerdim_wrap" v-bind:style="quantity0">
        <div class="dim_inner">
            <div class="layer_wrap">
                <div class="layer_box">
                    <div class="layer_head">
                        <!-- <h2 class="ly_title">알림</h2> -->
                        <a class="btn_close" @click="close()">닫기</a>
                    </div>
                    <div class="layer_body">
                    <p>상품 수량을 선택해주세요.</p>
                        <div class="btn_center">
                            <a class="btn_wht" @click="close()">확인</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="layerdim_wrap" v-bind:style="popUpCart">
        <div class="dim_inner">
            <div class="layer_wrap">
                <div class="layer_box">
                    <div class="layer_head">
                        <!-- <h2 class="ly_title">알림</h2> -->
                        <a class="btn_close" @click="close('cart')">닫기</a>
                    </div>
                    <div class="layer_body">
                        <p id="ptext">{{message}}</p>
                        <div class="btn_center combo_form">
                            <div>
                                <a class="btn_wht" @click="close('cart')">닫기</a>
                            </div>
                            <div>
                                <router-link to="/cart" class="btn_wht">장바구니 이동</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="layerdim_wrap" v-show="isLoading">
        <div class="dim_inner">
            <div class="loading_api">
                <div></div>
            </div>
        </div>
    </div>

    <!-- 20220914 ~ 수량체크 및 확인  -->
    <div class="layerdim_wrap v2" v-show="isShowQuantity">
        <div class="dim_inner">
            <div v-if="currentProduct" class="layer_box"
                :class="{'hSize01':!(total.amount > 0), 'hSize02':total.amount > 0}">
                <div class="layer_head">
                    <a class="btn_close" @click="isShowQuantity=false">닫기</a>
                </div>
                <div class="space"></div>
                <div class="product_caption">
                    <h4>{{currentProduct.productNm}}</h4>
                    <p v-if="currentProduct.addInfo1 != null">{{currentProduct.addInfo1}}</p>
                    <p v-if="currentProduct.addInfo2 != null">{{currentProduct.addInfo2}}</p>
                </div>
                <div class="space"></div>
                <div>
                    <ul class="count_box">
                        <li @click="setQuantity(-1)"></li>
                        <li>{{currentQuantity}}</li>
                        <li @click="setQuantity(1)"></li>
                    </ul>
                </div>
                <div class="space" v-if="unitDefine[currentProduct.productType].quantity.length > 1"></div>
                <div class="count_btn" v-if="unitDefine[currentProduct.productType].quantity.length > 1">
                    <button class="cnt_btn" v-for="item in unitDefine[currentProduct.productType].quantity" :key="item"
                        @click="setQuantity(item)">{{item}}</button>
                </div>
                <div class="space"></div>
                <div class="util_btn">
                    <button class="initial_btn" @click="setQuantity(0)"><i></i>초기화</button>
                    <button class="check_btn" @click="confirmQuantity">확인</button>
                </div>
                <div class="space"></div>
            </div>
        </div>
    </div>
    <!-- 20220914 ~ 수량체크 및 확인  -->

    <!-- 20220914 ~ 자세히 내용 확인  -->
    <div class="layerdim_wrap v2" v-show="isShowDetail">
        <div class="dim_inner">
            <div v-if="currentProduct" class="layer_box"
                :class="{'hSize01':!(total.amount > 0), 'hSize02':total.amount > 0}">
                <div class="layer_head">
                    <a class="btn_close" @click="isShowDetail=false">닫기</a>
                </div>
                <div class="space"></div>
                <div class="product_caption">
                    <h4>{{currentProduct.productNm}}</h4>
                    <template v-if="!currentProduct.detailUrl">
                        <p v-if="currentProduct.addInfo1 != null">{{currentProduct.addInfo1}}</p>
                        <p v-if="currentProduct.addInfo2 != null">{{currentProduct.addInfo2}}</p>
                    </template>
                </div>
                <div v-if="currentProduct.detailUrl" class="space"></div>
                <div>
                    <dl v-if="currentProduct.detailUrl" class="product_package">
                        <dt>
                            <img alt="상품이미지" :src="currentProduct.detailUrl">
                        </dt>
<!--                      <dt>
                        <img alt="상품이미지" :src="currentProduct.detailUrl" style="max-height: 125px;width: 338px;">
                      </dt>
                      <dt>
                        <img alt="상품이미지" :src="currentProduct.detailUrl" style="max-height: 125px;width: 338px;">
                      </dt>-->
                    </dl>
                </div>
                <div class="space"></div>
                <div class="util_btn">
                    <button class="check_btn" @click="isShowDetail=false">확인</button>
                </div>
                <div class="space"></div>
            </div>
        </div>
    </div>
    <!-- 20220914 ~ 자세히 내용 확인  -->
    

</div>



</template>

<script>
// import axios from "axios";
import $ from 'jquery'
import Product from '@/api/Product'
import Code from '@/api/Code'
import Cart from '@/api/Cart'
import Order from '@/api/Order'
import Customer from '@/api/Customer'
import Log from '@/api/Log'
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment'
import VueImage from '@/components/common/vue-image';

export default {
    name: 'Product',
    components: {
        InfiniteLoading,
        VueImage
    },

    data: () => {
        return {
            isLoading: false,               // 로딩중
            productList: [],               //axios 받아온 porudctList
            categoryList: [],               //axios 받아온 brandList(검색 select)
            cartProductCode:[],             //선택된 상품 [{상품코드, 수량},...]
            obj:{},                         //선택된 상품 {상품코드, 수량}
            // statusCode : '',             //최근 주문상태
            category:"",          //첫화면 검색타입 상품명 표시
            // product_nm:"name",           //검색타입 상품명(상품명 표시)
            searchType:'',
            brandCode:'',
            keyword:"",                     //검색 상품명
            sort:"default",                 //첫화면 정렬 기본 표시
            sortBy:"default",               //정렬 방법
            orderQty: 0,                    //선택 상품 수량
            amount:0,                       //총 상품 가격
            result : "",                    //장바구니 추가 결과 ( > 0 이면 팝업)
            sortDesc : "ASC",               //mapper sorting 쓰기 위해서 넣는 안쓰는값

            quantity0 : {display: "none"},    //상품 수량 선택 안하고 장바구니 추가 눌럿을 시 팝업
            popUpCart: {display: "none"},     //장바구니 이동 팝업
            listBig : {display : ""},         //리스트 크게 보기
            listSmall : {displqy : "none"},   //리스트 작제 보기
            big : {display : 'none'},         //리스트 크게 보기 아이콘
            small : {display : 'block'},      //리스트 작게 보기 아이콘
            message : '',                     //팝업 메시지

            selectProduct: [],                //선택된 상품   
            // rmvProductCode: "",   
            
            infiniteId: +new Date(),            //리스트 더 보기
            startNo: 1,                         //페이징 스타트 NO (기본 20개)
            countDown:0,                        //상단 마감시간 카운트다운
            orderDeadLineDisplay : '',          //주문마감시간 표시
            orderDeadLine : '',                 //주문마감시간
            customerVO : [],                    //고객정보
            deadLineOrder : {display : 'none'}, ////배송전날 마감시간 -30분전 표시
            filters: ['기본순', '이름순', '자주구매순'],
            
            unitDefine: {},

            currentProduct: null,
            currentQuantity: 0,

            selectedProduct: {},

            total: {},

            // 퀵오더 버튼 컨트롤
            isDuplicated: false,
            quickOrderMessage: null,
            orderError: null,
            orderErrorMessage : {
                'Duplicated' : {
                    'short': '진행중인 주문이 있습니다.',
                    'text': '이미 등록된 주문건이 있습니다.<br>취소 또는 환불요청 후<br>다시 주문해주세요.'
                },
                'NotSelected' : {
                    'text': '상품 수량을 선택해주세요.'
                }
            },

            //display
            isCategoryOpen: false,
            isShowQuantity: false,
            isShowDetail: false,
            isShowDetailList : false
        };
    },

    computed: {
        productOddList() {
            const result = []
            for (let i = 0; i < this.productList.length; i += 2)
                result.push(this.productList.slice(i, i + 2))
            return result
        }
    }, 
    async created() {
        let category = this.$route.query.category
        if (category) {
            this.category = category;
            this.brandCode = category;
        }

        // 제품타입 코드 조회 (단위명, 단위수량)
        this.unitDefine = await this.$root.getUnitDefine();

        // 진행중인 주문 존재 여부 조회
        await Order.validateDuplicate()
            .catch((e) => {
                //console.log("error : ", e);
                if (e.response.data.message) {
                    this.orderError = e.response.data.message;
                }
            });

        // 주문마감시간 가져오기
        await Code.getCode('C00002', 'C01')
            .then(res => {
                // console.log('product 주문마감시간 ',res.data);
                var deadline = res.data.refValue1.split(':')
                this.orderDeadLineDisplay = res.data.refValue1;
                this.orderDeadLine = parseInt(deadline[0]+deadline[1])
                // console.log('주문 마감시간 : ',this.orderDeadLine)
            });

// 카테고리 조회
      await Code.getCategory()
          .then(res => {
            this.categoryList = res.data;
            // console.log('상품 카테고리 : ',this.categoryList);
          });

        this.listSmall.display= 'none';

        //로그인한 고객정보
        await Customer.getDetail()
        .then((response) => {
            this.customerVO = response.data
            // console.log('product 소매점주 정보 ',this.customerVO)

            //배송요일 
            this.deliveryNum = '';
            switch(this.customerVO.DeliveryDay__c) {    
                case 'MON':
                    this.deliveryNum = 1;
                    break;
                case 'TUE':
                    this.deliveryNum = 2;
                    break;
                case 'WED':
                    this.deliveryNum = 3;
                    break;
                case 'THU':
                    this.deliveryNum = 4;
                    break;
                case 'FRI':
                    this.deliveryNum = 5;
                    break;
            }

            //배송날짜 구하기
            var moment = require('moment');
            for (var i = 0; i < 7; i++) {
            var Now = new Date();
            var nowHour = Now.getHours();
            var nowMinutes = Now.getMinutes();
            var nowDate = Now.getDate();
            var getDay = new Date(Now.setDate(Now.getDate() + i)).getDay();
                if (getDay == this.deliveryNum) {//this.deliveryNum
                    var NowDate = new Date();
                    // var getDate = new Date(NowDate.setDate(NowDate.getDate() + i)).getDate();
                    var getDate = new Date(NowDate.setDate(NowDate.getDate() + i));
                }
            }

            // this.orderDeadLine = 1530
            var orderDeadLineToString = this.orderDeadLine.toString()
            var deadLineMinute = orderDeadLineToString.substring(2,4)
            var deadLineHour = orderDeadLineToString.substring(0,2)

            if(deadLineMinute == '00'){
                var orderDeadLineTime = parseInt(deadLineHour-1 +"30")
                this.countMinutes = 60
            }else if(deadLineMinute == '30'){
                orderDeadLineTime = parseInt(deadLineHour+"00")
                this.countMinutes = 30
            }

            if(nowMinutes <= 9){ nowMinutes = "0"+nowMinutes}
            var nowTime = parseInt(nowHour+''+nowMinutes)
            // nowTime =1530
            // nowDate = 2

            if(this.deliveryNum == 1){
                // console.log('nowDate : ',nowDate, '월요일 배송cutoff 띄우는 날짜 : ',parseInt(moment(getDate).subtract(3, 'd').format('DD')), ', this.orderDeadLine : ',this.orderDeadLine, ',orderDeadLineTime :',orderDeadLineTime,',nowTime : ',nowTime)
                if(nowDate == parseInt(moment(getDate).subtract(3, 'd').format('DD')) && this.orderDeadLine != '' && nowTime >= orderDeadLineTime && nowTime <= this.orderDeadLine){
                this.deadLineOrder.display='';
                this.countDownTimer()
                }
            }else{
                // console.log('nowDate : ',nowDate, '배송cutoff 띄우는 날짜 : ',parseInt(moment(getDate).subtract(1, 'd').format('DD')), ', this.orderDeadLine : ',this.orderDeadLine, ',nowTime : ',nowTime)    
                if(nowDate == parseInt(moment(getDate).subtract(1, 'd').format('DD')) && this.orderDeadLine != '' && nowTime >= orderDeadLineTime && nowTime <= this.orderDeadLine){
                this.deadLineOrder.display='';
                this.countDownTimer()
                }
            }
        


        })
        .catch((e) => {
        console.log("error : ", e);
        });

        if (this.category) {
            let self = this;
            $(function() {
                for(let i = 0; i< self.categoryList.length; i++) {
                    if (self.categoryList[i].code == self.category) {
                        self.slideCategory(i + 1);
                        break;
                    }
                }
            })
        }
    },

    updated() {
        for(var index in this.selectProduct){
            $('.input'+this.selectProduct[index].productCode).val(this.selectProduct[index].orderQty+"  갑");
            $('.totalPrice'+this.selectProduct[index].productCode).text(this.selectProduct[index].selectAmount);
            $('.background'+this.selectProduct[index].productCode).addClass("history");
        }   
    },

    methods: {
        // 실시간 남은 주문 마감시간 
        countDownTimer() {

            var now = new Date();	// 현재 날짜 및 시간
            var minutes = now.getMinutes();	// 분
            
            this.countDown = this.countMinutes - minutes
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    if(this.countDown == 0 || this.countDown == 60){
                        this.deadLineOrder.display='none'
                    }
                    this.countDownTimer()
                }, 60000)
            }
        },

        infiniteHandler($state) {
            var params =  {
                "searchType":this.searchType,
                "brandCode": this.brandCode,
                "keyword": this.keyword,
                "sort.sortBy": this.sortBy,
                "sort.desc": this.sortDesc, 
                "page.startNo": this.startNo
            }

            // console.log('검색 : ',params)

            Product.getProduct(params)
            .then(({data}) => {
                setTimeout(() => {
                    if (data.length) {
                        this.startNo += 20;
                        this.productList = this.productList.concat(data);
                        
                        //현재 수량 , 현재 총 구매가 초기화
                        for(var index in this.productList){
                            this.productList[index].quantity = 0;
                            this.productList[index].nowAmount = 0;
                        }
                        // console.log('상품리스트',this.productList)
                        // console.log("after", this.productList.length, this.startNo);
                        $state.loaded();
                        //console.log('infiniteHandler loaded');
                    } else {
                        $state.complete();
                        //console.log('infiniteHandler complete');
                    }
                }, 100)
            });
        },

        changeCategory(brandCode, idx){
            //브라우저에 표시되는 url 변경 (배너 링크용 url 제공)
            if (typeof (history.replaceState) != "undefined") { 
                history.replaceState(null, null, '/product?category=' + brandCode); 
            }

            this.category = brandCode;
            this.keyword = "";  //input kewyword 초기화
            this.productList = [];

            if(this.category == ''){ // 전체
                this.searchType = ""
                this.brandCode = ""
            }else{                             // 그외 브랜드
                this.searchType = ""
                this.brandCode = this.category;
            }

            this.startNo = 1;
            this.infiniteId += 1;
            console.log(this.productList.length)

            this.isCategoryOpen = false;

            this.slideCategory(idx);
        },
        slideCategory(idx) {
            if (idx > -1) {
                console.log('idx : ' + idx);
                //console.log('item : ' + $('.cate_scroll li').eq(idx).html());

                let $box = $('.cate_scroll ul');
                let $item = $('.cate_scroll li').eq(idx);

                console.log($item);


                let boxWidth = $box.innerWidth();
                let itemLeft = $item.offset().left;
                let itemRight = itemLeft + $item.width();
                //console.log('scrollLeft : ' + $box.scrollLeft());
                //console.log('itemLeft : ' + $item.offset().left);
                //console.log('itemRight : ' + itemRight);
                //console.log('boxWidth : ' + boxWidth);

                let scrollAmount = 0;

                if (boxWidth < itemRight) {
                    scrollAmount = itemRight - boxWidth + 10;
                } else if (0 > itemLeft) {
                    scrollAmount = $item.offset().left - 20;
                }

                //console.log('scrollAmount : ' + scrollAmount);

                //$box.scrollLeft($box.scrollLeft() + scrollAmount);
                $box.animate({ scrollLeft: '+=' + scrollAmount });
            }
        },
        sortSearch(sortBy){
            this.sortBy = sortBy;
            this.productList = [];

            this.startNo = 1;
            this.infiniteId += 1;
            
        },

        selectProductCode : function(code,selectQty){
            
            this.obj = {'productCode' : code, 'quantity' : selectQty};
            this.cartProductCode.push(this.obj);
        
            const tempMap = this.cartProductCode.reduce( (ret, {productCode, quantity}) => {
                if (ret[productCode]) {
                    ret[productCode] = quantity;
                } else {
                    ret[productCode] = quantity;
                }
                return ret;
                }, {});

            this.cartProductCode = Object.entries(tempMap).reduce( (arr, [productCode, quantity]) => {
                arr.push({ productCode, quantity});
                return arr;
            }, []);


            for(var index in this.cartProductCode){
                if((this.cartProductCode[index].quantity < 1)){
                    this.cartProductCode.splice(index,1)
                }
            }

            for(var key in this.cartProductCode){
                this.cartProductCode[key].clickValue = 'cartMerge'
            }

        },

        

        //장바구니 추가
        addCart : function(){
            var submit = [];
            
            if(this.cartProductCode.length < 1){
                this.quantity0.display = '';

            }else{
                
                this.isLoading = true;

                submit = this.cartProductCode
                const json = JSON.stringify(submit);
                // this.statusCode = ''

                Cart.insertCart(json)
                .then((response) => {
                    
                    this.isLoading = false;  
                    this.result = response.data;
                    if(this.result > 0){
                        this.message = '장바구니에 상품이 담겼습니다.'
                        this.popUpCart.display = '';
                        
                    }else{
                        console.log("장바구니 추가 에러");
                    }
                })
                .catch((e) => {
                    this.isLoading = false;  
                    console.log("error : ", e);
                });



            }

        },

        close : function(type){
            if(type == 'cart'){
                this.quantity0.display = 'none';
                this.popUpCart.display = 'none';
                this.$router.go(0);
            }else{
                this.quantity0.display = 'none';
                this.popUpCart.display = 'none';
            }
            
        },

        async showDetail(product) {
          //product Nm 로그 insert
          let params=this.$store.state.customer.customerCode + ',' +product.productCode;
          console.log('insert log error',params);
            try {
                await Log.insert('UI_PRODUCT_DETAIL', params, '');
            } catch (e) {
                console.log('insert log error');
            }

            this.currentProduct = product;
            this.isShowDetail = true;
        },

        showQuantity(product) {
            this.currentProduct = product;
            this.currentQuantity = this.selectedProduct[product.productCode] ? this.selectedProduct[product.productCode].quantity : 0;
            this.isShowQuantity = true;
        },

        setQuantity(num) {
            let qty = this.currentQuantity;
            num = parseInt(num);

            if (num == 0) {
                qty = 0;
            } else if (num <= 1) {
                qty += num * this.unitDefine[this.currentProduct.productType].quantity[0];
            } else {                
                qty += num;
            }

            if (qty < 0) {
                qty = 0;
            }

            this.currentQuantity = qty;
        },

        async confirmQuantity() {
            let prd = this.currentProduct;

            // 이전 수량과 같은 경우 통과
            if (this.selectedProduct[prd.productCode]?.quantity == this.currentQuantity) {
                this.isShowQuantity = false;
                return;
            }

            // 최소주문수량 체크
            //console.log('제품최소주문수량 : ' + prd.minQuantity);
            if (this.currentQuantity > 0 && this.currentQuantity < prd.minQuantity) {
                this.$dialog('최소 주문 수량은 ' + prd.minQuantity + this.unitDefine[prd.productType].name +' 입니다.');
                return;
            }

            //주문내역 상세보기 세팅
            let quantityString = '';
            await Product.getPackageDetailQuantity()
              .then(async ({data}) => {
                this.quantityListData = data;
              });

          if (prd.productCode.indexOf('PACKAGE') > -1) {
            let quantityListData = this.quantityListData[prd.productCode]

            let count = 0;
            let el = '';
            quantityListData.forEach(p => {
              if (count != 0) {
                el = '+';
              }
              quantityString += el + (p.quantity * this.currentQuantity) + p.unitname;
              count++;
            })
          } else {
            quantityString=this.currentQuantity + this.unitDefine[prd.productType].name;
          }


          //총금액 세팅
          this.selectedProduct[prd.productCode] = {
            'productType' : prd.productType,
            'quantity' : this.currentQuantity,
            'price' : prd.price,
            'productCategory' : prd.productCategory,
            // 주문저장용
            'productSfid' : prd.productSfid,
            'productNm' : prd.productNm,
            'quantityString' : quantityString
          }
          this.isShowQuantity = false;

          this.total = await this.$root.totalQuantityAmount(this.selectedProduct);
          console.log('this.total', this.total);

            //if (this.currentQuantity > 0) {
                this.selectProductCode(prd.productCode, this.currentQuantity);
            //}
        },

        quickOrder() {
            // 현재 오류 체크
            if (this.orderError) {
                this.$dialog(this.orderErrorMessage[this.orderError].text);
                return;
            }

            // 수량 입력 체크
            if (!this.total.amount) {
                this.$dialog(this.orderErrorMessage['NotSelected'].text);
                return;
            }

            // 최소 주문 수량 체크 전에 디바이스만 주문할 경우 수량 체크 안함--->24.04.23 추가
            // 1.디바이스 sfid 가져오기

            let productTypeStr='';
            this.categoryList.forEach(function (e)  {
              if(e.code=="PC08") {
                productTypeStr=e.code
              }
            });
            // 2.선택 상품 중 디바이스만 있으면 수량체크 안함
            let deviceYn=false;
            let selkeys = Object.keys(this.selectedProduct);
            selkeys.forEach(key => {
              if(this.selectedProduct[key].quantity>0 && this.selectedProduct[key].productCategory!=productTypeStr) {
                deviceYn=true;
              }
            });

            // 소매점주 등급에 따른 최소 주문 수량 체크
            // console.log('최소수량 체크' ,this.total.quantity, this.$store.state.customer.minOrderQuantity);
            console.log('최소수량 체크', this.$store.state.customer.deviceYn);
            //인천지점만 최소수량 70개로 수정_240415-->하드코딩 해제 후 codemaster로 관리_240709
            if(deviceYn) {
              /*if('46'==this.$store.state.customer.level3) {
                if (this.total.quantity < 70) {
                  this.$dialog('최소 수량 70 이상 주문 가능합니다.');
                  return;
                }
              }else {*/

              /*}*/

              //최대 주문 수량 104%넘지 않게 추가예정_240709

            }

          if (this.total.quantity < this.$store.state.customer.minOrderQuantity) {
            this.$dialog('최소 수량 ' + this.$store.state.customer.minOrderQuantity + ' 이상 주문 가능합니다.');
            return;
          }

            this.isLoading = true;

            let params = [];
            let keys = Object.keys(this.selectedProduct);
            keys.forEach(key => {
                params.push({
                    productCode: key,
                    quantity: this.selectedProduct[key].quantity
                });
            });

            if (this.$store.state.customer.epayment == 'E') {
                // E-Payment 대상점인 경우 결제화면으로
                Cart.insertTempCart(params)
                    .then((response) => {
                        this.isLoading = false;  
                        //this.result = response.data;
                        this.$router.push({name:'Order', query: {isQuickOrder:true}});
                    })
                    .catch((e) => {
                        this.isLoading = false;  
                        console.log("error : ", e);
                    });
            } else {
                // 현금수금 대상 점인 경우 바로 주문완료

                // selectedProduct => list
                let list = [];
                keys.forEach(key => {
                    if (this.selectedProduct[key].quantity > 0) {
                        list.push(this.selectedProduct[key]);
                    }
                });

                //신규주문 insert
                Order.insertOrder({
                    'list': list,
                })
                .then((response) => {
                    this.result = response.data;
                    // console.log('신규주문 insert result : ',this.result)
                    
                    this.$router.push({name:'OrderHistory',params: { orderMstSeq: this.result }})
                })
                .catch((e) => {
                    console.log("error : ", e);
                    if (e.response.data.message=='Duplicated') {
                        this.isLoading = false;  
                        this.$dialog({
                            type: 'alert',
                            message: '이미 등록된 주문건이 있습니다.<br>취소 또는 환불요청 후 다시 주문해주세요.',
                            callback: () => {
                                // 주문현황으로 이동
                                this.$router.push({name:'OrderHistory'});
                            }
                        });
                    } else {
                        this.isLoading = false;  
                        this.$dialog(e.response.data.message);
                    }
                });                        
            }
        },
      toggleDetail : function() {
        this.isShowDetailList = !this.isShowDetailList;
      }
    }    
};

</script>

<style >
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
 
.count_area a.link {
pointer-events: none; /*이벤트를 막음*/
cursor: default; /*마우스 커서를 포인트에서 디폴트로 변경*/
}
</style>
