<template>
    <div>
        <div class="layerdim_wrap" v-bind:style=popup1 v-for="popup of pop" :id= popup.contentsMstSeq :key="popup.contentsMstSeq" style="background:rgba(0, 0, 0, 0.5)">
            <div class="dim_inner">
                <div class="layer_wrap">
                    <div class="layer_box">
                        <div v-if="popup.filePath" class="image_area" >
                            <img :src=popup.filePath alt="팝업 이미지">
                        </div>
                        <div v-if="popup.contents" class="text_area">
                            <p v-html="popup.contents"></p>
                        </div>
                        <div class="bottom_area">
                            <button type="button" @click="weekClose(popup.contentsMstSeq)">일주일간 그만보기</button>
                            <button type="button" @click="close(popup.contentsMstSeq)">닫기</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';
import Contents from '@/api/Contents'
import VueCookies from "vue-cookies"
import Order from '@/api/Order'
import Customer from '@/api/Customer'

export default {
    data: () => {
        return {
            subject: "",
            sortBy: "regist_dttm",
            contentsMstSeq: "",
            pop: [],
            popup1: {
                display: ""
            },
            popup2: {
                display: ""
            },
            customerVO: "",
            customerCode : "",   
            order: "",
            statusCode: "",
            orderDt: "",
            orderList: []
        }
    },

    created() {
        // 배너
        /*
        Contents.getContentsList(this.sortBy, 'DESC', this.startNo, 'Notice')
        .then((response) => {
            this.subject = response.data[0].subject;
            this.contentsMstSeq = response.data[0].contentsMstSeq;
        })
        .catch((e) => {
            console.log("error : ", e);
        });
        */

        // 팝업
        Contents.getContentsDetail(this.$route.query.id)
            .then((response) => {
                this.pop = [
                    response.data
                ];
            })
            .catch((e) => {
                console.log("error : ", e);
            });
        //
        /*
        Contents.getContentsList('display_begin_dttm', 'DESC', this.startNo, 'Pop-up')
        .then((response) => {
            this.pop = response.data;
            
        })
        .catch((e) => {
            console.log("error : ", e);
        });
        */
    },

    updated(){},

        

    methods : {

        // 일주일간 그만보기
        weekClose : function(seq){},

        close : function(seq){},

        noticePopup: function(){
            this.$router.push({name: 'Notice', params: {contentsMstSeq: this.contentsMstSeq}})
        }
    }

};
</script>

<style lang="scss">
  @import '../../assets/styles/css/common.css';
  @import '../../assets/styles/css/default.css';
</style>