import Send from '../utils/Send.js'

export default {
    getContentsList(sortBy, desc, starNo, category) {
        return Send({
            url: '/contents',
            method: 'get',
            params: {
                "sort.sortBy": sortBy, 
                "sort.desc": desc,
                "page.startNo": starNo,
                "categoryCode": category
                }
        })
    },
    getContentsDetail(seq) {
        return Send({
            url: '/contents/' + seq,
            method: 'get'
        })        
    }
}