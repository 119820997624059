<template>
    <div>
        <img ref="img" v-show="show" alt="상품이미지"/>
        <div class="loading" v-show="!show"></div>
    </div>
</template>

<script>
    export default {
        props: {
            src: String,
            size: {
                type: [Number, String],
                default: 165
            }
        },
        data() {
            return {
                show: false
            }
        },
        mounted() {
            //console.log('vue-image mounted src:', this.modelValue);

            let el = this.$refs.img;

            if (this.src) {
                el.src = this.src;
            } else {
                el.src = require('../../assets/styles/images/img_default.gif');
            }

            el.onload = () => {
                //console.log('vue-image onload');
                //console.log('vue-image w*h : ', el.width, el.height);

                if (el.width > el.height) {
                    el.style.width = this.size + 'px';
                } else {
                    el.style.height = this.size + 'px';
                }
                this.show = true;
            }
        }
    }
</script>